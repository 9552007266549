import {
  ComponentType,
  IHeroComponentModel,
  IListComponentModel,
  IScreenModel,
} from "@nf/common";
import { LandingPageSignUp } from "../components/Configuration/CustomComponents";
import { LandingPageMarketingInfo } from "../components/Configuration/CustomComponents/LandingPageMarketingInfo/LandingPageMarketingInfo";
import { LandingPageDevicesList } from "../components/Configuration/CustomComponents/LandingPageDevicesList/LandingPageDevicesList";
import { LandingPageFAQ } from "../components/Configuration/CustomComponents/LandingPageFAQ/LandingPageFAQ";
import { LandingPageReview } from "../components/Configuration/CustomComponents/LandingPageReview/LandingPageReview";
import { SectionContainer } from "../components/Configuration/CustomComponents/SectionContainer/SectionContainer";
import { HeroBanner, IFrame, ListComponentHorizontal } from "../components";
import { ITextComponentModel } from "@nf/common/dist/models/Configuration/ITextComponentModel";
import styles from "../components/Configuration/ListComponent/components/ListComponentHorizontal/ListComponentHorizontal.module.scss";
import * as process from "process";
import { IFrameComponentModel } from "@nf/common/dist/models/Configuration/IFrameComponentModel";

export const renderPageBody = (screen: IScreenModel) => {
  return screen.Components?.map((component, index) => {
    if (!component) return null;

    const componentKey = `${component?.Id ? component.Id : component.Title}_${
      component.ComponentTypeCode
    }_${index}`;

    //custom landing page signup component
    if (component.Id === process.env.NEXT_PUBLIC_SIGNUP_COMPONENT_ID) {
      return (
        <LandingPageSignUp
          key={componentKey}
          component={component as IHeroComponentModel}
        />
      );
    }

    //custom landing page marketing info component
    if (component.Id === process.env.NEXT_PUBLIC_USP_COMPONENT_ID) {
      return (
        <LandingPageMarketingInfo
          key={componentKey}
          component={component as ITextComponentModel}
        />
      );
    }

    //devices list component
    if (component.Id === process.env.NEXT_PUBLIC_DEVICES_COMPONENT_ID) {
      return (
        <LandingPageDevicesList
          key={componentKey}
          component={component as ITextComponentModel}
        />
      );
    }

    //landing page FAQ component
    if (component.Id === process.env.NEXT_PUBLIC_FAQ_COMPONENT_ID) {
      return (
        <LandingPageFAQ
          key={componentKey}
          component={component as ITextComponentModel}
        />
      );
    }

    // landing page reviews component
    if (component.Id === process.env.NEXT_PUBLIC_REVIEWS_COMPONENT_ID) {
      return (
        <LandingPageReview
          key={componentKey}
          component={component as ITextComponentModel}
        />
      );
    }
    switch (component.ComponentTypeCode) {
      case ComponentType.List:
        return (
          <SectionContainer key={componentKey} autoHeight includePadding>
            <div />
            <ListComponentHorizontal
              component={component as IListComponentModel}
              className={styles.landingPage}
              showSeeAllButton
              showSubtitle
            />
          </SectionContainer>
        );
      case ComponentType.HeroBanner:
        return (
          <HeroBanner
            key={componentKey}
            component={component as IHeroComponentModel}
            index={index}
          />
        );

      case ComponentType.Iframe:
        return (
          <IFrame
            key={componentKey}
            component={component as IFrameComponentModel}
          />
        );
      default:
        return null;
    }
  });
};
