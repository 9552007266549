/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { CellType } from "@nf/common";
import React from "react";
import { IListComponentHorizontalProps, ListComponentHorizontal } from "..";
import styles from "./ListComponentFrame.module.scss";

export type IListComponentFrameProps = IListComponentHorizontalProps;

export const ListComponentFrame = React.memo(
  (props: IListComponentFrameProps) => {
    return (
      <ListComponentHorizontal
        className={
          props.component.CellType === CellType.FrameLarge
            ? styles.large
            : undefined
        }
        {...props}
      />
    );
  }
);

export default ListComponentFrame;
