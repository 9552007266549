import { useTranslation } from "next-i18next";

import styles from "./PaymentDetailsText.module.scss";

interface PaymentDetailsTextProps {
  cardType: string;
  maskedCard: string;
}

export const PaymentDetailsText = ({
  cardType = "**",
  maskedCard = "**",
}: PaymentDetailsTextProps) => {
  const { t } = useTranslation("translations");

  return (
    <p className={styles.container}>
      <span className={styles.paymentType}>
        {t("web_payment_current_payment_type")}:
      </span>
      {` `}
      <span className={styles.cardType}>{cardType}</span>
      {`, `}
      <span>{maskedCard}</span>
    </p>
  );
};
