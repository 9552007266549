/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Trans, useTranslation } from "next-i18next";

import styles from "./PaymentStatus.module.scss";

export const PaymentStatusError = () => {
  const { t } = useTranslation("translations");

  return (
    <div className={styles.status}>
      <p className={styles.error}>
        <Trans
          i18nKey={t("web_payment_failed")}
          components={{
            a: <a href={t("contact_us")} className={styles.link} />,
          }}
        />
      </p>
    </div>
  );
};
