import React, { useEffect, useState } from "react";
import { ProgressBar } from "../../ProgressBar";
import styles from "./MediaDetailsTopContent.module.scss";
import {
  calcProgress,
  IMediaModel,
  MediaType,
  useCanWatchAuthContent,
  useOverTheDeviceLimitSelector,
  DataProvider,
} from "@nf/common";

type Props = {
  media?: IMediaModel;
};
export const MediaDetailsProgressContainer: ({
  media,
}: Props) => null | JSX.Element = ({ media }) => {
  const [progress, setProgress] = useState<undefined | number>(undefined);
  const isSeriesType = media?.MediaTypeCode === MediaType.Series;
  const { isLoggedIn } = useCanWatchAuthContent(media);
  const isOverDeviceLimit = useOverTheDeviceLimitSelector();

  useEffect(() => {
    if (!isSeriesType && media && isLoggedIn) {
      DataProvider.getMediaProgress(media).then(
        (mediaWithProgressOffset: IMediaModel) =>
          setProgress(calcProgress(mediaWithProgressOffset))
      );
    }
  }, [media]);

  const showProgress =
    isLoggedIn && media?.IsPlayable && !!progress && !isOverDeviceLimit;

  return showProgress ? (
    <ProgressBar className={styles.progressBar} progress={progress} />
  ) : null;
};
