/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "next-i18next";
import { IUserParentalRatingModel } from "@nf/common";
import cx from "classnames";
import styles from "./AgeSlider.module.scss";

interface IAgeSliderProps {
  ageRanges: IUserParentalRatingModel[];
  setAgeRange: (value: string) => void;
  prevAgeRangeIndex?: number;
}

export const AgeSlider = ({
  ageRanges,
  setAgeRange,
  prevAgeRangeIndex = 0,
}: IAgeSliderProps) => {
  const { t } = useTranslation("translations");
  const [isChecked, setIsChecked] = useState(
    ageRanges.map((_, index) => ({
      checked:
        index === 0 || prevAgeRangeIndex === index || prevAgeRangeIndex > index,
    }))
  );
  const [selectedIndex, setSelectedIndex] = useState(
    prevAgeRangeIndex !== -1 ? prevAgeRangeIndex : 0
  );

  const handleChange = (checkboxIndex: number) => {
    setIsChecked((prev) =>
      prev.map((_, index) => {
        if (checkboxIndex === index) {
          setSelectedIndex(checkboxIndex);
          return { checked: true };
        }

        if (checkboxIndex > index) return { checked: true };
        else return { checked: false };
      })
    );
  };

  useEffect(() => {
    setAgeRange(ageRanges[selectedIndex].ratings);
  }, [selectedIndex]);

  return (
    <>
      <div className={styles.slider}>
        <>
          {ageRanges.map((age, index) => (
            <div
              key={age.ratings}
              className={cx(styles.sliderElement, {
                [styles.sliderElementFirst]: index === 0,
              })}
            >
              <input
                onChange={() => handleChange(index)}
                type="checkbox"
                checked={isChecked[index].checked}
                className={styles.sliderCheckBox}
                value={age.ratings}
              />
              <label
                title={`${age.label} ${t("web_subprofiles_kids_years")}`}
                className={styles.sliderLabel}
              >
                {age.label}
                {t("web_subprofiles_kids_years")}
              </label>
            </div>
          ))}
        </>
      </div>

      <div className={styles.ageRestrictionText}>
        <Trans
          i18nKey={t("web_subprofiles_kids_profile_reminder")}
          components={{
            p: <p />,
            br: <br />,
          }}
        />
      </div>
    </>
  );
};

export default AgeSlider;
