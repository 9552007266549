/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  CellType,
  ImageHelper,
  RouteHelper,
  useCanWatchAuthContent,
} from "@nf/common";
import { TILE_OUT_OF_VIEW_WIDTH } from "@nf/constants";
import React, { useRef, useState } from "react";
import dynamic from "next/dynamic";
import { isServerSideRender } from "@nf/helpers";
import { IListComponentItemProps } from "../../models";
import { useRouter } from "next/router";
import cx from "classnames";
import styles from "./ListComponentItemCover.module.scss";
import { useTileEntry } from "../../../../hooks";

const AvailabilityLabel = dynamic(() => import("components/AvailabilityLabel"));

const ImageWithPlaceholder = dynamic(
  () => import("components/ImageWithPlaceholder")
);

const ListComponentItemModal = dynamic(
  () => import("../ListComponentItemModal/ListComponentItemModal")
);

export interface IListComponentItemCoverProps extends IListComponentItemProps {
  width: number;
}

export const ListComponentItemCover = ({
  media,
  isPlaceholder = false,
  gridColumns,
  query,
  cellType,
}: IListComponentItemCoverProps) => {
  const [isHover, setIsHover] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isOutOfView, setIsOutOfView] = useState(false);
  let mouseEnterTimeout: NodeJS.Timeout;
  const frameRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const isLargeCover = cellType === CellType.CoverLarge;
  const isDesktopSize = !isServerSideRender && window.innerWidth > 850;
  const coverImageUrl = ImageHelper.getPosterImageUrl(media?.Images) ?? "";
  const { canWatch, isLoggedIn } = useCanWatchAuthContent(media);
  const resize =
    gridColumns || isLargeCover
      ? { width: 1.2, height: 0.6 }
      : { width: 1.275, height: 0.65 };
  const tileEntryParams = {
    isDesktopSize,
    isHover,
    gridColumns,
    frameRef,
    cellType: CellType.Cover,
    resize,
  };
  const tileEntry = useTileEntry(tileEntryParams);

  const mouseLeave = () => {
    if (!isDesktopSize) return;
    setIsFadingOut(true);
    setTimeout(() => {
      setIsHover(false);
      setIsFadingOut(false);
      setIsOutOfView(false);
    }, 350);
    clearTimeout(mouseEnterTimeout);
  };

  const mouseEnter = () => {
    if (!isDesktopSize) return;
    mouseEnterTimeout = setTimeout(() => {
      setIsFadingOut(false);
      setIsHover(true);
      const boundingRect = frameRef?.current?.getBoundingClientRect();
      const tileRight =
        boundingRect &&
        boundingRect.right >
          (window.innerWidth + TILE_OUT_OF_VIEW_WIDTH ||
            document.documentElement.clientWidth + TILE_OUT_OF_VIEW_WIDTH);
      const tileLeft = boundingRect && boundingRect.left < 0;
      if (tileRight || tileLeft) {
        setIsOutOfView(true);
      } else {
        setIsOutOfView(false);
      }
    }, 200);
  };

  const goToMedia = () => {
    if (!isDesktopSize) {
      const detailsUrlObject = RouteHelper.goToDetails(media, query);
      router.push({
        pathname: `/${router.query.country}/${router.query.language}${detailsUrlObject.pathname}`,
        query: {
          ...query,
        },
      });
    }
  };

  if (!media || isPlaceholder) {
    return (
      <div className={styles.containerSkeleton}>
        <div className={styles.itemSkeleton} />
      </div>
    );
  }

  const isModalAvailable = isHover && !isOutOfView && isDesktopSize;
  return (
    <div
      className={cx(styles.item, isOutOfView && styles.isOutOfView)}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={goToMedia}
    >
      <div className={styles.container} ref={frameRef}>
        <div className={styles.imageContainer}>
          <AvailabilityLabel media={media} />
          <ImageWithPlaceholder
            alt={media.Title}
            imageSrc={coverImageUrl}
            imageContainerClassName={styles.image}
          />
        </div>
        {tileEntry &&
          tileEntry.dimension &&
          tileEntry.dimension.width &&
          tileEntry.dimension.height &&
          tileEntry.position &&
          isModalAvailable && (
            <ListComponentItemModal
              media={media}
              isLoggedIn={isLoggedIn}
              gridColumns={gridColumns}
              cellType={isLargeCover ? CellType.CoverLarge : CellType.Cover}
              isFadingOut={isFadingOut}
              query={query}
              width={tileEntry.dimension.width}
              height={tileEntry.dimension.height}
              rectTop={tileEntry.position.top}
              rectLeft={tileEntry.position.left}
              rectRight={tileEntry.position.right}
            />
          )}
      </div>
    </div>
  );
};
