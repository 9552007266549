/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import RcSelect, { Option, OptGroup } from "rc-select";
import ChevronDown from "../../resources/icons/chevron-down.svg";

interface ISelectProps {
  onChange?: (e: string | undefined) => void;
  value: any;
  children?: React.ReactNode;
  onDropdownVisibleChange?: (value: boolean) => void;
  placeholder?: string;
}

export const Select = ({ children, value, ...props }: ISelectProps) => {
  return (
    <RcSelect
      prefixCls="Select"
      inputIcon={() => <ChevronDown />}
      menuItemSelectedIcon={() => null}
      value={value}
      defaultActiveFirstOption={false}
      {...props}
    >
      {children}
    </RcSelect>
  );
};

export { Option, OptGroup };
