/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IMediaModel,
  mediaDetailsTagHelper,
  MediaType,
  TimeHelper,
} from "@nf/common";
import React from "react";
import { useTranslation } from "next-i18next";
import cx from "classnames";
import styles from "./MediaAdditionalInfo.module.scss";

export interface MediaAdditionalInfoProps {
  media: IMediaModel;
  className?: string;
  showDuration?: boolean;
  showComma?: boolean; // chnage separator from "," to "|"
}

export const MediaAdditionalInfo = ({
  media,
  className,
  showDuration = true,
  showComma = true,
}: MediaAdditionalInfoProps) => {
  const { t } = useTranslation("translations");
  const mediaAdditionalInformations: string[] = [];

  if (media.MediaTypeCode === MediaType.Collection) {
    return (
      <div className={cx(styles.additionalInfo, className)}>
        {t("web_collection")}
      </div>
    );
  }

  if (media.Categories && media.Categories.length > 0) {
    mediaAdditionalInformations.push(
      media.Categories &&
        mediaDetailsTagHelper(media.Categories)[0]?.CategoryName
    );
  }

  if (media.Year) {
    mediaAdditionalInformations.push(`${media.Year}`);
  }

  if (media.Duration && showDuration) {
    mediaAdditionalInformations.push(
      `${TimeHelper.formatDuration(media.Duration)}`
    );
  }

  const mediaAdditionalInfo = !showComma
    ? mediaAdditionalInformations.join(" | ")
    : mediaAdditionalInformations.join(", ");

  if (!mediaAdditionalInfo) {
    return null;
  }

  return (
    <div className={cx(styles.additionalInfo, className)}>
      {mediaAdditionalInfo}
    </div>
  );
};

export default MediaAdditionalInfo;
