import { ROUTES } from "@nf/constants";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { LinkWithLocale } from "../../LinkWithLocale";

import styles from "./NoSubscriptionsContainer.module.scss";

const SettingsSubscriptionButton = dynamic(
  import("components/Settings/SettingsSubscriptionButton")
);

export const NoSubscriptionsContainer = () => {
  const { t } = useTranslation("translations");
  return (
    <div className={styles.container}>
      <p className={styles.text}>{t("web_subscription_no_subscription")}</p>
      <LinkWithLocale href={ROUTES.PAYMENT_SUBSCRIPTION}>
        <a>
          <SettingsSubscriptionButton wider variant="primary">
            {t("web_subscription_no_subscription_button")}
          </SettingsSubscriptionButton>
        </a>
      </LinkWithLocale>
    </div>
  );
};

export default NoSubscriptionsContainer;
