/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  filterPeopleByRole,
  IConfigurationModel,
  ImageHelper,
  IMediaModel,
  PersonInAssetType,
  RouteHelper,
} from "@nf/common";
import { useCountry, useLanguage } from "hooks";
import { NextSeo } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";
import getMediaSchema from "schema/getMovieSchema";
import getOpenGraphType from "schema/getOpenGraphType";
import getOpenGraphVideo from "schema/getOpenGraphVideo";
import getWebPageSchema from "schema/getWebPageSchema";
import StructuredData from "./StructuredData";
import { useMediaMetaDescription, useMediaMetaTitle } from "./utils";

export interface IMediaSeoProps {
  media: IMediaModel;
  configuration?: IConfigurationModel;
}

export const MediaSEO = ({ media, configuration }: IMediaSeoProps) => {
  const router = useRouter();
  const country = useCountry();
  const language = useLanguage();

  const imageUrl =
    ImageHelper.getHighlightsImageUrl(media?.Images) ||
    ImageHelper.getHeroAssetDetailsImageUrl(media?.Images);
  const href = process.env.NEXT_PUBLIC_MAIN_DOMAIN + router.asPath;
  const canonicalUrl = RouteHelper.getMediaCanonicalUrl(
    router.pathname,
    media,
    configuration
  );

  const actors = filterPeopleByRole(media.People, PersonInAssetType.Actor)?.map(
    (actor) => actor.PersonFullName
  );
  const directors = filterPeopleByRole(
    media.People,
    PersonInAssetType.Director
  )?.map((director) => director.PersonFullName);

  const categories = media?.Categories?.map(
    (category) => category.CategoryName
  );
  const duration = media?.Duration;
  const availableFrom = media?.AvailableFrom;

  const openGraphType = getOpenGraphType(media?.MediaTypeCode);
  const openGraphVideo = getOpenGraphVideo({
    actors,
    directors,
    categories,
    duration,
    availableFrom,
  });

  const metaTitle = useMediaMetaTitle(media);
  const metaDescription = useMediaMetaDescription(media);

  return (
    <>
      <Head>
        {media && (
          <StructuredData
            schemas={[
              getWebPageSchema(actors, directors),
              getMediaSchema({
                media,
                image: imageUrl,
                actors,
                directors,
                country,
                language,
              }),
            ]}
          />
        )}
        <title>{metaTitle}</title>
      </Head>
      <NextSeo
        title={metaTitle}
        titleTemplate="%s"
        description={metaDescription}
        canonical={canonicalUrl}
        openGraph={{
          title: metaTitle,
          description: metaDescription,
          url: href,
          site_name: process.env.NEXT_PUBLIC_NAME,
          type: openGraphType,
          video: openGraphVideo,
          images: [{ url: imageUrl ?? "" }],
        }}
      />
    </>
  );
};

export default MediaSEO;
