import {
  dispatch,
  IErrorModel,
  IUserSubscriptionModel,
  UserStore,
  useSubscriptionsSelector,
} from "@nf/common";
import { useEffect } from "react";

type TUserSubscriptions = {
  error?: IErrorModel;
  isLoading?: boolean;
  Completed?: boolean;
  subscriptions: IUserSubscriptionModel[] | undefined;
  hasActiveSubscriptions: boolean;
};

export const useUserSubscriptions = (): TUserSubscriptions => {
  const { error, isLoading, subscriptions, Completed } =
    useSubscriptionsSelector();

  useEffect(() => {
    !subscriptions &&
      !Completed &&
      !isLoading &&
      dispatch(UserStore.Actions.getUserSubscriptions());
  }, [Completed, isLoading]);

  const hasActiveSubscriptions =
    (subscriptions && subscriptions.length > 0 && !isLoading) || false;

  return {
    error,
    isLoading,
    Completed: Completed ?? false,
    hasActiveSubscriptions,
    subscriptions,
  };
};
