/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { PaymentStore, dispatch } from "@nf/common";
import { Dialog, MediaButton } from "components";
import { SettingsSubscriptionButton } from "../../../SettingsSubscriptionButton/SettingsSubscriptionButton";
import { DialogContentWrapper } from "../DialogContentWrapper";

import styles from "./RegretSubscriptionDialog.module.scss";

export interface IRegretSubscriptionDialogProps {
  id: number;
  isFormSent: boolean;
  setIsFormSent: (value: boolean) => void;
  children?: React.ReactNode;
  isLoading?: boolean;
}

export const RegretSubscriptionDialog = ({
  id,
  isLoading,
  isFormSent,
  setIsFormSent,
}: IRegretSubscriptionDialogProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation("translations");

  const handleClick = () => {
    setIsVisible((prev) => !prev);
  };

  const onConfirm = () => {
    try {
      dispatch(PaymentStore.Actions.cancelSubscriptionSwitch(id));
      setIsFormSent(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isFormSent && !isLoading) setIsVisible(false);
  }, [isFormSent, isLoading]);

  return (
    <div className={styles.ContentContainer}>
      <div>
        <SettingsSubscriptionButton
          variant="plain"
          type="submit"
          onClick={isVisible ? undefined : handleClick}
        >
          {t("web_regret_subscription_button", "")}
        </SettingsSubscriptionButton>
      </div>
      <Dialog
        wrapClassName={styles.DialogWrap}
        className={styles.DialogContainer}
        destroyOnClose={true}
        footer={null}
        visible={isVisible}
        onCancel={handleClick}
      >
        <DialogContentWrapper title={t("web_regret_subscription_header")}>
          <div className={styles.Text}>
            <p>{t("web_regret_subscription_info")}</p>
          </div>
          <div className={styles.ActionRow}>
            <MediaButton
              variant="plain"
              onClick={handleClick}
              className={styles.CancelButton}
            >
              {t("web_cancel", "")}
            </MediaButton>
            <MediaButton variant="primary" onClick={onConfirm}>
              {t("web_confirm", "")}
            </MediaButton>
          </div>
        </DialogContentWrapper>
      </Dialog>
    </div>
  );
};
