/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { useTranslation } from "next-i18next";

import CheckmarkRoundIcon from "../../resources/icons/payments/checkmark-round.svg";

import styles from "./PaymentStatus.module.scss";

export const UpdatePaymentStatusSuccess = () => {
  const { t } = useTranslation("translations");

  return (
    <div className={styles.status}>
      <CheckmarkRoundIcon className={styles.icon} />
      <p className={styles.info}>{t("web_payment_update_success")}</p>
    </div>
  );
};
