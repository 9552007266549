/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { EXTERNAL_LINKS } from "@nf/constants";
import React from "react";
import { Trans, useTranslation } from "next-i18next";
import styles from "./SettingsUserData.module.scss";

export const SettingsUserData = () => {
  const { t } = useTranslation("translations");
  return (
    <div>
      <div>
        <p className={styles.Text}>{t("web_user_data_entity")}</p>
        <p className={styles.Text}>
          <Trans
            i18nKey={t("web_user_data_read_more")}
            components={{
              1: (
                <a
                  href={EXTERNAL_LINKS.PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </p>
        <p className={styles.Text}>
          <Trans
            i18nKey={t("web_user_data_get_data_instruction")}
            components={{
              1: (
                <a
                  href={EXTERNAL_LINKS.HELPCENTER}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </p>
        {/* Functionality moved to phase 2 */}
        {/* <MediaButton variant="primary" className={styles.Button}>
          {t("web_user_data_send_email")}
        </MediaButton> */}
      </div>

      <div>
        <div className={styles.Title}>Delete all user data</div>
        <p className={styles.Text}>
          <Trans
            i18nKey={t("web_user_data_delete_data_instruction")}
            components={{
              1: (
                <a
                  href={EXTERNAL_LINKS.HELPCENTER}
                  target="_blank"
                  rel="noreferrer"
                />
              ),
            }}
          />
        </p>
        {/* Functionality moved to phase 2 */}
        {/* <MediaButton variant="primary" className={styles.Button}>
          {t("web_user_data_send_email")}
        </MediaButton> */}
      </div>
    </div>
  );
};

export default SettingsUserData;
