/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IErrorModel } from "@nf/common";
import { CommonTextError } from "../CommonTextError";
import dynamic from "next/dynamic";

const DotsLoader = dynamic(() => import("components/DotsLoader"), {
  ssr: false,
});

import styles from "./ActionsHandler.module.scss";

export interface IActionsHandlerProps {
  isLoading?: boolean;
  error?: IErrorModel;
  children?: JSX.Element;
  centeredError?: boolean;
  errorTranslationKey?: string;
}

export const ActionsHandler = ({
  children,
  error,
  isLoading,
  centeredError,
  errorTranslationKey,
}: IActionsHandlerProps) => {
  return (
    <>
      {!isLoading && children}
      {isLoading && !error && (
        <div className={styles.dotsContainer}>
          <DotsLoader width={100} />
        </div>
      )}
      {!isLoading && error && (
        <CommonTextError
          translationKey={errorTranslationKey}
          centered={centeredError}
        />
      )}
    </>
  );
};

export default ActionsHandler;
