import { useSelector } from "@nf/common";

export const useDataPayment = () => {
  const {
    offers: {
      IsProcessing: areOffersLoading,
      Error: offersError,
      Initialized: offersInitialized,
      Completed: offersCompleted,
    },
  } = useSelector((state) => state.payment);

  const offers = useSelector((state) => state.payment.offers.Data?.offers);

  return {
    areOffersLoading,
    offersError,
    offers,
    offersInitialized,
    offersCompleted,
  };
};
