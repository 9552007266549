import styles from "./WelcomeScreen.module.scss";
import dynamic from "next/dynamic";
import { useIsLoggedIn } from "@nf/common";
import { MDUp, XSSM } from "../../components";
import { DotsLoader } from "../../components/DotsLoader";
import React from "react";
import { useRouter } from "next/router";
import { LoginSplash } from "./components/LoginSplash/LoginSplash";

const InitialWelcome = dynamic(
  () => import("screens/WelcomeScreen/components/InitialWelcome")
);

export const MOBILE_PARAM_SUBSCRIPTIONS = "subscriptions";
export const MOBILE_PARAM_SUBSCRIPTION_VALUE = "active";

export const WelcomeScreen = () => {
  const { query } = useRouter();
  const isLoggedIn = useIsLoggedIn();

  const showViewForSubscribedAccount =
    query[MOBILE_PARAM_SUBSCRIPTIONS] === MOBILE_PARAM_SUBSCRIPTION_VALUE;

  return isLoggedIn ? (
    <section className={styles.welcomeContainer}>
      <XSSM>
        {showViewForSubscribedAccount ? <LoginSplash /> : <InitialWelcome />}
      </XSSM>
      <MDUp>
        <div className={styles.dotsContainer}>
          <DotsLoader />
        </div>
      </MDUp>
    </section>
  ) : null;
};

export default WelcomeScreen;
