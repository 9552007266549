/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { PlayerError } from "../components/VideoPlayer/types";

export const videoPlayerErrorHelper = (error: PlayerError | undefined) => {
  switch (error?.code) {
    case 905:
    case 3000:
    case 3016:
    case 3017:
      return { ...error, message: "CHROMECAST_PLAYER_ASSET_ERROR" };
    case 321:
    case 1002:
    case 1003:
      return { ...error, message: "CHROMECAST_PLAYER_NETWORK_ERROR" };
    default:
      return error;
  }
};
