/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { DeviceHelper } from "@nf/common";
import React from "react";
import dynamic from "next/dynamic";

const AppFooterTabletMobile = dynamic(
  () => import("components/AppFooter/AppFooterTabletMobile"),
  { ssr: false }
);

const AppFooterMenu = dynamic(
  () => import("components/AppFooter/AppFooterDesktop"),
  { ssr: false }
);

export type AppFooterProps = {
  config?: unknown;
  displayPhoneMenu?: boolean;
  displayDesktopMenu?: boolean;
};

export const AppFooter = ({
  config,
  displayDesktopMenu,
  displayPhoneMenu,
}: AppFooterProps) => {
  const isPhone = React.useMemo(() => DeviceHelper.isPhone(), []);
  const displayAppFooterTabletMobile = isPhone || displayPhoneMenu;
  return (
    <>
      {displayAppFooterTabletMobile && <AppFooterTabletMobile />}
      {displayDesktopMenu && (
        <AppFooterMenu
          config={config}
          isAppFooterTabletMobileDisplayed={displayAppFooterTabletMobile}
        />
      )}
    </>
  );
};

export default AppFooter;
