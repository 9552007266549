/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import styles from "./PaymentStatus.module.scss";

export const PaymentStatusCancelled = () => {
  return (
    <div className={styles.status}>
      <p className={styles.info}>Payment has been cancelled</p>
    </div>
  );
};
