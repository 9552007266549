/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  ActionType,
  ConfigurationHelper,
  IApplicationMenuItemComponentModel,
} from "@nf/common";
import { useRouter } from "next/router";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { LinkWithLocale, MenuItem } from "..";
import cx from "classnames";
import { useCountry, useLanguage } from "hooks";
import styles from "./ApplicationMenuItem.module.scss";

export interface IApplicationMenuItemProps {
  component: IApplicationMenuItemComponentModel;
}

export const ApplicationMenuItem = ({
  component,
  ...props
}: IApplicationMenuItemProps) => {
  const { t } = useTranslation("translations");
  const router = useRouter();
  const title = t(component?.TitleTranslationKey || "", component?.Title);
  const screenKey = ConfigurationHelper.getScreenRouteKey(
    component?.Action?.ScreenId,
    component?.Action?.ScreenTypeCode
  );

  const country = useCountry();
  const language = useLanguage();

  const isActive = useCallback(
    (key: string) => {
      const path = router.query.id;
      return key === path;
    },
    [screenKey, router.asPath]
  );

  useEffect(() => {
    router.prefetch(`/${country}/${language}/${screenKey}`);
  }, []);

  return (
    <MenuItem {...props}>
      {component?.Action?.ActionType === ActionType.OpenScreen && (
        <LinkWithLocale href={`/${screenKey}`}>
          <a
            data-title={title}
            className={cx(styles.item, {
              [styles.itemActive]: isActive(screenKey),
            })}
          >
            {title}
          </a>
        </LinkWithLocale>
      )}

      {component?.Action?.ActionType === ActionType.OpenUrl && (
        <a
          data-title={title}
          target="_blank"
          className={cx(styles.item, {
            [styles.itemActive]: isActive(screenKey),
          })}
        >
          {title}
        </a>
      )}
    </MenuItem>
  );
};

export default ApplicationMenuItem;
