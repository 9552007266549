/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useCallback } from "react";
import {
  IUserSubscriptionModel,
  useDispatch,
  UserStore,
  UserSubscriptionStatus,
} from "@nf/common";
import { ROUTES } from "@nf/constants";
import dynamic from "next/dynamic";
import { Trans, useTranslation } from "next-i18next";
import { PauseOrCancelSubscriptionDialog } from "../PauseOrCancelSubscriptionDialog";
import styles from "./SettingsSubscriptionContainer.module.scss";
import Checkmark from "resources/icons/checkmark.svg";

const SettingsSubscriptionButton = dynamic(
  () => import("components/Settings/SettingsSubscriptionButton")
);

import { getExpirationDate } from "../ExpirationDate/ExpirationDate";
import { useRouter } from "next/router";
import {
  isSubscriptionCancelable,
  isSubscriptionHold,
} from "../../../../../helpers";
import { useGeneratePath } from "../../../../../hooks";
import { SwitchSubscriptionInfo } from "../SwitchSubscriptionInfo/SwitchSubscriptionInfo";
import { Web_subscription_change_description } from "../../../../TranslationsContainers/Web_subscription_change_description";
import { PaymentDetailType } from "../../../../PaymentDetailType";
import { RegretSubscriptionDialog } from "../RegretSubscriptionDialog";

interface ISettingsSubscriptionContainerProps {
  subscription: IUserSubscriptionModel;
  isActive: boolean;
  isFormSent: boolean;
  setIsFormSent: (value: boolean) => void;
  setIsPauseFormSent: (value: boolean) => void;
  isLoading?: boolean;
  period?: string;
  hasActiveSub?: boolean;
  subscriptionStatus?: UserSubscriptionStatus;
}

const getSubscriptionStatusColor = ({
  IsActive,
  isExpired,
}: IUserSubscriptionModel): string => {
  if (isExpired) {
    return "#EC1B2E";
  }
  if (IsActive) {
    return "#52FB85";
  }
  if (!IsActive) {
    return "#F4D167";
  }
  return "transparent";
};

const SubscriptionsButtonContainer: React.FC<
  ISettingsSubscriptionContainerProps
> = ({
  isActive,
  subscription: {
    Id,
    ExpirationDate,
    IsPartnerSubscription,
    SwitchToOfferId,
    SwitchToOfferPeriod,
    Period,
    CampaignName,
    OfferId,
  },
  setIsFormSent,
  setIsPauseFormSent,
  isFormSent,
  isLoading,
  hasActiveSub,
  subscriptionStatus,
}) => {
  const { t } = useTranslation("translations");
  const expirationDate = getExpirationDate(ExpirationDate);
  const dispatch = useDispatch();
  const { push } = useRouter();
  const generatePath = useGeneratePath();

  const updateToActive = useCallback(() => {
    dispatch(UserStore.Actions.updateSubscription(Id));
    setIsFormSent(true);
  }, [Id]);

  // const changeSubscription = useCallback(() => {
  //   push({
  //     pathname: generatePath(ROUTES.PAYMENT_CHANGE_SUBSCRIPTION),
  //   });
  // }, [push, generatePath]);

  const canCancelSubscription = isSubscriptionCancelable(CampaignName);

  // const canChangeSubscription: boolean =
  //   canCancelSubscription && SwitchToOfferId === null;

  if (isActive) {
    return (
      <div className={styles.buttonContainer}>
        {SwitchToOfferId && SwitchToOfferPeriod && (
          <>
            <div className={styles.InfoText}>
              <SwitchSubscriptionInfo
                SwitchToOfferId={SwitchToOfferId}
                SwitchToOfferPeriod={SwitchToOfferPeriod}
                ExpirationDate={ExpirationDate}
              />
            </div>
            <RegretSubscriptionDialog
              id={Id}
              isFormSent={isFormSent}
              setIsFormSent={setIsFormSent}
              isLoading={isLoading}
            />
          </>
        )}
        {/*
          FIXME Temporarily disabled until all users are migrated to Reepay 
          and API is adjusted to new payment provider
        */}
        {/* {canChangeSubscription && (
          <>
            {Period === "month" && (
              <p>
                <Web_subscription_change_description />
              </p>
            )}
            <SettingsSubscriptionButton
              onClick={changeSubscription}
              variant="primary"
            >
              {t("web_subscription_change_button", "")}
            </SettingsSubscriptionButton>
          </>
        )} */}
        {canCancelSubscription && (
          <PauseOrCancelSubscriptionDialog
            id={Id}
            endDate={expirationDate}
            isFormSent={isFormSent}
            setIsFormSent={setIsFormSent}
            isLoading={isLoading}
            setIsPauseFormSent={setIsPauseFormSent}
            offerId={parseInt(OfferId)}
          />
        )}
      </div>
    );
  }

  /*
    FIXME Temporarily disabled until all users are migrated to Reepay 
    and API is adjusted to new payment provider
  */
  // if (
  //   !IsPartnerSubscription &&
  //   !hasActiveSub &&
  //   subscriptionStatus === UserSubscriptionStatus.CANCELLED
  // ) {
  //   return (
  //     <>
  //       <div className={styles.InfoText}>
  //         {t("web_subscription_subscription_restart_into", "")}
  //       </div>
  //       <SettingsSubscriptionButton onClick={updateToActive} variant="primary">
  //         {t("web_subscription_restart_subscription", "")}
  //       </SettingsSubscriptionButton>
  //     </>
  //   );
  // }

  return null;
};

export const SettingsSubscriptionContainer = ({
  subscription,
  isActive,
  isLoading,
  isFormSent,
  setIsFormSent,
  setIsPauseFormSent,
  hasActiveSub,
  subscriptionStatus,
}: ISettingsSubscriptionContainerProps) => {
  const { t } = useTranslation("translations");
  const expirationDate = getExpirationDate(subscription.ExpirationDate);
  const isSubscriptionPaused = isSubscriptionHold(subscriptionStatus);
  const canCancelSubscription = isSubscriptionCancelable(
    subscription.CampaignName
  );

  return (
    <>
      {!isActive && !isSubscriptionPaused && (
        <div className={styles.Container}>
          <div className={styles.checkmark}>
            <Checkmark />
          </div>
          <div style={{ width: "90%" }} className={styles.InfoText}>
            {t("web_subscription_cancel_peroid", "")}
            <span className={styles.FatText}>{expirationDate}</span>
          </div>
        </div>
      )}

      <div className={styles.Container}>
        <div
          className={styles.StatusIndicator}
          style={{
            backgroundColor: getSubscriptionStatusColor(subscription),
          }}
        />
        <div style={{ width: "90%" }}>
          {!subscription.IsPartnerSubscription ? (
            <>
              <div className={styles.SubscriptionInfo}>
                <PaymentDetailType
                  subscription={subscription}
                  className={styles.PaymentDetailType}
                />
                {!isSubscriptionPaused && (
                  <div className={styles.Item}>
                    <span className={styles.ItemTitle}>
                      {canCancelSubscription
                        ? isActive
                          ? t("web_subscription_next_renewal", "")
                          : t("web_subscription_subscription_ends", "")
                        : t("web_subscription_expiry", "")}
                      :
                    </span>
                    {`${expirationDate}.`}
                  </div>
                )}
                <div className={styles.Item}>
                  <span className={styles.ItemTitle}>
                    {t("web_subscription_status", "")}
                  </span>
                  {t(`web_subscription_status_${subscriptionStatus}`)}
                </div>
              </div>
              <div className={styles.ButtonContainer}>
                <SubscriptionsButtonContainer
                  subscription={subscription}
                  isActive={isActive}
                  isFormSent={isFormSent}
                  setIsFormSent={setIsFormSent}
                  setIsPauseFormSent={setIsPauseFormSent}
                  isLoading={isLoading}
                  hasActiveSub={hasActiveSub}
                  subscriptionStatus={subscriptionStatus}
                />
              </div>
            </>
          ) : (
            <p className={styles.PartnerInfo}>
              <span className={styles.PartnerInfoText}>
                <Trans
                  i18nKey={t(
                    "web_subscription_partner_subscription_info_one",
                    ""
                  )}
                  values={{ name: `<bold>${subscription.PartnerName}</bold>` }}
                  components={{
                    bold: <strong />,
                  }}
                />
              </span>
              <span>
                <Trans
                  i18nKey={t(
                    "web_subscription_partner_subscription_info_two",
                    ""
                  )}
                  values={{ name: `<bold>${subscription.PartnerName}</bold>` }}
                  components={{
                    bold: <strong />,
                  }}
                />
              </span>
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default SettingsSubscriptionContainer;
