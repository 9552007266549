import { MediaType } from "@nf/common";

const getOpenGraphType = (type?: string) => {
  switch (type) {
    case MediaType.Video:
      return "video.movie";
    case MediaType.Series:
      return "video.tv_show";
    case MediaType.Episode:
      return "video.episode";
    default:
      return "video.other";
  }
};

export default getOpenGraphType;
