import styles from "./LandingPageReviews.module.scss";
import React, { FC } from "react";
import dynamic from "next/dynamic";
import { ITextComponentModel } from "@nf/common/dist/models/Configuration/ITextComponentModel";

const SectionContainer = dynamic(() => import("../SectionContainer"));

const STAR_SYMBOL = <>&#9733;</>;

const Stars = ({ starsCount }: { starsCount: number }) => {
  const stars = Array.from({ length: starsCount }, (_, i) => i);
  return (
    <>
      {stars.map((star) => (
        <div key={`${star}`} className={styles.star}>
          {STAR_SYMBOL}
        </div>
      ))}
    </>
  );
};

type TLandingPageReview = {
  component: ITextComponentModel;
};

type TData = {
  title: string;
  stars: number;
  reviewSource: string;
};

export const LandingPageReview: FC<TLandingPageReview> = ({ component }) => {
  const reviews = component.TextParsed
    ? (component.TextParsed as unknown as TData[])
    : [];
  return (
    <SectionContainer
      title={component.Title}
      className={styles.container}
      includePadding
    >
      <div className={styles.list}>
        {reviews.map((element, index) => (
          <div className={styles.listRow} key={`${index}-${element.title}`}>
            <div className={styles.starsRow}>
              <Stars starsCount={element.stars} />
            </div>
            <pre className={styles.title}>{element.title}</pre>
            <div className={styles.reviewSource}>{element.reviewSource}</div>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};
