import { UrlObject } from "url";
import { useRoutePathBuild, useRedirectUrlParam } from "../../../hooks";
import { useMemo } from "react";

export const useRouterRedirectionParams = (): UrlObject | undefined => {
  const routePrefix = useRoutePathBuild();
  const redirectUrlParam = useRedirectUrlParam();

  return useMemo(() => {
    if (redirectUrlParam) {
      return {
        pathname: `${routePrefix}${redirectUrlParam}`,
      };
    }

    return undefined;
  }, [redirectUrlParam, routePrefix]);
};
