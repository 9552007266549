/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useContext, useLayoutEffect, useMemo, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { isSafari } from "react-device-detect";
import ReactDOM from "react-dom";
import Color from "color";

import {
  ImageHelper,
  IMediaModel,
  ThemeContext,
  RouteHelper,
  CellType,
  mediaDetailsTagHelper,
  UrlQuery,
  MediaType,
} from "@nf/common";
import placeholder from "resources/icons/placeholder.png";
import InfoCircleIcon from "resources/icons/info-circle.svg";
import dynamic from "next/dynamic";

const PlayIconButton = dynamic(() => import("components/PlayIconButton"));

const PlayInfo = dynamic(() => import("components/PlayInfo"));

const MyListButton = dynamic(() => import("components/MyListButton"));

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

const AppImage = dynamic(() => import("components/AppImage"), { ssr: false });

import styles from "./ListComponentItemModal.module.scss";

export interface IListComponentItemModalProps {
  media: IMediaModel;
  isLoggedIn: boolean;
  cellType?: CellType;
  gridColumns?: number;
  width: number;
  height: number;
  isFadingOut: boolean;
  rectTop: number;
  rectLeft: number;
  rectRight: number;
  query?: UrlQuery;
}

const ListComponentItemModal = ({
  media,
  isLoggedIn,
  cellType,
  gridColumns,
  width,
  height,
  isFadingOut,
  rectTop,
  rectLeft,
  rectRight,
  query,
}: IListComponentItemModalProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const [isFirstTile, setIsFirstTile] = useState(false);
  const [isLastTile, setIsLastTile] = useState(false);
  const isLargeDesktop = window && window.innerWidth > 1537;
  const frameImageUrl = ImageHelper.getHoverAssetImageUrl(media?.Images);
  const mainMediaCategory = mediaDetailsTagHelper(media.Categories)[0]
    ?.CategoryName;

  useLayoutEffect(() => {
    const firstTile = rectLeft < 135;
    if (cellType === CellType.Frame || cellType === CellType.FrameLarge) {
      const lastTile = rectRight > window.innerWidth - 10;
      setIsLastTile(lastTile);
    } else {
      const lastTile = rectRight > window.innerWidth - 195;
      setIsLastTile(lastTile);
    }
    setIsFirstTile(firstTile);
  }, [cellType, width, height]);

  const calcPosition = useMemo(() => {
    let position: number;
    const lastTileHover = window.innerWidth - width * 2;
    const rectLeftInVw = (rectLeft / innerWidth) * 100;
    const frameLarge_firstTileRectPosition = rectLeft + 15;
    const frameLarge_lastTileRectPosition = window.innerWidth - width * 1.7;
    const frameLarge_otherTileRectPosition = isLargeDesktop
      ? rectLeft + 30
      : rectLeft;
    const frame_firstTileRectPosition = isLargeDesktop
      ? rectLeft + 70
      : rectLeft + 50;
    const frame_otherTileRectPosition = isLargeDesktop
      ? rectLeft + 10
      : rectLeft;
    const cover_firstTileRect = isLargeDesktop
      ? rectLeftInVw + 7
      : rectLeftInVw + 5.5;
    const cover_firstTileRectPosition = isLargeDesktop
      ? rectLeft + 155
      : rectLeft + 75;
    const cover_lastTileRectPosition = isLargeDesktop
      ? lastTileHover + 50
      : lastTileHover;
    const cover_otherTileRectPosition = isLargeDesktop
      ? rectLeft + 10
      : rectLeft;
    const coverLarge_firstTileRectPosition = isLargeDesktop
      ? rectLeft + 155
      : rectLeft + 75;
    const coverLarge_lastTileRectPosition = isLargeDesktop
      ? lastTileHover + 50
      : lastTileHover;
    const coverLarge_otherTileRectPosition = isLargeDesktop
      ? rectLeft + 10
      : rectLeft;

    switch (cellType) {
      case CellType.FrameLarge:
        if (gridColumns) {
          return {
            top: `${rectTop}px`,
            left: `${rectLeft}px`,
          };
        }
        position = isFirstTile
          ? frameLarge_firstTileRectPosition
          : isLastTile
          ? frameLarge_lastTileRectPosition
          : frameLarge_otherTileRectPosition;
        return {
          top: `${rectTop}px`,
          left: `${position}px`,
        };
      case CellType.Frame:
        if (gridColumns) {
          return {
            top: `${rectTop}px`,
            left: `${rectLeft}px`,
          };
        }
        position = isFirstTile
          ? frame_firstTileRectPosition
          : isLastTile
          ? lastTileHover
          : frame_otherTileRectPosition;
        return {
          top: `${rectTop}px`,
          left: `${position}px`,
        };
      case CellType.Cover:
        if (gridColumns) {
          position = isFirstTile
            ? cover_firstTileRect
            : isLastTile
            ? rectLeftInVw - 5.5
            : rectLeftInVw;
          return {
            top: `${rectTop}px`,
            left: `${position}vw`,
          };
        }
        position = isFirstTile
          ? cover_firstTileRectPosition
          : isLastTile
          ? cover_lastTileRectPosition
          : cover_otherTileRectPosition;
        return {
          top: `${rectTop}px`,
          left: `${position}px`,
        };
      case CellType.CoverLarge:
        if (gridColumns) {
          position = isFirstTile
            ? isLargeDesktop
              ? rectLeftInVw + 14
              : rectLeftInVw + 10
            : isLastTile
            ? isLargeDesktop
              ? rectLeftInVw - 14
              : rectLeftInVw - 10
            : rectLeftInVw;
          return {
            top: `${rectTop}px`,
            left: `${position}vw`,
          };
        }
        position = isFirstTile
          ? coverLarge_firstTileRectPosition
          : isLastTile
          ? coverLarge_lastTileRectPosition
          : coverLarge_otherTileRectPosition;
        return {
          top: `${rectTop}px`,
          left: `${position}px`,
        };
      default:
        return {
          top: `${rectTop}px`,
          left: `${rectLeft}px`,
        };
    }
  }, [
    cellType,
    gridColumns,
    isFirstTile,
    isLastTile,
    rectLeft,
    rectRight,
    rectTop,
    isFadingOut,
    window.innerWidth,
  ]);

  const { modal } = useSpring({
    from: { modal: 0 },
    modal: !isFadingOut ? 1 : 0,
    delay: !isFadingOut ? 300 : 0,
    reverse: isFadingOut,
    config: { duration: 0 },
  });

  const { context } = useSpring({
    from: { context: 0 },
    context: !isFadingOut ? 1 : 0,
    delay: isFadingOut ? 50 : isSafari ? 1100 : 900,
    reverse: isFadingOut,
    config: { duration: 200 },
  });

  const contextStyle = {
    opacity: context.to({
      range: [0, 0.25, 0.5, 0.75, 1],
      output: [0, 0.25, 0.5, 0.75, 1],
    }),
  };

  return ReactDOM.createPortal(
    <animated.div
      className={styles.modal}
      style={{
        ...calcPosition,
        width: `${width}px`,
        height: `${height}px`,
        opacity: modal.to({
          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
          output: [0, 0, 0, 0, 0.3, 0.4, 0.5, 1],
        }),
        scale: modal.to({
          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
          output: [1, 1, 1, 1.2, 1.4, 1.6, 1.8, 2],
        }),
      }}
    >
      <AppImage
        className={styles.Image}
        src={frameImageUrl || placeholder.src || ""}
        layout="fill"
        sizes="33vw"
        alt={media.Title || ""}
        onlyWidth
      />

      <animated.div className={styles.top} style={contextStyle}>
        <PlayInfo media={media} isModal>
          <PlayIconButton media={media} query={query} />
        </PlayInfo>
      </animated.div>

      <div
        className={styles.bottom}
        style={{
          background: `linear-gradient(
          to bottom,
          ${Color(themeProvider.getColor("AppBackgroundColor"))
            .alpha(0)
            .string()},
          ${Color(themeProvider.getColor("AppBackgroundColor"))
            .alpha(0.35)
            .string()},
          ${Color(themeProvider.getColor("AppBackgroundColor"))
            .alpha(0.7)
            .string()},
          ${Color(themeProvider.getColor("AppBackgroundColor"))
            .alpha(0.9)
            .string()},
          ${Color(themeProvider.getColor("AppBackgroundColor"))
            .alpha(0.99)
            .string()},
          ${Color(themeProvider.getColor("AppBackgroundColor"))
            .alpha(1)
            .string()}
        )`,
        }}
      >
        <animated.div className={styles.info} style={contextStyle}>
          <div className={styles.text}>
            <h2>{media.Title}</h2>
            <p>
              {mainMediaCategory}
              {mainMediaCategory && media.Year && " | "}
              {media.Year}
            </p>
          </div>
          <div className={styles.icons}>
            <LinkWithLocale href={RouteHelper.goToDetails(media, query)}>
              <a>
                <InfoCircleIcon className={styles.infocircle} />
              </a>
            </LinkWithLocale>
            {isLoggedIn && media.MediaTypeCode !== MediaType.Collection && (
              <MyListButton media={media} showLabel={false} />
            )}
          </div>
        </animated.div>
        <animated.div className={styles.description} style={contextStyle}>
          <p>{media.ShortDescription}</p>
        </animated.div>
      </div>

      {!isLoggedIn && (
        <LinkWithLocale href={RouteHelper.goToDetails(media, query)}>
          <a
            className={styles.icon}
            style={{ position: "absolute", width: "100%", height: "100%" }}
          />
        </LinkWithLocale>
      )}
    </animated.div>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById("portal")!
  );
};

export default ListComponentItemModal;
