import React from "react";
import type { IOfferCardProps } from "../SimpleOfferCard";
import cx from "classnames";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { ROUTES } from "@nf/constants";
import { isAnnualPeriod } from "../utils";
import { LinkWithLocale } from "components/LinkWithLocale";

const MediaButton = dynamic(() => import("components/MediaButton"));

const ChooseOfferButton = ({ offer }: IOfferCardProps) => {
  const { t } = useTranslation("translations");

  return (
    <div className={"CTAButtonContainer"}>
      <LinkWithLocale
        href={{
          pathname: ROUTES.PAYMENT_DETAILS,
          query: {
            offerId: offer.Id,
            offerHandle: offer.Handle,
          },
        }}
      >
        <a className="CTAButtonContainer">
          <MediaButton
            className={cx("ChooseOfferButton")}
            variant="primary"
            tabIndex={-1}
          >
            {t("web_choose_subscription")}
          </MediaButton>
        </a>
      </LinkWithLocale>
      <span className={"OfferDesc"}>
        {isAnnualPeriod(offer.Period)
          ? t("web_choose_subscription_annual_usp")
          : t("web_landing_page__features__price_desc")}
      </span>
    </div>
  );
};

export default ChooseOfferButton;
