/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { IUserSubprofileModel, IUserSubprofilesStateModel } from "@nf/common";
import { ROUTES } from "@nf/constants";
import { LinkWithLocale } from "components/LinkWithLocale";
import { useTranslation } from "next-i18next";
import cx from "classnames";
import AddCircleIcon from "../../../resources/icons/add-profile-circle.svg";
import EditIcon from "../../../resources/icons/edit-circle.svg";
import styles from "./ProfileCircle.module.scss";

export interface IProfileCircleProps {
  subprofile?: IUserSubprofileModel;
  editButton?: boolean;
  selectProfile?: (subprofile?: IUserSubprofileModel) => void;
  className?: string;
  ellipseText?: boolean;
  trimUsername?: boolean;
}

export interface IAddProfileProps {
  subprofilesState?: IUserSubprofilesStateModel;
}

export const ProfileCircle = ({
  subprofile,
  editButton = true,
  selectProfile,
  className,
  ellipseText,
  trimUsername,
}: IProfileCircleProps) => {
  const displayName = trimUsername
    ? subprofile?.DisplayName?.split(" ")[0]
    : subprofile?.DisplayName;

  return (
    <div className={cx(styles.Profile, className)}>
      <div
        onClick={() => selectProfile && selectProfile(subprofile)}
        className={styles.ProfileCircle}
        style={{ backgroundColor: subprofile?.Color }}
      >
        <span className={styles.ProfileLetter}>{subprofile?.FirstLetter}</span>
        {editButton && (
          <div className={styles.ProfileEditIcon}>
            <LinkWithLocale
              href={`${ROUTES.SETTINGS_EDIT_SUBRPOFILE}/${subprofile?.UserId}`}
            >
              <a>
                <EditIcon />
              </a>
            </LinkWithLocale>
          </div>
        )}
      </div>
      <div
        className={cx(styles.ProfileTitle, {
          [styles.ProfileTitleOverflow]: ellipseText,
        })}
      >
        {displayName}
      </div>
    </div>
  );
};

export const AddProfile = ({ subprofilesState }: IAddProfileProps) => {
  const { t } = useTranslation("translations");

  if (
    (subprofilesState?.currentSubprofile &&
      !subprofilesState.currentSubprofile.Owner) ||
    (subprofilesState?.subprofiles?.length ?? 0) > 4
  )
    return null;

  return (
    <div className={styles.Profile}>
      <div className={styles.ProfileAdd}>
        <LinkWithLocale href={ROUTES.SETTINGS_ADD_SUBRPOFILE}>
          <a>
            <AddCircleIcon />
          </a>
        </LinkWithLocale>
      </div>
      <div className={styles.ProfileTitle}>
        {t("web_subprofiles_button_add_profile")}
      </div>
    </div>
  );
};
