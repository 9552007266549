import { useEffect, useState } from "react";
import { isServerSideRender } from "@nf/helpers";

let imageWorker: Worker | null = null;

const canLoadByWorkerImage =
  !isServerSideRender &&
  typeof window !== "undefined" &&
  typeof window?.Worker === "function";

if (!isServerSideRender && canLoadByWorkerImage) {
  imageWorker = new Worker("/workers/image.worker.js");
}
type TWorkerImageData = { imageSrc: string; imageBlob: string };
type TWorkerResponse = { data: TWorkerImageData };
type TState = string | undefined;

const EVENT_WORKER_NAME = "message";

const isString = (value: unknown): value is string => {
  return typeof value === "string";
};

export const useImageWorker = (
  inView: boolean,
  src?: string | unknown
): string | undefined => {
  const [imageSrc, setState] = useState<TState>(undefined);

  useEffect(() => {
    const loadImageByWorker = (imageSrc?: string) => {
      if (imageWorker) {
        (imageWorker as Worker).postMessage(imageSrc);
        (imageWorker as Worker).addEventListener(
          EVENT_WORKER_NAME,
          ({ data }: TWorkerResponse) => onImageLoaded({ data }, imageSrc)
        );
      }
    };

    const onImageLoaded = (
      { data }: TWorkerResponse,
      imageSrc?: string | unknown
    ) => {
      if (typeof imageSrc === "string" && data.imageSrc === imageSrc) {
        setState(data.imageBlob);
      }
    };

    if (canLoadByWorkerImage) {
      isString(src) && inView && loadImageByWorker(src);
    } else {
      isString(src) && setState(src);
    }

    return () => {
      if (canLoadByWorkerImage) {
        (imageWorker as Worker).removeEventListener(
          EVENT_WORKER_NAME,
          ({ data }: TWorkerResponse) => onImageLoaded({ data }, src)
        );
      }
    };
  }, [src, canLoadByWorkerImage, imageWorker, setState, inView]);

  return imageSrc;
};
