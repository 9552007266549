/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import RcCheckbox, { Props } from "rc-checkbox";

import styles from "./Checkbox.module.scss";

export const Checkbox = (props: Props) => (
  <RcCheckbox className={styles.checkbox} {...props} />
);

export default Checkbox;
