/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import cx from "classnames";
import styles from "./SettingsSubscriptionButton.module.scss";
import dynamic from "next/dynamic";
import { IMediaButtonProps } from "../../MediaButton";

const MediaButton = dynamic(import("components/MediaButton"));

import React from "react";

export interface ISettingsSubscriptionButtonProps extends IMediaButtonProps {
  children: JSX.Element;
  wider?: boolean;
}

export const SettingsSubscriptionButton: React.FC<
  ISettingsSubscriptionButtonProps
> = ({ children, wider, className, ...restProps }) => {
  return (
    <MediaButton
      className={cx(styles.SettingsSubscriptionButton, className, {
        [styles.ButtonWider]: wider,
      })}
      {...restProps}
    >
      {children}
    </MediaButton>
  );
};

export default SettingsSubscriptionButton;
