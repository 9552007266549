import React from "react";
import { IHeroComponentModel } from "@nf/common";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dynamic from "next/dynamic";

const HeroBannerSingleItem = dynamic(
  () => import("./components/SingleItemHeroBanner")
);

const HeroBannerSlider = dynamic(() => import("./components/HeroBannerSlider"));

type THeroBanner = {
  component: IHeroComponentModel;
  index: number;
};

export const HeroBanner: React.FC<THeroBanner> = ({ component, index }) => {
  const { MediaList } = component;

  if (!MediaList) {
    return null;
  }

  const wrapWithSlider = MediaList.length > 1;
  const firstMediaItem = MediaList[0];

  if (MediaList.length === 0) {
    return null;
  }

  return wrapWithSlider ? (
    <HeroBannerSlider component={component} />
  ) : (
    <HeroBannerSingleItem element={firstMediaItem} index={index} />
  );
};
