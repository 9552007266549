/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  ImageHelper,
  RouteHelper,
  calcProgress,
  CellType,
  useCanWatchAuthContent,
} from "@nf/common";
import { TILE_OUT_OF_VIEW_WIDTH } from "@nf/constants";
import React, { useEffect, useMemo, useRef, useState } from "react";

import dynamic from "next/dynamic";

const ProgressBar = dynamic(() => import("components/ProgressBar"), {
  ssr: false,
});

const ImageWithPlaceholder = dynamic(
  () => import("components/ImageWithPlaceholder")
);

const AvailabilityLabel = dynamic(() => import("components/AvailabilityLabel"));

import { useTileEntry } from "../../../../hooks";
import { isServerSideRender } from "@nf/helpers";
import { IListComponentItemProps } from "../../models";
import { useRouter } from "next/router";
import cx from "classnames";
import styles from "./ListComponentItemFrame.module.scss";
import ListComponentItemModal from "../ListComponentItemModal/ListComponentItemModal";
import { useCountry, useLanguage } from "hooks";

export interface IListComponentItemFrameProps extends IListComponentItemProps {
  width: number;
}

export const ListComponentItemFrame = ({
  media,
  isPlaceholder = false,
  withProgress,
  gridColumns,
  cellType,
}: IListComponentItemFrameProps) => {
  const frameRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [isHover, setIsHover] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isOutOfView, setIsOutOfView] = useState(false);
  let mouseEnterTimeout: NodeJS.Timeout;
  const progressRef = useRef(false);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const isDesktopSize = !isServerSideRender && window.innerWidth > 850;
  const isLargeFrame = cellType === CellType.FrameLarge;
  const bannerImageUrl = ImageHelper.getBannerImageUrl(media?.Images);
  const { canWatch, isLoggedIn } = useCanWatchAuthContent(media);
  const country = useCountry();
  const language = useLanguage();

  const resize = useMemo(() => {
    if (gridColumns && gridColumns > 5) {
      return { width: 1.1, height: 1.333 };
    }

    if (gridColumns || isLargeFrame) {
      return { width: 0.62, height: 0.775 };
    }

    return { width: 0.785, height: 0.975 };
  }, []);

  const tileEntryParams = {
    isDesktopSize,
    isHover,
    gridColumns,
    frameRef,
    cellType,
    resize,
  };
  const tileEntry = useTileEntry(tileEntryParams);
  const mouseEnter = () => {
    if (!isDesktopSize) return;
    mouseEnterTimeout = setTimeout(() => {
      setIsFadingOut(false);
      setIsHover(true);
      const boundingRect = frameRef?.current?.getBoundingClientRect();
      const tileRight =
        boundingRect &&
        boundingRect.right >
          (window.innerWidth + TILE_OUT_OF_VIEW_WIDTH ||
            document.documentElement.clientWidth + TILE_OUT_OF_VIEW_WIDTH);
      const tileLeft = boundingRect && boundingRect.left < 0;
      if (!isLargeFrame && (tileRight || tileLeft)) {
        setIsOutOfView(true);
      } else {
        setIsOutOfView(false);
      }
    }, 200);
  };

  const mouseLeave = () => {
    if (!isDesktopSize) return;
    setIsFadingOut(true);
    setTimeout(() => {
      setIsHover(false);
      setIsFadingOut(false);
      setIsOutOfView(false);
    }, 350);
    clearTimeout(mouseEnterTimeout);
  };

  const goToMedia = () => {
    if (!isDesktopSize) {
      const detailsUrlObject = RouteHelper.goToDetails(media);
      router.push({
        pathname: `/${country}/${language}${detailsUrlObject.pathname}`,
      });
    }
  };

  const getProgress = () =>
    progressRef.current && setProgress(calcProgress(media));

  useEffect(() => {
    if (media?.Duration) {
      progressRef.current = true;
      getProgress();
    }
    return () => {
      progressRef.current = false;
    };
  }, [media]);

  const renderSkeleton = () => {
    return (
      <div
        className={cx(
          styles.containerSkeleton,
          isLargeFrame && styles.largeFrame
        )}
      >
        <div className={styles.skeleton} />
      </div>
    );
  };

  if (!media || isPlaceholder) {
    return renderSkeleton();
  }

  const isModalAvailable = isHover && !isOutOfView && isDesktopSize;
  const hrefUrlObject = RouteHelper.goToDetails(media);

  const newHref = `${process.env.NEXT_PUBLIC_MAIN_CANONICAL}/${country}/${language}${hrefUrlObject.pathname}`;

  return (
    <div
      className={cx(
        styles.item,
        isOutOfView && styles.isOutOfView,
        isLargeFrame && styles.largeFrame
      )}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={goToMedia}
    >
      <div className={styles.container} ref={frameRef}>
        <div className={styles.imageContainer}>
          <AvailabilityLabel media={media} />

          <ImageWithPlaceholder
            alt={media.Title}
            imageSrc={bannerImageUrl}
            imageContainerClassName={styles.image}
          />
          <a href={newHref}>{isServerSideRender && media.Title}</a>
          <div className={styles.content}>
            {withProgress && !!progress && <ProgressBar progress={progress} />}
          </div>
          {isServerSideRender && media.ShortDescription && (
            <p>{media.ShortDescription}</p>
          )}
        </div>
        {isServerSideRender ||
          (tileEntry &&
            tileEntry.dimension &&
            tileEntry.dimension.width &&
            tileEntry.dimension.height &&
            tileEntry.position &&
            isModalAvailable && (
              <ListComponentItemModal
                media={media}
                isLoggedIn={isLoggedIn}
                gridColumns={gridColumns}
                cellType={cellType}
                isFadingOut={isFadingOut}
                width={tileEntry.dimension.width}
                height={tileEntry.dimension.height}
                rectTop={tileEntry.position.top}
                rectLeft={tileEntry.position.left}
                rectRight={tileEntry.position.right}
              />
            ))}
      </div>
    </div>
  );
};
