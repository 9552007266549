import dynamic from "next/dynamic";

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

export interface LinkTextProps {
  href: string;
  bold?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const LinkText = ({
  href,
  bold,
  children,
  className,
}: LinkTextProps) => (
  <LinkWithLocale href={href}>
    {children && (
      <a className={className}>
        {bold ? <strong>{children}</strong> : <>{children}</>}
      </a>
    )}
  </LinkWithLocale>
);

export default LinkText;
