/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React, { useEffect, useState } from "react";
import { lastValueFrom, map } from "rxjs";
import { useTranslation } from "next-i18next";
import { Form, LabelField } from "components/Form";
import { DataProvider, UserStore, dispatch } from "@nf/common";
import {
  CANCELLATION_OTHER_SUBREASON_ARRAY,
  CANCELLATION_REASONS,
} from "@nf/constants";
import { Props } from "rc-checkbox";
import Textarea from "rc-textarea";
import { googleAnalyticsEvent } from "../../../../../../../lib/gtm";

import { SettingsSubscriptionButton } from "components/Settings/SettingsSubscriptionButton/SettingsSubscriptionButton";
import { Checkbox } from "components/Checkbox";

import styles from "./CancelSubscriptionForm.module.scss";

type CancellationReason = {
  Id: string;
  translationKey: string;
  subReasons: {
    Id: string;
    translationKey: string;
  }[];
};

export interface ICancelSubscriptionFormProps {
  id: number;
  setIsFormSent: (value: boolean) => void;
}

export const CancelSubscriptionForm = ({
  id,
  setIsFormSent,
}: ICancelSubscriptionFormProps) => {
  const { t } = useTranslation("translations");
  const [selectedReasons, setSelectedReason] = useState<string[]>([]);
  const [translations, setTranslations] = useState<{ [key: string]: string }>(
    {}
  );

  useEffect(() => {
    const loadTranslations = async () => {
      const observableData = DataProvider.getTranslation("en_GB").pipe(
        map((translationResopnse) => translationResopnse)
      );
      const data = await lastValueFrom(observableData);
      setTranslations(data);
    };

    loadTranslations();
  }, []);

  const onFinish = async (props: { [key: string]: Props }) => {
    const cancellationAnswers = Object.keys(props).filter(
      (key) => props[key]?.checked
    );

    const cancellationReasons: { [key: string]: string } = {};
    CANCELLATION_OTHER_SUBREASON_ARRAY.forEach((el) => {
      if (cancellationAnswers.includes(el) && props[`${el}_text`]) {
        cancellationReasons[el] = `${props[`${el}_text`]}`;
      }
    });

    const payload = {
      cancellation_answers_v2: cancellationAnswers,
      cancellation_reasons_v2: cancellationReasons,
    };

    dispatch(UserStore.Actions.cancelSubscription(id, payload));
    googleAnalyticsEvent.cancelSubscription(cancellationAnswers, translations);
    setIsFormSent(true);
  };

  const onValuesChange = (_: any, values: { [key: string]: Props }) => {
    const selectedKeys = Object.keys(values).filter(
      (key) => values[key]?.checked
    );
    setSelectedReason(selectedKeys);
  };

  const cancellationSubreasonMapper = (
    cancellationReason: CancellationReason
  ) => {
    return (
      <>
        {cancellationReason.subReasons.map((subReason) => (
          <label className={styles.Reason} key={subReason.Id}>
            <Form.Field name={subReason.Id}>
              {({ onChange }) => (
                <Checkbox
                  value={subReason.Id}
                  onChange={({ target }) => onChange(target)}
                />
              )}
            </Form.Field>
            <span className={styles.ReasonText}>
              {t(subReason.translationKey)}
            </span>
          </label>
        ))}
      </>
    );
  };

  const cancellationReasonMapper = () => {
    return (
      <>
        {CANCELLATION_REASONS.map((reason) => (
          <div key={reason.Id}>
            <label className={styles.Reason}>
              <Form.Field name={reason.Id}>
                {({ onChange }) => (
                  <Checkbox
                    value={reason.Id}
                    onChange={({ target }) => onChange(target)}
                  />
                )}
              </Form.Field>
              <span className={styles.ReasonText}>
                {t(reason.translationKey)}
              </span>
            </label>
            <div
              className={
                selectedReasons.includes(reason.Id)
                  ? styles.SubReason
                  : styles.SubReasonHidden
              }
            >
              {cancellationSubreasonMapper(reason)}
              <div
                className={
                  selectedReasons.includes(`${reason.Id}_other`)
                    ? styles.TextAreaContainer
                    : styles.TextAreaContainerHidden
                }
              >
                <LabelField name={`${reason.Id}_other_text`}>
                  <Textarea
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    className={styles.Textarea}
                    placeholder={t(
                      "web_subscription_cancel_reason_other_placeholder"
                    )}
                  />
                </LabelField>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <p className={styles.ContentTite}>{t("web_subscription_cancel_title")}</p>
      <p className={styles.ContentInfo}>{t("web_subscription_cancel_info")}</p>
      <Form onFinish={onFinish} onValuesChange={onValuesChange}>
        {cancellationReasonMapper()}
        <div className={styles.ButtonContainer}>
          <SettingsSubscriptionButton variant="primary" type="submit">
            {t("web_subscription_cancel_button_confirm")}
          </SettingsSubscriptionButton>
        </div>
      </Form>
    </>
  );
};
