/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ReactNode } from "react";
import styles from "./MediaDetailsButton.module.scss";

export interface IMediaDetailsButtonProps {
  className?: string;
  icon?: ReactNode;
  children?: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const MediaDetailsButton = ({
  icon,
  children,
  onClick,
  className,
}: IMediaDetailsButtonProps): JSX.Element => {
  return (
    <button
      onClick={onClick}
      className={`${styles.detailsButton} ${className ? className : ""}`}
    >
      {icon}
      {children}
    </button>
  );
};

export default MediaDetailsButton;
