import { Thing } from "schema-dts";

interface StructuredDataProps {
  schemas: Thing[];
}

const StructuredData = ({ schemas }: StructuredDataProps) => (
  <>
    {schemas.map((schema, index) => (
      <script
        key={index}
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
      />
    ))}
  </>
);

export default StructuredData;
