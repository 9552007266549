import { IMediaModel, RouteHelper, TimeHelper } from "@nf/common";
import type { Movie, WithContext } from "schema-dts";

interface MediaSchemaData {
  media: IMediaModel;
  actors?: string[];
  directors?: string[];
  image?: string;
  country: string;
  language: string;
}

const getMediaSchema = ({
  media,
  actors,
  directors,
  image,
  country,
  language,
}: MediaSchemaData): WithContext<Movie> => {
  const title = media?.Title;
  const description =
    media?.Description ||
    media?.ShortDescription ||
    media?.LongDescription ||
    media?.Title;

  const mainCanonicalUrl = RouteHelper.getMainCanonicalUrl();
  const trialPath = RouteHelper.goToTrial(media.Id);
  const trialUrl = `${mainCanonicalUrl}/${country}/${language}${trialPath.pathname}?${trialPath.query}`;

  return {
    "@context": "https://schema.org",
    "@type": "Movie",
    name: title,
    description: description,
    image,
    dateCreated: media?.Year ? `${media?.Year}` : "",
    director: directors?.map((director) => ({
      "@type": "Person",
      name: director ?? "",
    })),
    actor: actors?.map((actor) => ({
      "@type": "Person",
      name: actor ?? "",
    })),
    trailer: media.TrailerMediaId
      ? {
          "@type": "VideoObject",
          name: `Official Trailer ${title}`,
          description: description,
          dateCreated: media?.Year ? `${media?.Year}` : "",
          uploadDate: media?.AvailableFrom
            ? TimeHelper.toISOString(media.AvailableFrom)
            : undefined,
          thumbnailUrl: image ?? "",
          embedUrl: trialUrl,
        }
      : undefined,
  };
};

export default getMediaSchema;
