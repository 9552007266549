import React, { PropsWithChildren } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { ROUTES } from "@nf/constants";
import { SourceType, type CellType } from "@nf/common";

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

export interface LinkToPlaylistProps {
  sourceId?: string | number;
  sourceType?: SourceType;
  cellType?: CellType;
}

export const LinkToPlaylist = ({
  sourceId,
  sourceType,
  cellType,
  children,
}: PropsWithChildren<LinkToPlaylistProps>) => {
  const router = useRouter();

  const pathname = `${
    sourceType === SourceType.Favourites
      ? ROUTES.MYLIST_SCREEN
      : ROUTES.PLAYLIST_SCREEN
  }/${sourceId}/${cellType?.toLowerCase()}/${router.query.id}`;

  return (
    <LinkWithLocale
      href={{
        pathname,
      }}
    >
      {children}
    </LinkWithLocale>
  );
};
