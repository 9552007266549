/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useSubscriptionsSelector } from "@nf/common";
import { ROUTES } from "@nf/constants";
import { useCallback, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { ActionsHandler } from "components/ActionsHandler";
import { PaymentDetailType } from "components/PaymentDetailType";
import { useGeneratePath } from "hooks";
import PaymentForm from "screens/PaymentScreen/components/PaymentForm/PaymentForm";
import { NoSubscriptionsContainer } from "../NoSubscriptionContainer/NoSubscriptionsContainer";
import { ChangePaymentDetailsButton, PaymentDetailsText } from "./components";
import { useCreatePaymentDetails } from "./hooks/useCreatePaymentDetails";
import { useGetPaymentDetails } from "./hooks/useGetPaymentDetails";

import styles from "./SettingsPaymentDetails.module.scss";

export const SettingsPaymentDetails = () => {
  const generatePath = useGeneratePath(true, true);
  const { subscriptions } = useSubscriptionsSelector();
  const currentSubscription = subscriptions?.[0];
  const { t } = useTranslation("translations");

  const {
    data: currentPaymentDetails,
    error: currentPaymentDetailsError,
    isLoading: isFetchingCurrentPaymentDetails,
    mutate: getPaymentDetails,
  } = useGetPaymentDetails();

  const {
    data: newPaymentDetails,
    isLoading: isCreatingPaymentDetails,
    mutate: createPaymentDetails,
  } = useCreatePaymentDetails();

  const changePaymentDetails = useCallback(() => {
    createPaymentDetails({
      AcceptUrl: generatePath(ROUTES.PAYMENT_UPDATING),
      CancelUrl: generatePath(ROUTES.PAYMENT_CANCELLED),
    });
  }, []);

  useEffect(() => {
    getPaymentDetails();
  }, []);

  return (
    <div>
      <ActionsHandler
        error={
          currentPaymentDetailsError ? currentPaymentDetailsError : undefined
        }
        centeredError
        isLoading={isFetchingCurrentPaymentDetails}
      >
        {currentSubscription ? (
          <>
            <PaymentDetailType subscription={currentSubscription} />
            <div className={styles.paymentDetails}>
              {currentPaymentDetails && (
                <PaymentDetailsText
                  cardType={currentPaymentDetails.CardType}
                  maskedCard={currentPaymentDetails.MaskedCard}
                />
              )}

              {/* FIXME Temporarily display this text until changing payment method is reenabled */}
              <p style={{ marginTop: "5rem" }}>
                {t("web_payment_details_edit_payment_error")}
              </p>
            </div>
          </>
        ) : (
          <NoSubscriptionsContainer />
        )}
      </ActionsHandler>

      {/* FIXME Temporarily disable changing payment method until all users are migrated to Reepay */}
      {/* {currentSubscription && !newPaymentDetails && (
        <ChangePaymentDetailsButton
          isLoading={isCreatingPaymentDetails}
          onClick={changePaymentDetails}
        />
      )} */}

      {newPaymentDetails?.CheckoutUrl && (
        <PaymentForm url={newPaymentDetails.CheckoutUrl} />
      )}
    </div>
  );
};

export default SettingsPaymentDetails;
