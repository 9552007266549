/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { IListComponentHorizontalProps, ListComponentHorizontal } from "..";
import styles from "./ListComponentPromo.module.scss";

export type IListComponentPromoProps = IListComponentHorizontalProps;

export const ListComponentPromo: React.FC<IListComponentPromoProps> =
  React.memo((props) => {
    return (
      <ListComponentHorizontal
        showHeader={false}
        className={styles.list}
        {...props}
      />
    );
  });

export default ListComponentPromo;
