import styles from "../../WelcomeScreen.module.scss";
import React from "react";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { LinkWithLocale } from "../../../../components";
import { RouteHelper } from "@nf/common";
import { EXTERNAL_LINKS } from "@nf/constants";
import RightArrowIcon from "../../../../resources/icons/right-arrow.svg";

const AndroidBadge = dynamic(
  () => import("screens/WelcomeScreen/components/AndroidBadge")
);

const LogoutButton = dynamic(() => import("components/LogoutButton"));

const IOSBadge = dynamic(
  () => import("screens/WelcomeScreen/components/IosBadge/IosBadge")
);

const Web_mobile_link_to_tv = dynamic(
  () => import("components/TranslationsContainers/Web_mobile_link_to_tv")
);

export const LoginSplash = () => {
  const { t } = useTranslation("translations");
  return (
    <div>
      <div className={styles.listContainer}>
        <ul className={styles.list}>
          <li>
            <LinkWithLocale href={RouteHelper.goToSettings()}>
              {t("web_my_account_header", "")}
            </LinkWithLocale>
            <RightArrowIcon />
            <span>{t("web_mobile_my_account_description", "")}</span>
          </li>
          <li>
            <a href={EXTERNAL_LINKS.HELPCENTER}>{t("web_footer_help", "")}</a>
            <RightArrowIcon />
            <span>{t("web_mobile_help_description", "")}</span>
          </li>
          <li>
            <Web_mobile_link_to_tv />
            <RightArrowIcon />
            <span>{t("web_mobile_login_description", "")}</span>
          </li>
        </ul>

        <div>{t("web_receipt_mobile_download_app", " ")}</div>
        <div className={styles.badgeContainer}>
          <IOSBadge className={styles.IOSdBadge} />
          <AndroidBadge className={styles.androidBadge} />
        </div>
        <div>
          <LogoutButton variant={"no-border"} />
        </div>
      </div>
    </div>
  );
};
