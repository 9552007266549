import React from "react";
import dynamic from "next/dynamic";
import { getExpirationDate } from "../ExpirationDate/ExpirationDate";
import { useDataPayment } from "../../../../../screens/PaymentScreen/hooks/useDataPayment";

const WebSubscriptionChangeConfirmDescription = dynamic(
  () =>
    import(
      "components/TranslationsContainers/Web_subscription_change_confirm_description"
    )
);

type Props = {
  ExpirationDate: number;
  SwitchToOfferId?: number;
  SwitchToOfferPeriod?: string;
};

export const SwitchSubscriptionInfo: React.FC<Props> = ({
  SwitchToOfferPeriod,
  SwitchToOfferId,
  ExpirationDate,
}) => {
  const { offers } = useDataPayment();

  const nextPaymentPrice = offers?.find(
    (offer) => offer.Id == SwitchToOfferId?.toString()
  )?.Price;

  const expirationDate = getExpirationDate(ExpirationDate);

  return (
    <WebSubscriptionChangeConfirmDescription
      date={expirationDate}
      price={nextPaymentPrice}
      period={SwitchToOfferPeriod}
    />
  );
};
