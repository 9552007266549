import React from "react";
import styles from "./SectionContaine.module.scss";
import dynamic from "next/dynamic";
import cx from "classnames";

const SectionHeader = dynamic(() => import("../SectionHeader/SectionHeader"));

export type ISectionContainerProps = {
  children: React.ReactNode;
  title?: string;
  className?: string;
  autoHeight?: boolean;
  includePadding?: boolean;
};

export const SectionContainer: React.FC<ISectionContainerProps> = ({
  children,
  title,
  className,
  autoHeight,
  includePadding,
}) => {
  return (
    <div
      className={cx(styles.container, className, {
        [styles.container]: autoHeight,
        [styles.withPadding]: includePadding,
        [styles.fullHeight]: !autoHeight,
      })}
    >
      {title && <SectionHeader title={title} />}
      {children}
    </div>
  );
};

export default SectionContainer;
