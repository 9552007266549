/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { cloneElement } from "react";
import { IListComponentItemProps } from "../../models";

interface IListComponentItemCustomProps extends IListComponentItemProps {
  children: JSX.Element;
}

export const ListComponentItemCustom = (
  propsToPass: IListComponentItemCustomProps
) => {
  const { children, ...props } = propsToPass;

  return <>{cloneElement(children, props)}</>;
};
