import { IMediaModel, channelTagsFromMedia } from "@nf/common";
import { CHANNELS, DEFAULT_COUNTRY_CODE } from "@nf/constants";
import { compareStrings } from "@nf/helpers";
import { useTranslation } from "next-i18next";

const getMediaChannelInfo = (media: IMediaModel) => {
  const channel = channelTagsFromMedia(media);

  const isKids = channel === CHANNELS.KIDS;
  const isDanskFilmskat = channel === CHANNELS.DANSK_FILMSKAT;
  const isDanish = !!media?.Countries?.find((country) =>
    compareStrings(country.Code, DEFAULT_COUNTRY_CODE)
  );

  return {
    isDanish,
    isDanskFilmskat,
    isKids,
  };
};

export const useMediaMetaTitle = (media: IMediaModel) => {
  const { t } = useTranslation("translations");
  const { isDanish, isDanskFilmskat, isKids } = getMediaChannelInfo(media);

  if (isKids) {
    return `Stream ${media.Title} | Sjovt og trygt børneunivers | Nordisk Film+`;
  }

  if (isDanskFilmskat) {
    return `Se ${media.Title} | Dansk Filmskat | Nordisk Film+`;
  }

  if (isDanish) {
    return `Se ${media.Title} | Danske film | Nordisk Film+`;
  }

  return t("web_meta_fallback_title", { title: media.Title });
};

export const useMediaMetaDescription = (media: IMediaModel) => {
  const { isDanish, isDanskFilmskat, isKids } = getMediaChannelInfo(media);
  const price = 59;
  if (isKids) {
    return `På Nordisk Film+ kan du se og streame ${media.Title}. Familiens yngste kan boltre sig et sjovt og trygt børneunivers med velkendte favoritter. Kun ${price} kr. per måned.`;
  }

  if (isDanskFilmskat) {
    return `På Nordisk Film+ kan du se og streame ${media.Title}. Du får det største udvalg af danske film og håndplukkede film fra Hollywood. Kun ${price} kr. per måned.`;
  }

  if (isDanish) {
    return `På Nordisk Film+ kan du se og streame ${media.Title}. Se nye danske spillefilm og gense gode gamle klassikere fra Dansk Filmskat. Kun ${price} kr. per måned.`;
  }

  return `På Nordisk Film+ kan du se og streame ${media.Title}. Du får det største udvalg af danske film og håndplukkede film fra Hollywood. Kun ${price} kr. per måned.`;
};
