/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

export enum SortTypes {
  ALPHABETICAL = "Alphabetical",
  YEAR_NEWEST = "Year newest",
  YEAR_OLDEST = "Year oldest",
  POPULAR = "Popular",
  RECENTLY_ADDED = "Recently added",
}
