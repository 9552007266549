import { IMediaModel } from "@nf/common";
import { useState } from "react";
import {
  IListComponentHorizontalProps,
  ListComponentHorizontal,
} from "./ListComponentHorizontal";

export const ListComponentHorizontalInteractive = ({
  onItemClick,
  onItemMouseEnter,
  onItemMouseLeave,
  ...props
}: IListComponentHorizontalProps) => {
  const [activeIndex, setActiveIndex] = useState<number>();
  const [hoverIndex, setHoverIndex] = useState<number>();

  const changeActiveIndex = (index?: number, data?: IMediaModel) => {
    let newIndex;
    let newData;
    if (activeIndex === index) {
      newIndex = undefined;
      newData = undefined;
    } else {
      newIndex = index;
      newData = data;
    }
    setActiveIndex(newIndex);
    onItemClick?.(newIndex, newData);
  };

  const changeHoverIndex = (index?: number, data?: IMediaModel) => {
    setHoverIndex(index);
    onItemMouseEnter?.(index, data);
  };

  const unsetHoverIndex = () => {
    setHoverIndex(undefined);
    onItemMouseLeave?.(undefined);
  };

  return (
    <ListComponentHorizontal
      {...props}
      activeIndex={activeIndex}
      hoverIndex={hoverIndex}
      onItemClick={changeActiveIndex}
      onItemMouseEnter={changeHoverIndex}
      onItemMouseLeave={unsetHoverIndex}
    />
  );
};
