/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { IListComponentHorizontalProps, ListComponentHorizontal } from "..";
import styles from "./ListComponentHighlight.module.scss";
import { LazyLoadTypes } from "react-slick";

export type IListComponentHighlightProps = IListComponentHorizontalProps;

const settings = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 10000,
  pauseOnDotsHover: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  swipeToSlide: true,
  fade: true,
  speed: 700,
  waitForAnimate: false,
  lazyLoad: "ondemand" as LazyLoadTypes,
};

export const ListComponentHighlight = React.memo(
  (props: IListComponentHighlightProps) => {
    return (
      <ListComponentHorizontal
        settings={settings}
        showHeader={false}
        className={styles.list}
        {...props}
      />
    );
  }
);

export default ListComponentHighlight;
