/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import axios from "axios";

import {
  AssetType,
  IMediaCategoryModel,
  IMediaModel,
  slugify,
  UrlHelper,
} from "@nf/common";
import {
  AppConfig,
  DEFAULT_COUNTRY_CODE_LOWERCASE,
  DEFAULT_LANGUAGE_CODE,
  ROUTES,
} from "@nf/constants";
import { ISitemapField } from "next-sitemap";
import { IAssetModel } from "@nf/common/src/providers/DataProvider/RedBeeMedia/models";
import { ModelsMapperHelper } from "@nf/common/src/providers/DataProvider/RedBeeMedia/helpers";

const MAX_PAGE_SIZE = 200;
const LIST_SIZE = 800;

const defaultOptions = {
  defaultCountry: DEFAULT_COUNTRY_CODE_LOWERCASE,
  defaultLocale: DEFAULT_LANGUAGE_CODE,
  categoriesCache: {
    categories: new Map<string, IMediaCategoryModel>(),
    refresh: false,
  },
};

interface IMedia extends IMediaModel {
  changed: string;
}

export class SitemapHelper {
  static getApiUrl() {
    return `${AppConfig.ApiUrl}/v1/customer/${AppConfig.Customer}/businessunit/${AppConfig.BusinessUnit}`;
  }

  static getSiteUrl(country?: string, language?: string) {
    const preparedLanguage = language?.slice(0, 2);
    return country && language
      ? `${
          process.env.NEXT_PUBLIC_MAIN_CANONICAL
        }/${country?.toLowerCase()}/${preparedLanguage}`
      : process.env.NEXT_PUBLIC_MAIN_CANONICAL;
  }

  static mapMediaToSitemapField(
    assetType: AssetType,
    mediaList: IMedia[],
    country?: string,
    language?: string
  ): ISitemapField[] {
    const siteUrl = this.getSiteUrl(country, language);

    return mediaList.map((media) => {
      const title = slugify(media.Title);
      const id = media.Id;
      let url = "";

      switch (assetType) {
        case AssetType.Collection:
          url = `${siteUrl}${ROUTES.COLLECTION_DETAILS_SCREEN}/${title}/${id}`;
          break;
        case AssetType.Series:
          url = `${siteUrl}${ROUTES.SERIES_DETAILS_SCREEN}/${title}/${id}`;
          break;
        default:
          url = `${siteUrl}${ROUTES.MOVIE_DETAILS_SCREEN}/${title}/${id}`;
          break;
      }

      return {
        loc: url,
        lastmod: media.changed,
        priority: 1,
        changefreq: "weekly" as ISitemapField["changefreq"],
      };
    });
  }

  static async getAssets(
    assetType: AssetType,
    country: string,
    language: string
  ): Promise<IMedia[]> {
    const options = {
      ...defaultOptions,
      lang: language,
      country,
    };

    const mapToMedia = (assetList: IAssetModel[]) => {
      return assetList.map((asset: IAssetModel) => {
        return {
          ...ModelsMapperHelper.toMediaModel(asset, options),
          changed: asset.changed,
        };
      });
    };

    const promises = [];

    for (let page = 1; page < LIST_SIZE / MAX_PAGE_SIZE; page++) {
      promises.push(
        axios(
          UrlHelper.parametrize(`${this.getApiUrl()}/content/asset`, {
            assetType,
            includeFields: "assetId,type,changed",
            onlyPublished: true,
            sort: "popularity.month",
            allowedCountry: options.country,
            pageSize: MAX_PAGE_SIZE,
            pageNumber: page,
          })
        )
      );
    }

    const results = await Promise.all(promises);
    const mediaList: IMedia[] = results.flatMap((result) =>
      mapToMedia(result.data.items)
    );

    return mediaList;
  }

  static async getSitemapFields(
    assetType: AssetType.Series | AssetType.Movie | AssetType.Collection,
    country: string,
    language: string
  ): Promise<ISitemapField[]> {
    const mediaList = await this.getAssets(assetType, country, language);
    return this.mapMediaToSitemapField(assetType, mediaList, country, language);
  }
}
