import React from "react";
import { useTranslation } from "react-i18next";
import { RouteHelper } from "@nf/common";
import styles from "./LandingPageMarketingInfo.module.scss";
import FilmFrameIcon from "../../../../resources/landingPage/play.svg";
import LowPriceIcon from "../../../../resources/landingPage/noun-deposit.svg";
import NoAdsIcon from "../../../../resources/landingPage/no_ads.svg";
import dynamic from "next/dynamic";
import { ITextComponentModel } from "@nf/common/dist/models/Configuration/ITextComponentModel";
import { LinkWithLocale, MediaButton } from "components";

const SectionContainer = dynamic(
  () => import("../SectionContainer/SectionContainer")
);

const Icon = ({ index }: { index: number | string }) => {
  switch (index) {
    case 0:
      return <FilmFrameIcon />;
    case 1:
      return <LowPriceIcon />;
    case 2:
      return <NoAdsIcon />;
    default:
      return null;
  }
};

type TLandingPageMarketingInfo = {
  component: ITextComponentModel;
};

type TData = {
  subTitle: string;
  columns: { title: string }[];
};

export const LandingPageMarketingInfo: React.FC<TLandingPageMarketingInfo> = ({
  component,
}) => {
  const { t } = useTranslation("translations");

  const data = component.TextParsed
    ? (component.TextParsed as unknown as TData)
    : { columns: [], subTitle: "" };

  return (
    <SectionContainer
      title={component.Title}
      className={styles.container}
      includePadding
      autoHeight
    >
      <div className={styles.subTitle}>{data?.subTitle}</div>
      <div className={styles.list}>
        <div className={styles.row}>
          {data?.columns &&
            data?.columns?.map((element, index) => (
              <div
                key={`${index}-${element.title}`}
                className={styles.elementContainer}
              >
                <div className={styles.icon}>
                  <Icon index={index} />
                </div>
                <div className={styles.title}>{element.title}</div>
              </div>
            ))}
        </div>
      </div>
      <LinkWithLocale href={RouteHelper.goToHome()}>
        <MediaButton variant="plain" className={styles.browseButton}>
          {t("web_start_browse_button", "")}
        </MediaButton>
      </LinkWithLocale>
    </SectionContainer>
  );
};
