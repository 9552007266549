import { SettingsSubscriptionContainer } from "../SettingsSubscriptionContainer";
import { useTranslation } from "next-i18next";
import { IUserSubscriptionModel } from "@nf/common";
import { useFormContext } from "../PaymentFormProvider/PaymentFormProvider";

type TCurrentSubscriptionContainer = {
  hasActiveSub: boolean;
  currentSubscriptions: IUserSubscriptionModel[];
  setIsPauseFormSent: (value: boolean) => void;
};

export const CurrentSubscriptionContainer = ({
  currentSubscriptions,
  hasActiveSub,
  setIsPauseFormSent,
}: TCurrentSubscriptionContainer) => {
  const { t } = useTranslation("translations");
  const { isFormSent, setIsFormSent, isDataLoading } = useFormContext();
  return (
    <>
      <h2>{t("web_subscription_current")}</h2>
      {currentSubscriptions.map((sub) => (
        <SettingsSubscriptionContainer
          key={sub.Id}
          period={sub.Period}
          subscription={sub}
          isActive={sub.IsActive}
          isLoading={isDataLoading}
          isFormSent={isFormSent}
          setIsFormSent={setIsFormSent}
          setIsPauseFormSent={setIsPauseFormSent}
          hasActiveSub={hasActiveSub}
          subscriptionStatus={sub.SubscriptionStatus}
        />
      ))}
    </>
  );
};
