import React from "react";
import styles from "./LandingPageFAQ.module.scss";
import RightArrowIcon from "../../../../resources/icons/right-arrow.svg";
import dynamic from "next/dynamic";
import { ITextComponentModel } from "@nf/common/dist/models/Configuration/ITextComponentModel";

const SectionContainer = dynamic(() => import("../SectionContainer"));

const getLink = (link: string) => {
  const url = link.match(/\(([^)]+)\)/);
  return url ? url[1] : "";
};

type TLandingPageFAQ = {
  component: ITextComponentModel;
};

type TFaqList = {
  title: string;
  description: string;
  link: string;
};

export const LandingPageFAQ: React.FC<TLandingPageFAQ> = ({ component }) => {
  const faqList = component.TextParsed
    ? (component.TextParsed as unknown as TFaqList[])
    : [];
  return (
    <SectionContainer
      title={component.Title}
      className={styles.container}
      includePadding
    >
      <div className={styles.list}>
        {faqList.map((element, index) => (
          <div key={`${index}-${element.title}`} className={styles.listRow}>
            <a href={getLink(element.link)}>
              <div className={styles.detailContainer}>
                <div className={styles.title}>
                  <span>{element.title}</span>
                </div>
                <div className={styles.description}>
                  <div className={styles.descriptionDetails}>
                    <span>{element.description}</span>
                  </div>
                  <RightArrowIcon className={styles.rightArrow} />
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};
