/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import {
  DeviceHelper,
  IMediaModel,
  MediaType,
  RouteHelper,
  TimeHelper,
} from "@nf/common";

const MyListButton = dynamic(() => import("components/MyListButton"));

const MediaDetailsAdditionalInfo = dynamic(
  () => import("components/Media/MediaDetailsAdditionalInfo")
);

const MediaDetailsCoverImg = dynamic(
  () => import("components/Media/MediaDetailsCoverImg")
);

import LeaveArrow from "resources/icons/back.svg";
import LeaveArrowMobile from "resources/icons/leave-cross.svg";
import Play from "resources/icons/see-trailer.svg";
import cx from "classnames";
import styles from "./MediaDetailsTopContent.module.scss";
import { MediaDetailsProgressContainer } from "./MediaDetailsProgressContainer";
import dynamic from "next/dynamic";
import { MediaDetailsButton } from "../MediaDetailsButton";
import { useGoBackOrHome, useRoutePathBuild } from "../../../hooks";

interface IMediaDetailsTopContentProps {
  media: IMediaModel;
  isLoggedIn?: boolean;
  className?: string;
}

export const MediaDetailsTopContent = ({
  media,
  isLoggedIn,
  className,
}: IMediaDetailsTopContentProps): JSX.Element => {
  const { t } = useTranslation("translations");
  const router = useRouter();
  const isSeriesType = media.MediaTypeCode === MediaType.Series;
  const isMobile = DeviceHelper.isMobile();
  const routePrefix = useRoutePathBuild();
  const goBackOrHome = useGoBackOrHome();
  const isAvailableToBeforeCurrent = TimeHelper.isBeforeCurrent(
    media.AvailableTo
  );

  const goToTrial = () => {
    const { pathname } = RouteHelper.goToTrial(media.Id);
    router.push({
      pathname: `${routePrefix}${pathname}`,
      query: {
        TRIAL: "",
      },
    });
  };

  const shouldRenderTrailerButton = !isSeriesType && media.TrailerMediaId;

  return (
    <>
      <MediaDetailsButton // MOBILE DESIGN
        className={cx(styles.leftIcon)}
        icon={<LeaveArrowMobile />}
        onClick={goBackOrHome}
      />
      <div className={cx(styles.mediaDetailsTopContent, className)}>
        <MediaDetailsButton // DESKTOP DESIGN
          className={cx(styles.leftIcon)}
          icon={<LeaveArrow />}
          onClick={goBackOrHome}
        />

        <div className={styles.container}>
          <MediaDetailsCoverImg
            className={styles.coverImg}
            images={media?.Images}
            altText={media?.Title}
          />
          <MediaDetailsAdditionalInfo media={media} />
          {!isMobile && (
            <div className={styles.buttonsBox}>
              {isLoggedIn && !isAvailableToBeforeCurrent && (
                <MyListButton media={media} showLabel={true} />
              )}
              {shouldRenderTrailerButton && (
                <MediaDetailsButton
                  onClick={goToTrial}
                  icon={<Play />}
                  className={styles.trailerButton}
                >
                  {t("web_asset_page_watch_trailer", "")}
                </MediaDetailsButton>
              )}
              <MediaDetailsProgressContainer media={media} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
