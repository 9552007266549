/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useState, useEffect } from "react";
import {
  IFormValues,
  UserStore,
  useUserSubprofilesSelector,
  IUserSubprofileModel,
  userProfileHelper,
} from "@nf/common";
import { ROUTES } from "@nf/constants";

import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Input, Form } from "components";
import dynamic from "next/dynamic";
import { useProfiles } from "../../../hooks";
import { useConfigurationSelector } from "@nf/common";

const ActionsHandler = dynamic(() => import("components/ActionsHandler"));

const ColorPicker = dynamic(() => import("components/Subprofiles/ColorPicker"));

const AgeSlider = dynamic(
  () => import("components/Subprofiles/SubprofileForm/components/AgeSlider")
);

const RadioButton = dynamic(() => import("components/RadioButton"));

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

const Checkbox = dynamic(() => import("components/Checkbox"));

const LabelField = dynamic(() => import("components/Form/LabelField"));

const FormButton = dynamic(() => import("components/Form/FormButton"));

const MediaButton = dynamic(() => import("components/MediaButton"));

import Remove from "../../../resources/icons/trash.svg";
import i18next from "i18next";
import styles from "./SubprofileForm.module.scss";

interface ISubprofileFormProps {
  editForm?: boolean;
}

export const SubprofileForm = ({ editForm }: ISubprofileFormProps) => {
  const { t } = useTranslation("translations");
  const router = useRouter();
  const dispatch = useDispatch();
  const subprofileId = router.query.id as string | number;
  const { handleProfile } = useProfiles();
  const { Data, IsProcessing } = useUserSubprofilesSelector();
  const parentalRatings = Data?.parentalRatings;
  const config = useConfigurationSelector();
  const singleSubprofile = Data?.subprofiles?.find((sub) => {
    return subprofileId === sub.UserId;
  });
  const [selectedValue, setSelectedValue] = useState(
    userProfileHelper.getInitialHomePage(singleSubprofile)
  );

  const currentProfile =
    singleSubprofile?.UserId === Data?.currentSubprofile?.UserId;
  const [kidsProfile, setKidsProfile] = useState(
    singleSubprofile?.Child || false
  );

  const [selectedAgeRange, setSelectedAgeRange] = useState(
    parentalRatings?.[0].ratings
  );
  const prevAgeRangeIndex = parentalRatings?.findIndex(
    (range) => range.ratings === singleSubprofile?.ProfileType
  );

  useEffect(() => {
    !parentalRatings?.length &&
      config?.DefaultCountry &&
      dispatch(
        UserStore.Actions.getUserParentalRatings(
          config.DefaultCountry?.toUpperCase()
        )
      );
  }, [dispatch, parentalRatings?.length, config?.DefaultCountry]);

  const renderSpecificPageFields = () => {
    return config?.Screens?.APPLICATION_MENU?.Components?.map((item, key) => {
      return (
        <Form.Field name={item?.Id} key={`${item?.Id}-${key}`}>
          <div className={styles.checkboxContainer}>
            <RadioButton
              name={item?.Title}
              value={`${userProfileHelper.cleanUp(item?.Id?.toString())}`}
              selectedValue={selectedValue}
              onChange={handleCheckboxChange}
            />
          </div>
        </Form.Field>
      );
    });
  };

  const handleCheckboxChange = (value: string) => {
    if (selectedValue === value) {
      setSelectedValue("");
    } else {
      setSelectedValue(userProfileHelper.cleanUp(value) as string);
    }
  };

  const [form] = Form.useForm();
  const initialValues: IFormValues = {
    profileName: singleSubprofile?.DisplayName,
    kidsProfile: singleSubprofile?.Child,
    color: singleSubprofile?.Color,
  };

  const profileDelete = () => {
    dispatch(UserStore.Actions.deleteUserSubprofile(subprofileId));
    router.back();
  };

  const handleTypeChange = () => setKidsProfile((kidsProfile) => !kidsProfile);

  const onFinish = (values: IFormValues) => {
    const subprofilePayload: IUserSubprofileModel = {
      UserId: String(subprofileId),
      DisplayName: `${values["profileName"]}`,
      Child: kidsProfile,
      ProfileType: kidsProfile ? selectedAgeRange : "",
      Language: `${i18next.language}`,
      Color: values["color"],
      InitialHomePage: userProfileHelper.cleanUp(selectedValue),
      CurrentProfile: currentProfile,
    };

    if (editForm) {
      dispatch(
        UserStore.Actions.updateUserSubprofile(subprofilePayload, subprofileId)
      );

      handleProfile({
        UserId: String(subprofileId),
        DisplayName: `${values["profileName"]}`,
        InitialHomePage: userProfileHelper.cleanUp(selectedValue),
        CurrentProfile: currentProfile,
      });
    } else {
      delete subprofilePayload.UserId;
      dispatch(UserStore.Actions.createUserSubprofiles(subprofilePayload));
    }
    router.back();
  };

  return (
    <>
      <div className={styles.header}>
        <h2>
          {editForm
            ? t("web_subprofiles_edit_profile")
            : t("web_subprofiles_add_profile")}
        </h2>
      </div>

      <Form
        className={styles.subprofileForm}
        initialValues={initialValues}
        form={form}
        onFinish={onFinish}
      >
        <ColorPicker initialColor={singleSubprofile?.Color} />
        <LabelField label={t("web_subprofiles_name")} name="profileName">
          <Input
            placeholder={t("web_subprofiles_placeholder")}
            className={styles.input}
          />
        </LabelField>

        {!singleSubprofile?.Owner && (
          <Form.Field name="kidsProfile">
            <div className={styles.checkboxContainer}>
              <Checkbox checked={kidsProfile} onChange={handleTypeChange} />
              <span>{t("web_subprofiles_kids_profile")}</span>
            </div>
          </Form.Field>
        )}

        {!kidsProfile && (
          <div className={styles.initialPageContainer}>
            <div>
              <p className={styles.initialPageContainer__title}>
                {t("web_profile_startpage_header")}
              </p>
              <span className={styles.initialPageContainer__description}>
                {t("web_profile_startpage_text")}
              </span>
            </div>

            {renderSpecificPageFields()}
          </div>
        )}

        {kidsProfile && !singleSubprofile?.Owner && (
          <>
            <LabelField>
              <span className={styles.sliderHeader}>
                {t("web_subprofiles_age_restriction")}
              </span>
            </LabelField>
            <ActionsHandler
              isLoading={!parentalRatings?.length && IsProcessing}
            >
              {!!parentalRatings?.length ? (
                <Form.Field name="ageRestricion">
                  <AgeSlider
                    ageRanges={parentalRatings}
                    setAgeRange={setSelectedAgeRange}
                    prevAgeRangeIndex={prevAgeRangeIndex}
                  />
                </Form.Field>
              ) : undefined}
            </ActionsHandler>
          </>
        )}
        <div className={styles.buttonContainer}>
          {editForm &&
          !singleSubprofile?.Owner &&
          singleSubprofile?.UserId &&
          !currentProfile ? (
            <LinkWithLocale href={ROUTES.SETTINGS_SUBPROFILE_SCREEN}>
              <a>
                <MediaButton
                  onClick={() => profileDelete()}
                  className={styles.button}
                  tabIndex={-1}
                  variant="secondary"
                  icon={<Remove />}
                >
                  {t("web_subprofiles_button_delete_profile")}
                </MediaButton>
              </a>
            </LinkWithLocale>
          ) : (
            <LinkWithLocale href={ROUTES.SETTINGS_SUBPROFILE_SCREEN}>
              <a>
                <MediaButton
                  className={styles.button}
                  tabIndex={-1}
                  variant="secondary"
                >
                  {t("web_cancel")}
                </MediaButton>
              </a>
            </LinkWithLocale>
          )}
          <FormButton
            nonEmptyFields={{
              requiredFields: ["profileName"],
              autoCompleted: false,
            }}
            className={styles.button}
          >
            {editForm
              ? t("web_subprofiles_button_save_changes")
              : t("web_subprofiles_button_save")}
          </FormButton>
        </div>
      </Form>
    </>
  );
};

export default SubprofileForm;
