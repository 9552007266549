/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  IAppState,
  IListComponentModel,
  IMediaListModel,
  IMediaModel,
  MediaStore,
  SourceType,
  ThemeContext,
  CellType,
  DeviceHelper,
  AssetType,
} from "@nf/common";
import cx from "classnames";
import { useTranslation } from "next-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { IListComponentProps } from "../../types";
import dynamic from "next/dynamic";

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { InView } from "react-intersection-observer";
import { isServerSideRender } from "@nf/helpers";
import { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./ListComponentHorizontal.module.scss";
import { useListItemCount } from "../../hooks";
import { ListComponentItem } from "../ListComponentItem";
import { LinkToPlaylist } from "./components/LinkToPlaylist";

const SeeAllButton = dynamic(() => import("./components/SeeAllButton"));

const ListComponentArrow = dynamic(() => import("../ListComponentArrow"));

const ListComponentHorizontalSSR = dynamic(
  () => import("../ListComponentItemSSR/ListComponentHorizontalSSR")
);

const Slider = dynamic(() => import("react-slick"));

const useSourceSelector = (
  component: IListComponentModel
): IMediaListModel | undefined => {
  const mediaList =
    useSelector((state: IAppState) => state.media.mediaList) || {};
  if (component.SourceId) {
    return mediaList[component.SourceId];
  }

  if (component.MediaList) {
    return {
      SourceType: SourceType.MediaList,
      Entities: component.MediaList,
      TotalCount: component.MediaList.length,
    };
  }
};

const useGetMediaList = () => {
  const dispatch = useDispatch();
  return useCallback(
    (
      mediaListId: string | number,
      sourceUrl?: string,
      sourceType?: SourceType
    ) =>
      dispatch(
        MediaStore.Actions.getMediaList({
          MediaListId: mediaListId,
          SourceUrl: sourceUrl,
          SourceType: sourceType,
          IncludeCategories: true,
          IncludeImages: true,
          IncludeMedia: false,
          PageNumber: 1,
          PageSize: 12,
        })
      ),
    [dispatch]
  );
};

export interface IListComponentHorizontalProps extends IListComponentProps {
  className?: string;
  placeholder?: React.ReactElement;
  showHeader?: boolean;
  settings?: Settings;
  showSeeAllButton?: boolean;
  showSubtitle?: boolean;

  activeIndex?: number;
  hoverIndex?: number;
  onItemClick?: (index?: number, item?: IMediaModel) => void;
  onItemMouseEnter?: (index?: number, item?: IMediaModel) => void;
  onItemMouseLeave?: (index?: number, item?: IMediaModel) => void;
}

export const ListComponentHorizontal = ({
  className,
  component,
  loading: isLoading,
  placeholder,
  ready = true,
  readOnly,
  showHeader = true,
  settings,
  showSeeAllButton,
  showSubtitle = false,
  activeIndex,
  hoverIndex,
  onItemClick,
  onItemMouseEnter,
  onItemMouseLeave,
}: IListComponentHorizontalProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const { t } = useTranslation("translations");
  const source = useSourceSelector(component);
  const itemCount = useListItemCount(component);
  const getMediaList = useGetMediaList();
  const isDesktopSize = !isServerSideRender && window.innerWidth > 850;
  const [isListFocused, setIsListFocused] = useState(false);
  const [sliderTouched, setSliderTouched] = useState(false);
  const [showLefArrow, setShowLefArrow] = useState(false);
  const [sliderTouchedCount, setSliderTouchedCount] = useState(0);
  const IsHighlightComponent = component.CellType === CellType.Highlights;
  const listClassName = cx(styles.list, className);
  const isListNotEmpty = !!source?.Entities.length;
  const isListLoading = source?.IsLoading || isLoading;
  const isEntitiesShort =
    source?.Entities && source.Entities.length <= itemCount;
  const isSafariMobile = DeviceHelper.isSafari() && !isDesktopSize;
  const [isInView, setIsInView] = useState(false);

  const inViewGetMediaList = useCallback(
    (inView: boolean) => {
      if (inView && component.SourceId && getMediaList) {
        getMediaList(
          component.SourceId,
          component.SourceUrl,
          component.SourceType
        );
      }
      setIsInView(inView);
    },
    [getMediaList, component.SourceId]
  );

  useEffect(() => {
    if (isInView && !source && component.SourceId) {
      getMediaList(
        component.SourceId,
        component.SourceUrl,
        component.SourceType
      );
    }
  }, [isInView, source, getMediaList, component.SourceId]);

  const renderItems = useMemo(() => {
    if (source && ready && isListNotEmpty && !isListLoading) {
      return source.Entities.map((media: IMediaModel, index: number) => {
        if (media.MediaTypeCode !== AssetType.Intro) {
          return (
            <ListComponentItem
              sourceType={source.SourceType}
              key={`${media.Id}`}
              index={index}
              cellType={component.CellType}
              media={media}
              isPlaceholder={false}
              isActive={activeIndex === index}
              isHovered={hoverIndex === index}
              isRowActive={activeIndex !== undefined}
              isRowHovered={hoverIndex !== undefined}
              withProgress={source.SourceType === SourceType.ContinueWatching}
              readOnly={readOnly}
              cutomListItem={component.CutomListItem}
              style={{
                fontSize: `${
                  themeProvider.getFontSize() *
                  themeProvider.getListItemCountFactor(itemCount)
                }px`,
              }}
              onClick={onItemClick}
              onMouseEnter={onItemMouseEnter}
              onMouseLeave={onItemMouseLeave}
            />
          );
        }
      });
    }

    return (
      placeholder ||
      Array(itemCount)
        .fill(undefined)
        .map((_, idx) => (
          <ListComponentItem
            key={`placeholder_${idx}`}
            cellType={component.CellType}
            isPlaceholder
          />
        ))
    );
  }, [source, ready, isListNotEmpty, isListLoading]);

  const handleSliderAfterTouched = () => {
    setSliderTouched(true);
  };

  const handleSliderBeforeTouched = () => {
    setShowLefArrow(true);
  };

  useEffect(() => {
    if (component.CellType === CellType.Highlights) return;
    if (source && source.Entities.length / itemCount < 2) {
      setSliderTouchedCount(sliderTouchedCount + 1);
    }
  }, [sliderTouched]);

  const getArrowContainerStyle = useCallback(
    (direction) => {
      const isLeftArrow = direction === "left";
      const showLeftArrowAfterFirstTouch =
        (showLefArrow || IsHighlightComponent) && isLeftArrow;
      const arrows = {
        justifyContent: isLeftArrow ? "flex-start" : "flex-end",
        display: showLeftArrowAfterFirstTouch || !isLeftArrow ? "flex" : "none",
      };
      const background = {
        background: `linear-gradient(to ${direction} , rgb(0, 0, 0, 0.0),  rgb(0, 0, 0, ${
          component.CellType === CellType.Circle
            ? 0.7
            : IsHighlightComponent
            ? 0
            : 0.5
        }))`,
      };
      return { arrows, background };
    },
    [showLefArrow]
  );

  const arrowContainer = useCallback(
    (arrowDirection) => {
      return isListFocused ? (
        <ListComponentArrow
          direction={arrowDirection}
          containerStyle={getArrowContainerStyle(arrowDirection)}
        />
      ) : undefined;
    },
    [isListFocused, getArrowContainerStyle]
  );

  const renderHeader = useMemo(() => {
    const skeletonColor = themeProvider.getColor("AppCellsBackgroundColor");
    const title = t(component.TitleTranslationKey || "", component.Title);
    const hasRedirectLink = !readOnly && component.SourceId;

    const isListReady = ready && isListNotEmpty && !isListLoading;
    const isListReadyToBeRedirected =
      isListReady && hasRedirectLink && !isEntitiesShort;
    const isGenreList = source?.SourceType === SourceType.GenreList;
    const isNotGenreListAndNoSeeAllButton = !isGenreList && !showSeeAllButton;

    const shouldLinkToPlaylist =
      isListReadyToBeRedirected && isNotGenreListAndNoSeeAllButton && title;
    const shouldDisplaySeeAll =
      isListReadyToBeRedirected &&
      isNotGenreListAndNoSeeAllButton &&
      (isListFocused || !isDesktopSize);
    const shouldDisplayTitle = isListReady && title;

    if (title || (component.SourceId && !isServerSideRender)) {
      return (
        <SkeletonTheme baseColor={skeletonColor} highlightColor={skeletonColor}>
          <header className={styles.titleContainer}>
            <span className={styles.title}>
              {shouldLinkToPlaylist ? (
                <LinkToPlaylist
                  sourceId={component.SourceId}
                  sourceType={component.SourceType}
                  cellType={component.CellType}
                >
                  {title}
                </LinkToPlaylist>
              ) : shouldDisplayTitle ? (
                title
              ) : (
                <Skeleton width="25rem" />
              )}
            </span>

            {shouldDisplaySeeAll && (
              <SeeAllButton
                sourceId={component.SourceId}
                sourceType={component.SourceType}
                cellType={component.CellType}
              />
            )}
          </header>
          {showSubtitle && component.SubTitle && (
            <div className={styles.subTitle}>
              <span>{component.SubTitle}</span>
            </div>
          )}
        </SkeletonTheme>
      );
    }
  }, [
    component.TitleTranslationKey,
    component.SourceId,
    readOnly,
    isListNotEmpty,
    ready,
    isListFocused,
    isDesktopSize,
    isEntitiesShort,
  ]);

  if (source && !isListLoading && !source.TotalCount) {
    return null;
  }

  return (
    <InView
      as="div"
      id={`list-${component.Id}`}
      className={listClassName}
      rootMargin="25% 0px"
      triggerOnce
      onChange={inViewGetMediaList}
    >
      <div
        className={styles.container}
        onMouseOver={isSafariMobile ? undefined : () => setIsListFocused(true)}
        onMouseLeave={
          isSafariMobile ? undefined : () => setIsListFocused(false)
        }
      >
        {showHeader && renderHeader}

        <ListComponentHorizontalSSR
          mediaListSSR={component.MediaListSSR}
          mediaList={component.MediaList}
          cellType={component.CellType}
        />

        {!isServerSideRender && (
          <Slider
            className={cx(
              { ["circle-list"]: component.CellType === CellType.Circle },
              { ["cover-list"]: component.CellType === CellType.Cover }
            )}
            {...settings}
            slidesToShow={itemCount}
            slidesToScroll={
              sliderTouchedCount === 1 ? itemCount - 1 : itemCount
            }
            draggable={false}
            infinite={
              (sliderTouched || IsHighlightComponent) && !isEntitiesShort
            }
            afterChange={handleSliderAfterTouched}
            beforeChange={handleSliderBeforeTouched}
            prevArrow={arrowContainer("left")}
            nextArrow={arrowContainer("right")}
          >
            {renderItems}
          </Slider>
        )}
        {showSeeAllButton && (
          <div className={styles.bottomButtonSeeAllContainer}>
            <SeeAllButton
              sourceId={component.SourceId}
              sourceType={component.SourceType}
              cellType={component.CellType}
              mode="button"
              linkLabel="web_start_browse_button"
            />
          </div>
        )}
      </div>
    </InView>
  );
};

export default ListComponentHorizontal;
