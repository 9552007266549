import {
  DataProvider,
  IFormValues,
  IUserSubprofileModel,
  IUserSubprofilesAuthStateModel,
  UserStore,
} from "@nf/common";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "rc-field-form";
import { useDispatch } from "react-redux";
import cx from "classnames";

import { Form, Input } from "components";

const MediaButton = dynamic(() => import("components/MediaButton"));
const LabelField = dynamic(() => import("components/Form/LabelField"));

import styles from "./EnterPinCodeForm.module.scss";

interface IEnterPinCodeForm {
  handleSelectProfile?: (subprofile?: IUserSubprofileModel) => void;
  setCodeIsRemoved?: () => void;
  setInfo?: () => void;
  pinCodeId?: string;
  subprofile?: IUserSubprofileModel;
  inputType?: string;
  pinCodeState?: IUserSubprofilesAuthStateModel;
  isPinAvailable?: boolean;
  shouldBeRemoved?: boolean;
  autofocus?: boolean;
}

export type InputRefMap = Map<React.Key, React.RefObject<Input>>;

const inputsArr = [1, 2, 3, 4];

const EnterPinCodeForm = ({
  pinCodeId,
  handleSelectProfile,
  subprofile,
  inputType,
  isPinAvailable,
  pinCodeState,
  setInfo,
  shouldBeRemoved,
  setCodeIsRemoved,
  autofocus = true,
}: IEnterPinCodeForm) => {
  const [form] = useForm();
  const { t } = useTranslation("translations");
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const [mount, setMount] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const initialValues: IFormValues = {
    code: "",
  };

  const inputRefs = useRef<InputRefMap>(new Map());

  const getInputRef = (key: React.Key) => {
    if (!inputRefs.current.has(key)) {
      inputRefs.current.set(key, React.createRef<Input>());
    }

    return inputRefs.current.get(key);
  };

  const onFinish = async (values: IFormValues) => {
    const res = await DataProvider.validateUserSubprofilePinCode(
      pinCodeId,
      values.code
    );

    if (inputType === "password") {
      if (res) {
        handleSelectProfile && handleSelectProfile(subprofile);
        setIsError(false);
      } else if (!res) {
        form.resetFields();
        setIsError(true);
      }
    } else {
      if (!shouldBeRemoved) {
        dispatch(UserStore.Actions.setUserSubprofilePinCode(values.code));
      } else {
        if (res) {
          dispatch(UserStore.Actions.deleteUserSubprofilePinCode());
          setCodeIsRemoved && setCodeIsRemoved();
          setIsDisabled(true);
          setIsError(false);
          form.resetFields();
        } else if (!res) {
          dispatch(UserStore.Actions.deleteUserSubprofilePinCodeFailure());
          setIsError(true);
          setIsDisabled(false);
        }
      }
    }
  };

  const resetForm = () => {
    setInfo && setInfo();
    setIsError(false);
  };

  useEffect(() => {
    setMount(true);
  }, []);

  if (!mount) return null;

  return (
    <Form
      onFinish={onFinish}
      form={form}
      initialValues={initialValues}
      className={inputType !== "password" ? styles.ParentalControlCode : ""}
    >
      <div className={inputType !== "password" ? styles.CodeContainer : ""}>
        <LabelField
          name="code"
          className={
            inputType === "password"
              ? styles.CodeInputs
              : styles.ParentalControlCodeInput
          }
        >
          {({ value, onChange }) => {
            return inputsArr.map((_, index) => (
              <div
                key={index}
                className={inputType === "password" ? styles.InputCode : ""}
              >
                <Input
                  className={cx(
                    inputType === "password"
                      ? styles.InputContainer
                      : styles.ParentalContorlIputContainer,
                    {
                      [styles.InputContainerError]: isError,
                    }
                  )}
                  autoFocus={autofocus && index === 0}
                  inputClassName={
                    inputType === "password"
                      ? styles.Input
                      : styles.ParentalContorlInput
                  }
                  placeholder={inputType === "password" ? "*" : ""}
                  type={inputType}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  key={index}
                  ref={getInputRef(index)}
                  maxLength={1}
                  defaultValue={value[index]}
                  onChange={(val) => {
                    const nextInput = getInputRef(index + 1);
                    const prevInput = getInputRef(index - 1);
                    const inputValues =
                      value.substring(0, index) +
                      val.target.value +
                      value.substring(index + 1);
                    onChange(inputValues);
                    if (value.length + 1 === inputsArr.length) {
                      setIsDisabled(false);
                    }
                    if (val.target.value === "") {
                      if (prevInput?.current) {
                        prevInput.current.focus();
                      }
                    } else if (val.target.value.length > 1) {
                      form.resetFields();

                      if (nextInput?.current) {
                        nextInput.current.focus();
                      }
                    } else {
                      if (nextInput?.current) {
                        nextInput.current.focus();
                      }
                    }

                    if (
                      value.length + 1 === inputsArr.length &&
                      inputType === "password"
                    ) {
                      form.submit();
                    }
                  }}
                  onBlur={resetForm}
                  onFocus={resetForm}
                />
              </div>
            ));
          }}
        </LabelField>
        {isError && (
          <p className={styles.Error}>{t("web_subprofiles_pin_error")}</p>
        )}
      </div>
      {inputType !== "password" && (
        <MediaButton
          loading={pinCodeState?.IsProcessing}
          variant="primary"
          type="submit"
          disabled={isDisabled}
          className={styles.PinCodeButton}
        >
          {isPinAvailable
            ? t("web_subprofiles_parental_control_button_remove_pin")
            : t("web_subprofiles_parental_control_button_save")}
        </MediaButton>
      )}
    </Form>
  );
};

export default EnterPinCodeForm;
