/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel } from "@nf/common";
import styles from "./SeasonSelect.module.scss";

interface ISeasonSelectProps {
  items: IMediaModel[];
  title?: string;
  selected?: string | number;
  onChange: (id: string | number) => void;
}

export const SeasonSelect = ({
  items,
  title,
  selected,
  onChange,
}: ISeasonSelectProps): JSX.Element => {
  return (
    <div className={styles.select}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <ul className={styles.itemsBox}>
        {items?.map((item, index) => {
          const isActive = item.Id === selected || (!selected && index === 0);

          return (
            <li
              key={item.Id}
              className={`${styles.item} ${isActive ? styles.itemActive : ""}`}
              onClick={() => onChange(item.Id)}
            >
              <span>{index + 1}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SeasonSelect;
