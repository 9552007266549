/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useEffect, useState } from "react";
import {
  useSubscriptionsSelector,
  useDispatch,
  PaymentStore,
  IAppState,
  IPaymentInvoiceModel,
} from "@nf/common";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { useSelector } from "react-redux";
import { useGetInvoicePDF } from "./hooks/useGetInvoicePDF";
import styles from "./SettingsPaymentHistory.module.scss";
import { getExpirationDate } from "../SettingsSubscription/components/ExpirationDate/ExpirationDate";
import { PaymentDetailType } from "components/PaymentDetailType";
import { ITableProps } from "components/Table";

type Transaction = Omit<IPaymentInvoiceModel, "TransationDate"> & {
  TransationDate: JSX.Element;
};

const ActionsHandler = dynamic(() => import("components/ActionsHandler"));

const Table = dynamic<ITableProps<Transaction>>(
  () => import("components/Table")
);

export const SettingsPaymentHistory = () => {
  const { t } = useTranslation("translations");
  const dispatch = useDispatch();
  const [showInvoiceClicked, setShowInvoiceClicked] = useState<boolean>(false);
  const [invoiceToFetchId, setInvoiceToFetchId] = useState<string | undefined>(
    undefined
  );
  const {
    subscriptions,
    error: subscriptionsError,
    isLoading: subscriptionsLoading,
  } = useSubscriptionsSelector();
  const {
    Data: invoices,
    Error: invoicesError,
    IsProcessing: invoicesLoading,
  } = useSelector((state: IAppState) => state.payment.paymentInvoices);
  const {
    data: invoicePDF,
    error: invoicePDFError,
    loading: invoicePDFLoading,
    refresh,
  } = useGetInvoicePDF(invoiceToFetchId);

  const subscription = !!subscriptions?.length && subscriptions[0];

  const getInvoice = (invoiceId: string) => {
    setShowInvoiceClicked(true);
    if (invoiceToFetchId !== invoiceId) {
      setInvoiceToFetchId(invoiceId);
      refresh();
    }
  };

  const transactions = invoices?.map((transaction) => {
    const price = Number(transaction.TransationPrice);
    return {
      ...transaction,
      TransationDate: (
        <div
          className="clicable-date"
          onClick={() => getInvoice(transaction.TransationId)}
        >
          {transaction.PeriodFrom}
        </div>
      ),
      PeriodTo: `${transaction.PeriodFrom} - ${transaction.PeriodTo}`,
      TransationPrice: `${price.toFixed(2)} ${transaction.CustomerCurrency}`,
    };
  });

  useEffect(() => {
    if (invoicePDF && showInvoiceClicked) {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${invoicePDF}`;
      link.download = `${invoiceToFetchId}.pdf`;
      link.click();
      setShowInvoiceClicked(false);
    }
  }, [invoicePDF, showInvoiceClicked]);

  /* FIXME Temporarily do not display payment history until all subscriptions are not migrated to Reepay */
  // useEffect(() => {
  //   dispatch(PaymentStore.Actions.getPaymentInvoices());
  // }, []);

  const columns = [
    {
      title: t("web_payment_history_header_date"),
      dataIndex: "TransationDate",
      key: "date",
    },
    {
      title: t("web_payment_history_header_period"),
      dataIndex: "PeriodTo",
      key: "period",
      render: (period: string) => (
        <p>
          {period.slice(0, 10)} <span>{period.slice(11)}</span>
        </p>
      ),
    },
    {
      title: t("web_payment_history_header_payment_method"),
      dataIndex: "PaymentMethod",
      key: "paymentMethod",
    },
    {
      title: t("web_payment_history_header_amount"),
      dataIndex: "TransationPrice",
      key: "amount",
    },
  ];

  return (
    <div className={styles.Container}>
      <ActionsHandler
        isLoading={subscriptionsLoading}
        error={
          subscriptionsError ||
          (!invoicesLoading ? invoicesError : undefined) ||
          (!invoicePDFLoading ? invoicePDFError : undefined)
        }
      >
        <>
          <div className={styles.SubscriptionInfo}>
            {subscription ? (
              <>
                <div className={styles.Item}>
                  <PaymentDetailType subscription={subscription} />
                </div>
                <div className={styles.Item}>
                  <span className={styles.ItemTitle}>
                    {subscription.IsActive
                      ? t("web_subscription_next_renewal")
                      : t("web_subscription_subscription_ends")}
                    :
                  </span>
                  {`${getExpirationDate(subscription.ExpirationDate)}`}
                </div>
              </>
            ) : (
              <div className={styles.Item}>
                <span>{t("web_subscription_no_subscription")}</span>
              </div>
            )}
            <div className={styles.Item}>
              {/* FIXME Temporarily do not display payment history until all subscriptions are not migrated to Reepay */}
              {/* <p>{t("web_payment_history_description")}</p> */}
              <p>{t("web_payment_history_data_error")}</p>
            </div>
          </div>

          {/* FIXME Temporarily do not display payment history until all subscriptions are not migrated to Reepay */}
          {/* <ActionsHandler isLoading={!subscriptionsLoading && invoicesLoading}>
            <Table
              className={styles.Table}
              columns={columns}
              data={transactions}
              rowKey={"TransationId"}
            />
          </ActionsHandler> */}
          <ActionsHandler isLoading={invoicePDFLoading} />
        </>
      </ActionsHandler>
    </div>
  );
};

export default SettingsPaymentHistory;
