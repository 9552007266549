/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import {
  ComponentType,
  IMediaListModel,
  IScreenModel,
  Orientation,
} from "@nf/common";
import dynamic from "next/dynamic";
import { TextComponent } from "../components";

const ImageComponent = dynamic(
  () => import("components/Configuration/ImageComponent")
);
const ListComponent = dynamic(
  () => import("components/Configuration/ListComponent/ListComponent")
);
const WebViewComponent = dynamic(
  () => import("components/Configuration/WebViewComponent")
);

const assignMediaListToComponent = (
  componentId?: string | number,
  componentData: IMediaListModel[] = []
): IMediaListModel | undefined => {
  return componentData.find((item) => item?.MediaListId === componentId);
};

export class ComponentHelper {
  static renderComponents(
    screen: IScreenModel,
    isLoggedIn = false,
    overDeviceLimit = false,
    componentData = []
  ) {
    if (screen && screen.Components && screen.Components.length > 0) {
      return screen.Components.map((componentDefinition, index) => {
        if (!componentDefinition.IsVisible) {
          return null;
        }

        if (
          (componentDefinition.HasAuthorizedContent && !isLoggedIn) ||
          (componentDefinition.HasAuthorizedContent && overDeviceLimit)
        ) {
          return null;
        }

        switch (componentDefinition.ComponentTypeCode) {
          case ComponentType.List:
            if (
              // NOT SUPPORTED
              ("Orientation" in componentDefinition &&
                componentDefinition?.Orientation === Orientation.Grid) ||
              ("Orientation" in componentDefinition &&
                componentDefinition?.Orientation === Orientation.Vertical)
            )
              return;

            const MediaListSSR = assignMediaListToComponent(
              componentDefinition.Id,
              componentData
            );
            return (
              <ListComponent
                key={`${componentDefinition.Id}_${index}`}
                component={{ ...componentDefinition, MediaListSSR }}
              />
            );

          case ComponentType.Image:
            return (
              <ImageComponent
                key={`${componentDefinition.Id}_${index}`}
                component={componentDefinition}
              />
            );

          case ComponentType.WebView:
            return (
              <WebViewComponent
                key={componentDefinition.Id}
                component={componentDefinition}
              />
            );
          case ComponentType.TextWidget:
            return (
              <TextComponent
                component={componentDefinition}
                key={`${componentDefinition.Id}_${index}`}
              />
            );
        }
      });
    }

    return [];
  }
}
