/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { withRouter } from "next/router";
import { ListComponentItem as ListComponentItemDefinition } from "./ListComponentItem";

export const ListComponentItem = withRouter(ListComponentItemDefinition);

export default ListComponentItem;
