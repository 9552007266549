/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useState } from "react";
import { LabelField, Input } from "components";
import { useTranslation } from "next-i18next";
import { subprofilesColors } from "@nf/constants";
import cx from "classnames";
import styles from "./ColorPicker.module.scss";

interface IColorPickerProps {
  initialColor?: string;
}

export const ColorPicker = ({ initialColor }: IColorPickerProps) => {
  const { t } = useTranslation("translations");
  const [currentColor, setCurrentColor] = useState(
    initialColor ?? subprofilesColors[0]
  );

  return (
    <LabelField
      className={styles.colorPicker}
      label={t("web_subprofiles_color")}
      name="color"
    >
      <div className={styles.colorContainer}>
        {subprofilesColors.map((color, index) => {
          return (
            <Input
              className={cx({ [styles.active]: currentColor === color })}
              css={{ backgroundColor: color }}
              checked={currentColor === color}
              onChange={(e) => setCurrentColor(e.target.value)}
              key={index}
              type="checkbox"
              value={color}
            />
          );
        })}
      </div>
    </LabelField>
  );
};

export default ColorPicker;
