import {
  DEFAULT_COUNTRY_CODE_LOWERCASE,
  DEFAULT_LANGUAGE_CODE,
} from "@nf/constants";

type CookiePattern<T> = {
  regex: RegExp;
  defaultValue: T;
};

const SEPARATOR = ";";

const langCookiePattern: CookiePattern<string> = {
  regex: /lang/gi,
  defaultValue: DEFAULT_LANGUAGE_CODE,
};

const countryCookiePattern: CookiePattern<string> = {
  regex: /country/gi,
  defaultValue: DEFAULT_COUNTRY_CODE_LOWERCASE,
};

const convertValue = <T>(value: string | undefined, defaultValue: T): T => {
  if (typeof value !== "undefined" && value !== "undefined") {
    return value as unknown as T;
  }
  return defaultValue;
};

export const getCookieValue = <T>(
  cookies: string,
  pattern: CookiePattern<T>
): T => {
  const cookieValue = cookies
    .split(SEPARATOR)
    .find((item: string) => pattern.regex.test(item))
    ?.split("=")[1];

  return convertValue<T>(cookieValue, pattern.defaultValue);
};

export const getLangCookie = (cookies?: string): string =>
  cookies
    ? getCookieValue<string>(cookies, langCookiePattern)
    : DEFAULT_LANGUAGE_CODE;

export const getCountryCookie = (cookies?: string): string =>
  cookies
    ? getCookieValue<string>(cookies, countryCookiePattern)
    : DEFAULT_COUNTRY_CODE_LOWERCASE;
