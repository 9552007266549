/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import cx from "classnames";
import styles from "./FormLogo.module.scss";
import dynamic from "next/dynamic";

const HeaderLogo = dynamic(
  () => import("components/AppHeader/components/HeaderLogo")
);

interface IFormLogoProps {
  className?: string;
}

export const FormLogo = ({ className }: IFormLogoProps) => (
  <div className={cx(styles.logo, className)}>
    <HeaderLogo />
  </div>
);

export default FormLogo;
