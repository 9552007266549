/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useState } from "react";
import { useTranslation } from "next-i18next";
import {
  ApiErrors,
  buildPasswordMaximumRule,
  buildPasswordMinimumRule,
  DataProvider,
  dispatch,
  IErrorModel,
  IFormValues,
  updateApiErrors,
  userProfileHelper,
  UserStore,
  useSelector,
  useUserSubprofilesSelector,
} from "@nf/common";
import {
  ForgotPasswordLink,
  Form,
  FormButton,
  InputPassword,
  LabelField,
  CommonTextError,
} from "components";
import { useSetCurrentSubProfile } from "../../../hooks";
import authFormStyles from "../../Forms/AuthForm.module.scss";
import styles from "./ForgotPinCode.module.scss";

export const ForgotPinCode = () => {
  const { t } = useTranslation("translations");
  const { Data, IsProcessing: subprofilesLoading } =
    useUserSubprofilesSelector();
  const selectCurrentProfile = useSetCurrentSubProfile();
  const mainProfile = userProfileHelper.findMainProfile(Data?.subprofiles);
  const [isValidatingPassword, setIsValidatingPassword] = useState(false);
  const [apiErrors, setApiErrors] = useState<ApiErrors>({});
  const userEmail = useSelector((state) => state.auth.user?.Email);

  const onValuesChange = (changedValues: IFormValues) => {
    const [isUpdated, newApiErrors] = updateApiErrors(apiErrors, changedValues);

    if (isUpdated) setApiErrors(newApiErrors);

    setApiErrors({
      ...apiErrors,
      Password: [""],
    });
  };

  const onFinish = async (values: IFormValues) => {
    try {
      setIsValidatingPassword(true);
      await DataProvider.validatePassword(values["password"], userEmail);
      await selectCurrentProfile(mainProfile, true);
      dispatch(UserStore.Actions.deleteUserSubprofilePinCode());
      setIsValidatingPassword(false);
    } catch (err) {
      const error = err as IErrorModel;

      setApiErrors({
        ...apiErrors,
        Password: [`${error.Message}`],
      });
      setIsValidatingPassword(false);
    }
  };

  return (
    <div className={styles.forgotPin}>
      <h1 className={styles.title}>
        {t("web_subprofiles_forgot_pin_enter_password")}
      </h1>
      <p className={styles.description}>
        {t("web_subprofiles_forgot_pin_description")}
      </p>
      <Form onFinish={onFinish} onValuesChange={onValuesChange}>
        <LabelField
          className={styles.labelFieldContainer}
          name="password"
          rules={[buildPasswordMinimumRule(), buildPasswordMaximumRule()]}
          tip={<ForgotPasswordLink />}
          validateTrigger={["onBlur", "onSubmit"]}
        >
          <InputPassword
            spellCheck="false"
            placeholder={t("web_subprofiles_forgot_pin_input_placeholder")}
            className={authFormStyles.inputContainer}
            inputClassName={authFormStyles.input}
            visibilityToggle
          />
        </LabelField>
        <div className={styles.buttonWrapper}>
          <FormButton
            disabled={subprofilesLoading || isValidatingPassword}
            loading={subprofilesLoading || isValidatingPassword}
            nonEmptyFields={{
              requiredFields: ["password"],
              autoCompleted: false,
            }}
          >
            {t("web_subprofiles_forgot_pin_button_continue")}
          </FormButton>
        </div>
        {!!apiErrors.Password?.[0]?.length && !isValidatingPassword && (
          <CommonTextError
            className={styles.error}
            error={{ Message: "web_subprofiles_wrong_password" }}
            centered
          />
        )}
      </Form>
    </div>
  );
};

export default ForgotPinCode;
