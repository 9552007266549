/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { CellType, RouteHelper, SourceType } from "@nf/common";
import * as React from "react";
import { isServerSideRender } from "@nf/helpers";
import {
  IListComponentItemWrapperProps,
  IListComponentItemProps,
} from "../../models";
import styles from "./ListComponentItem.module.scss";
import dynamic from "next/dynamic";
import { ListComponentItemCover } from "../ListComponentItemCover";
import { ListComponentItemHighlight } from "../ListComponentItemHighlight";
import { ListComponentItemPromo } from "../ListComponentItemPromo";
import { ListComponentItemFrame } from "../ListComponentItemFrame";
import { ListComponentItemCustom } from "../ListComponentItemCustom";
import { ListComponentItemCircle } from "../ListComponentItemCircle";
import { ListComponentGenreItemFrame } from "../ListComponentGenreItemFrame";

const ItemPlaceholderSSR = dynamic(
  () => import("../../../ListComponentItemSSR/ItemPlaceholderSSR")
);
/**
 * Component to generate current cell in horizontal list. For example a film from category list
 * @exports ListComponentItem [default]
 */
export class ListComponentItem extends React.PureComponent<IListComponentItemWrapperProps> {
  public static defaultProps: IListComponentItemProps = {
    isPlaceholder: false,
    width: -1,
  };

  private renderView = () => {
    const {
      media,
      isActive,
      isHovered,
      isRowActive,
      isRowHovered,
      isPlaceholder,
      width = -1,
      withProgress,
      index,
      readOnly,
      gridColumns,
      cutomListItem,
      query,
      router,
      sourceType,
    } = this.props;
    const detailsUrlObject = RouteHelper.goToDetails(media, query);
    !isServerSideRender &&
      router?.prefetch(
        `/${router.query.country}/${router.query.language}/${detailsUrlObject.pathname}`
      );
    const cellType = this.props.cellType;
    const itemKey = `list-item-${index}_${media ? media.Id : ""}`;

    if (isServerSideRender) {
      return <ItemPlaceholderSSR media={media} router={router} />;
    }

    if (sourceType === SourceType.GenreList) {
      return (
        <ListComponentGenreItemFrame
          key={itemKey}
          media={media}
          index={index}
          isActive={isActive}
          isPlaceholder={isPlaceholder}
          width={width}
          readOnly={readOnly}
          withProgress={withProgress}
          gridColumns={gridColumns}
          cellType={cellType}
        />
      );
    }

    switch (cellType) {
      case CellType.Cover:
        return (
          <ListComponentItemCover
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
            gridColumns={gridColumns}
            query={query}
          />
        );
      case CellType.CoverLarge:
        return (
          <ListComponentItemCover
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
            gridColumns={gridColumns}
            query={query}
            cellType={CellType.CoverLarge}
          />
        );
      case CellType.Highlights:
        return (
          <ListComponentItemHighlight
            key={itemKey}
            media={media}
            index={index}
            isActive={false}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
          />
        );
      case CellType.Promo:
        return (
          <ListComponentItemPromo
            key={itemKey}
            media={media}
            index={index}
            isActive={false}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
          />
        );
      case CellType.Event:
        return (
          <ListComponentItemFrame
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
            withProgress={withProgress}
          />
        );
      case CellType.Custom:
        return (
          <ListComponentItemCustom
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isHovered={isHovered}
            isRowActive={isRowActive}
            isRowHovered={isRowHovered}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
            withProgress={withProgress}
            gridColumns={gridColumns}
            onClick={this.props?.onClick}
            onMouseEnter={this.props?.onMouseEnter}
            onMouseLeave={this.props?.onMouseLeave}
          >
            {cutomListItem ?? <></>}
          </ListComponentItemCustom>
        );
      case CellType.Circle:
        return (
          <ListComponentItemCircle
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            width={width}
          />
        );
      case CellType.Frame:
      case CellType.FrameLarge:
      default:
        return (
          <ListComponentItemFrame
            key={itemKey}
            media={media}
            index={index}
            isActive={isActive}
            isPlaceholder={isPlaceholder}
            width={width}
            readOnly={readOnly}
            withProgress={withProgress}
            gridColumns={gridColumns}
            cellType={cellType}
          />
        );
    }
  };

  render() {
    return (
      <div className={styles.item} style={this.props.style}>
        {this.renderView()}
      </div>
    );
  }
}
