/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { NextSeo } from "next-seo";

export const WatchSEO = () => {
  return <NextSeo noindex={true} nofollow={true} />;
};
