import {
  DEFAULT_COUNTRY_CODE_LOWERCASE,
  DEFAULT_LANGUAGE_CODE,
  ORGANIZATION_EMAIL,
  ORGANIZATION_NAME,
  ORGANIZATION_PHONE_NUMBER,
  ROUTES,
} from "@nf/constants";

import type { Organization, WithContext } from "schema-dts";

const url = `${process.env.NEXT_PUBLIC_MAIN_CANONICAL}/${DEFAULT_COUNTRY_CODE_LOWERCASE}/${DEFAULT_LANGUAGE_CODE}/${ROUTES.HOME}`;
const logo = `${process.env.NEXT_PUBLIC_MAIN_CANONICAL}/assets/app/logo.png`;

const getOrganizationSchema = (): WithContext<Organization> => ({
  "@context": "https://schema.org",
  "@type": "Organization",
  name: ORGANIZATION_NAME,
  url,
  logo,
  contactPoint: {
    "@type": "ContactPoint",
    telephone: ORGANIZATION_PHONE_NUMBER,
    email: ORGANIZATION_EMAIL,
  },
});

export default getOrganizationSchema;
