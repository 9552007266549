/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { ImageHelper, RouteHelper } from "@nf/common";
import React from "react";
import { IListComponentItemProps } from "../../models";
import dynamic from "next/dynamic";

import styles from "./ListComponentItemCircle.module.scss";

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

export interface IListComponentItemCircleProps extends IListComponentItemProps {
  width: number;
}

export const ListComponentItemCircle = ({
  media,
}: IListComponentItemCircleProps) => {
  const circleImageUrl = ImageHelper.getCircleImageUrl(media?.Images);
  if (media) {
    if (circleImageUrl) {
      return (
        <div className={styles.item}>
          <div className={styles.imageContainer}>
            <LinkWithLocale href={RouteHelper.goToDetails(media)}>
              <a>
                <img
                  className={styles.image}
                  src={circleImageUrl}
                  alt={media?.Title}
                />
              </a>
            </LinkWithLocale>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.itemPlaceholder}>
        <LinkWithLocale href={RouteHelper.goToDetails(media)}>
          <a className={styles.placeholder} />
        </LinkWithLocale>
      </div>
    );
  }
  return null;
};
