import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { DialogContentWrapper } from "../DialogContentWrapper";

import { Dialog } from "components/Dialog";

import styles from "./PauseSubscriptionSuccesInfoDialog.module.scss";

export interface IPauseSubscriptionSuccesInfoDialogProps {
  setIsPauseFormSent: (value: boolean) => void;
}

export const PauseSubscriptionSuccesInfoDialog = ({
  setIsPauseFormSent,
}: IPauseSubscriptionSuccesInfoDialogProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation("translations");

  const handleClick = () => {
    setIsVisible((prevState) => !prevState);
    setIsPauseFormSent(false);
  };

  return (
    <Dialog
      wrapClassName={styles.DialogWrap}
      className={styles.DialogContainer}
      destroyOnClose={true}
      footer={null}
      visible={isVisible}
      onCancel={handleClick}
    >
      <DialogContentWrapper
        title={t("web_subscription_pause_confirmation_headline")}
      >
        <div className={styles.PauseSubscriptionSuccessInfoContainer}>
          <Trans
            i18nKey={t("web_subscription_pause_confirmation_body")}
            components={{
              1: <p className={styles.PauseSubscriptionSuccesInfo} />,
            }}
          />
        </div>
      </DialogContentWrapper>
    </Dialog>
  );
};
