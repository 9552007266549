/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  ApiErrors,
  AppActions,
  AuthStore,
  buildPasswordMinimumRule,
  IChangePasswordModel,
  IFormValues,
} from "@nf/common";
import { ActionsHandler } from "components/ActionsHandler";
import React from "react";
import { WithTranslation } from "react-i18next";
import { ActionCreator } from "redux";
import { Form, FormButton, LabelField } from "../../../Form";
import { InputPassword } from "../../../Inputs";
import styles from "./PasswordForm.module.scss";

export interface IPasswordFormStateProps {
  action?: AppActions;
  stateAuth?: AuthStore.Types.IAuthState;
  isLoading?: boolean;
  changePasswordSuccess?: boolean;
}

export interface IPasswordFormDispatchProps {
  changePassword: ActionCreator<AuthStore.Types.IChangePasswordAction>;
  clearChangePasswordSuccessAction: ActionCreator<AuthStore.Types.IClearChangePasswordResultAction>;
}

export interface IPasswordFormProps
  extends IPasswordFormStateProps,
    IPasswordFormDispatchProps,
    WithTranslation {}

interface IPasswordFormState {
  isFormSent: boolean;
  apiErrors: ApiErrors;
}
const PASSWORD_FIELD_NAME = "password";
const PASSWORD_NEW_FIELD_NAME = "password_new";
const FORM_FIELDS = [PASSWORD_FIELD_NAME, PASSWORD_NEW_FIELD_NAME];

export class PasswordForm extends React.PureComponent<
  IPasswordFormProps,
  IPasswordFormState
> {
  public state: IPasswordFormState = {
    isFormSent: false,
    apiErrors: {},
  };

  static getDerivedStateFromProps(
    nextProps: IPasswordFormProps,
    prevState: IPasswordFormState
  ) {
    if (prevState.isFormSent && nextProps.stateAuth?.error) {
      return {
        apiErrors: {
          password: [nextProps.stateAuth?.error.Message],
        },
      };
    }

    return null;
  }

  private onFinish = (values: IFormValues) => {
    const { changePassword } = this.props;

    const payload: IChangePasswordModel = {
      password: values[PASSWORD_FIELD_NAME],
      password_new: values[PASSWORD_NEW_FIELD_NAME],
    };

    this.setState({ isFormSent: true });
    changePassword(payload);
  };

  private onValuesChange = (changedValues: IFormValues) => {
    const { apiErrors } = this.state;

    for (const field in changedValues) {
      if (field in apiErrors) {
        const newApiErrors = { ...apiErrors };
        delete newApiErrors[field];
        this.setState({ isFormSent: false, apiErrors: newApiErrors });
      }
    }

    if (this.props.changePasswordSuccess) {
      this.props.clearChangePasswordSuccessAction();
    }
  };

  public render() {
    const { t } = this.props;
    return (
      <ActionsHandler
        isLoading={undefined}
        error={
          this.props?.action?.type === AuthStore.Consts.CHANGE_PASSWORD_FAILURE
            ? this.props?.action.error
            : undefined
        }
        errorTranslationKey="web_my_account_menu_change_password_error"
      >
        <div className={styles.PasswordForm}>
          <Form onFinish={this.onFinish} onValuesChange={this.onValuesChange}>
            <LabelField
              name="password"
              label={t("web_new_password_current_password_header")}
              rules={[buildPasswordMinimumRule()]}
              apiErrors={this.state.apiErrors.name || []}
              validateTrigger={["onBlur", "onSubmit"]}
            >
              <InputPassword
                className="FormInput"
                placeholder={t("web_new_password_current_password_field")}
              />
            </LabelField>

            <LabelField
              name="password_new"
              label={t("web_new_password_header")}
              tip={t("web_new_password_text")}
              rules={[buildPasswordMinimumRule()]}
              apiErrors={this.state.apiErrors.name || []}
              validateTrigger={["onBlur", "onSubmit"]}
            >
              <InputPassword
                className="FormInput"
                placeholder={t("web_new_password_field")}
              />
            </LabelField>
            <div className={styles.ButtonLine}>
              {this.props.changePasswordSuccess && (
                <p>{t("web_login_password_updated")}</p>
              )}
            </div>
            <div className={styles.ButtonLine}>
              <FormButton
                disabled={this.props.isLoading || this.state.isFormSent}
                loading={this.props.stateAuth?.isProcessing}
                nonEmptyFields={{
                  requiredFields: FORM_FIELDS,
                  autoCompleted: false,
                }}
              >
                {t("web_new_password_submit")}
              </FormButton>
            </div>
          </Form>
          <p className={styles.information}>
            {t("web_new_password_information")}
          </p>
        </div>
      </ActionsHandler>
    );
  }
}
