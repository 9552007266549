/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { RouteHelper } from "@nf/common";
import dynamic from "next/dynamic";
import { useBrandingLogo } from "../../hooks";

import styles from "./HeroLogo.module.scss";

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

const defaultLogo = "/assets/app/app-header-logo-transparent.png";

interface HeroLogoProps {
  title?: string;
}

export const HeroLogo = ({ title }: HeroLogoProps) => {
  const logo = useBrandingLogo();

  return (
    <div className={styles.header}>
      <LinkWithLocale href={RouteHelper.goToHome("")}>
        <a>
          <img alt="Logo" className={styles.logo} src={logo || defaultLogo} />
        </a>
      </LinkWithLocale>
      <div className={styles.title}>{title}</div>
    </div>
  );
};
