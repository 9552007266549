import type { Person, WebPage, WithContext } from "schema-dts";

const getWebPageSchema = (
  actors?: string[],
  directors?: string[]
): WithContext<WebPage> => {
  const actor: Person[] =
    actors?.map((actor) => ({
      "@type": "Person",
      name: actor,
      jobTitle: "Actor",
    })) || [];

  const director: Person[] =
    directors?.map((director) => ({
      "@type": "Person",
      name: director,
      jobTitle: "Director",
    })) || [];

  const all = [...actor, ...director];

  return {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntity: all,
  };
};

export default getWebPageSchema;
