/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React from "react";

import styles from "./DialogContentWrapper.module.scss";

interface IWrapperProps {
  title?: React.ReactNode;
  children: React.ReactNode;
}

export const DialogContentWrapper = ({ title, children }: IWrapperProps) => (
  <div className={styles.Dialog}>
    <div className={styles.Container}>
      {title && <span className={styles.Title}>{title}</span>}
      <div className={styles.Content}>{children}</div>
    </div>
  </div>
);
