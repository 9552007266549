/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { PasswordForm } from "../Forms";

export const SettingsChangePassword = () => {
  return <PasswordForm />;
};

export default SettingsChangePassword;
