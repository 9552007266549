/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import cx from "classnames";
import InfoCircleIcon from "resources/icons/info-circle.svg";
import type { IListComponentItemProps } from "../../models";

import styles from "./ListComponentPersonItemFrame.module.scss";

export type ListComponentPersonItemFrameProps = IListComponentItemProps;

export const ListComponentPersonItemFrame = ({
  index,
  media,
  isActive,
  isHovered,
  isRowActive,
  isRowHovered,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: ListComponentPersonItemFrameProps) => {
  // TODO list component is typed for media elements only
  // it might be a good idea to make it more generic so
  // other data such as person could be used
  const person = media as any;

  const handleClick = () => onClick?.(index, person);
  const handleMouseEnter = () => onMouseEnter?.(index, person);
  const handleMouseLeave = () => onMouseLeave?.(index, person);

  return (
    <div
      className={cx(styles.item, {
        [styles.itemActive]: isActive,
        [styles.itemHovered]: isHovered,
        [styles.itemFaded]: isRowActive && !isActive && !isHovered,
        [styles.rowHovered]: isRowHovered && !isHovered,
      })}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.container}>
        <div className={styles.gradient} />
        <div className={styles.dust} />
        {isActive && <InfoCircleIcon className={styles.icon} />}
        <div className={styles.information}>
          {/* TODO mising data from API */}
          {/* {person?.PersonRoleDisplayName && (
            <div className={styles.role}>{person?.PersonRoleDisplayName}</div>
          )} */}
          <div className={styles.name}>{person?.PersonFullName}</div>
        </div>
      </div>
    </div>
  );
};

export default ListComponentPersonItemFrame;
