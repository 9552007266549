/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ImageHelper, IMediaImageModel } from "@nf/common";
import styles from "./MediaDetailsCoverImg.module.scss";

interface IMediaDetailsCoverImgProps {
  images?: IMediaImageModel[];
  className?: string;
  altText?: string;
}

export const MediaDetailsCoverImg = ({
  images,
  className,
  altText,
}: IMediaDetailsCoverImgProps): JSX.Element => {
  const coverImg = ImageHelper.getPosterImageUrl(images);

  return (
    <>
      {coverImg && (
        <img
          className={`${styles.coverImg} ${className}`}
          src={coverImg}
          alt={altText}
        />
      )}
    </>
  );
};

export default MediaDetailsCoverImg;
