/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useLayoutEffect, useMemo, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import ReactDOM from "react-dom";

import { IMediaModel, UrlQuery } from "@nf/common";

import styles from "./ListComponentGenreItemModal.module.scss";

export interface IListComponentGenreItemModalProps {
  media: IMediaModel;
  gridColumns?: number;
  width: number;
  height: number;
  isFadingOut: boolean;
  rectTop: number;
  rectLeft: number;
  rectRight: number;
  query?: UrlQuery;
}

const ListComponentGenreItemModal = ({
  media,
  gridColumns,
  width,
  height,
  isFadingOut,
  rectTop,
  rectLeft,
  rectRight,
}: IListComponentGenreItemModalProps) => {
  const [isFirstTile, setIsFirstTile] = useState(false);
  const [isLastTile, setIsLastTile] = useState(false);
  const isLargeDesktop = window && window.innerWidth > 1537;

  useLayoutEffect(() => {
    const firstTile = rectLeft < 135;
    const lastTile = rectRight > window.innerWidth - 10;
    setIsLastTile(lastTile);
    setIsFirstTile(firstTile);
  }, [width, height]);

  const calcPosition = useMemo(() => {
    const lastTileHover = window.innerWidth - width * 2;
    const frame_firstTileRectPosition = isLargeDesktop
      ? rectLeft + 70
      : rectLeft + 50;
    const frame_otherTileRectPosition = isLargeDesktop
      ? rectLeft + 10
      : rectLeft;

    if (gridColumns) {
      return {
        top: `${rectTop}px`,
        left: `${rectLeft}px`,
      };
    }
    const position = isFirstTile
      ? frame_firstTileRectPosition
      : isLastTile
      ? lastTileHover
      : frame_otherTileRectPosition;
    return {
      top: `${rectTop}px`,
      left: `${position}px`,
    };
  }, [
    gridColumns,
    isFirstTile,
    isLastTile,
    rectLeft,
    rectRight,
    rectTop,
    isFadingOut,
    window.innerWidth,
  ]);

  const { modal } = useSpring({
    from: { modal: 0 },
    modal: !isFadingOut ? 1 : 0,
    delay: !isFadingOut ? 300 : 0,
    reverse: isFadingOut,
    config: { duration: 0 },
  });

  return ReactDOM.createPortal(
    <animated.div
      className={styles.modal}
      style={{
        ...calcPosition,
        width: `${width}px`,
        height: `${height}px`,
        opacity: modal.to({
          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
          output: [0, 0, 0, 0, 0.3, 0.4, 0.5, 1],
        }),
        scale: modal.to({
          range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
          output: [1, 1, 1, 1.2, 1.4, 1.6, 1.8, 2],
        }),
      }}
    >
      <div className={styles.container}>
        <div className={styles.title}>{media.Title}</div>
      </div>
    </animated.div>,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById("portal")!
  );
};

export default ListComponentGenreItemModal;
