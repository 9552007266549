/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { IMediaModel, MediaType, RouteHelper, UrlQuery } from "@nf/common";
import { LinkWithLocale } from "components/LinkWithLocale";

import PlayFilledIcon from "resources/play-filled.svg";

import styles from "./PlayIconButton.module.scss";

export interface IPlayIconButtonProps {
  media?: IMediaModel;
  query?: UrlQuery;
}

export const PlayIconButton = ({ media, query }: IPlayIconButtonProps) => {
  if (!media || !media.IsPlayable) {
    return null;
  }

  const isCollection = media.MediaTypeCode === MediaType.Collection;
  const detailsUrl = RouteHelper.goToDetails(media, query);
  const playerUrl = RouteHelper.goToPlayer(media, query);

  return (
    <>
      <LinkWithLocale href={detailsUrl}>
        <a className={styles.placeholder}></a>
      </LinkWithLocale>
      <LinkWithLocale href={isCollection ? detailsUrl : playerUrl}>
        <a className={styles.icon}>
          <PlayFilledIcon />
        </a>
      </LinkWithLocale>
    </>
  );
};

export default PlayIconButton;
