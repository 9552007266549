/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import * as React from "react";

import styles from "./ImageWithPlaceholder.module.scss";
import dynamic from "next/dynamic";
import { useEffect } from "react";

const AppImage = dynamic(() => import("../AppImage"), { ssr: false });

export interface Props {
  imageContainerClassName?: string; // custom className for component
  imageContainerStyles?: React.CSSProperties; // custom styles for component
  imageClassName?: string; // custom styles for image element
  imageSrc?: string; // source for the image which will be rendered once it is loaded
  alt?: string;
  isLCP?: boolean;
  blurDataURL?: string;
}

/*
 * Component which will display given image based od imageSrc. Until image is not loaded (ready to be rendered)
 * a placeholder image will be rendered
 */
const ImageWithPlaceholderRaw = ({
  imageContainerClassName = "",
  imageContainerStyles = {},
  imageClassName,
  imageSrc,
  alt,
  isLCP,
}: Props) => {
  const imgClassName = imageClassName
    ? `${styles.image} ${imageClassName}`
    : styles.image;
  const ref = React.useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = React.useState<{
    width?: number;
    height?: number;
  }>({ width: undefined, height: undefined });
  useEffect(() => {
    if (ref.current) {
      setDimensions({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      });
    }
  }, [ref.current]);

  return (
    <div
      className={imageContainerClassName}
      style={imageContainerStyles}
      ref={ref}
    >
      {imageSrc && dimensions.height && (
        <AppImage
          className={imgClassName}
          src={imageSrc || ""}
          draggable={false}
          priority={isLCP}
          layout={dimensions.width ? "intrinsic" : "fill"}
          width={dimensions.width}
          height={dimensions.height}
          alt={alt || ""}
          onlyWidth
        />
      )}
    </div>
  );
};

export default React.memo(ImageWithPlaceholderRaw);
