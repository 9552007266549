import dynamic from "next/dynamic";

import PlayFilledIcon from "resources/play-filled.svg";

import styles from "./BigPlayButton.module.scss";

const MediaButton = dynamic(() => import("../MediaButton"));

export interface IBigPlayButtonProps {
  onClick?: () => void;
}

export const BigPlayButton = ({ onClick }: IBigPlayButtonProps) => (
  <MediaButton
    onClick={onClick}
    variant="transparent"
    className={styles.playBtn}
  >
    <PlayFilledIcon />
  </MediaButton>
);

export default BigPlayButton;
