import { IMediaModel, useIsPlayableWithDate } from "@nf/common";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./AvailabilityLabel.module.scss";

interface AvailabilityLabelProps {
  media: IMediaModel;
}

export const AvailabilityLabel = ({ media }: AvailabilityLabelProps) => {
  const { t } = useTranslation("translations");
  const { isPlayable, availableFrom, availableTo } =
    useIsPlayableWithDate(media);

  if (isPlayable && availableTo) {
    return (
      <div className={`${styles.label} ${styles.expiresSoon}`}>
        {t("web_asset_expire")} {availableTo}
      </div>
    );
  }

  if (!isPlayable && availableFrom) {
    return (
      <div className={`${styles.label} ${styles.availableSoon}`}>
        {availableFrom}
      </div>
    );
  }

  return null;
};

export default AvailabilityLabel;
