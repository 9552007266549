import styles from "./PaymentForm.module.scss";

interface PaymentFormProps {
  url?: string;
}

export const PaymentForm = ({ url }: PaymentFormProps) => {
  if (!url) {
    return null;
  }

  return <iframe className={styles.frame} src={url} />;
};

export default PaymentForm;
