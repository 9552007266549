import { IFrameComponentModel } from "@nf/common/dist/models/Configuration/IFrameComponentModel";
import React from "react";

import styles from "./IFrame.module.scss";

type TIFrame = {
  component: IFrameComponentModel;
};

const DEFAULT_HEIGHT = 100;

export const IFrame = ({ component }: TIFrame) => {
  const componentSettings = component.Fallback;
  const height = componentSettings?.height
    ? componentSettings.height + 50
    : DEFAULT_HEIGHT;

  if (!componentSettings?.url) {
    return null;
  }

  return (
    <iframe
      className={styles.iframeComponent}
      src={componentSettings?.url}
      style={{ height }}
    />
  );
};
