/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { useTranslation } from "next-i18next";

import styles from "./PaymentStatus.module.scss";

export const PaymentStatusSuccess = () => {
  const { t } = useTranslation("translations");

  return (
    <div className={styles.status}>
      <p className={styles.info}>{t("web_payment_success_header")}</p>
    </div>
  );
};
