import React from "react";
import { isMobile } from "react-device-detect";
import styles from "./MobileSignUpComponent.module.scss";
import { Trans, useTranslation } from "next-i18next";
import { ROUTES } from "@nf/constants";
import dynamic from "next/dynamic";
import { useIsLoggedIn } from "@nf/common";

const LinkText = dynamic(() => import("components/LinkText"));

const OfferButton = dynamic(() => import("components/OfferButton"));

export const MobileSignUpComponent = () => {
  const { t } = useTranslation("translations");
  const isLoggedIn = useIsLoggedIn();

  return isMobile && !isLoggedIn ? (
    <div className={styles.container}>
      <OfferButton />
      <span className={styles.login}>
        <Trans
          i18nKey={t("web_signup_login")}
          components={{
            1: <LinkText bold href={ROUTES.LOGIN}></LinkText>,
          }}
        />
      </span>
    </div>
  ) : null;
};
