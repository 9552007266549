/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useTranslation } from "next-i18next";
import cx from "classnames";
import styles from "./CommonTextError.module.scss";
import { IErrorModel } from "@nf/common";

export interface ICommonTextErrorProps {
  centered?: boolean;
  className?: string;
  error?: IErrorModel;
  translationKey?: string;
}

export const CommonTextError = ({
  centered,
  className,
  error,
  translationKey,
}: ICommonTextErrorProps) => {
  const { t } = useTranslation("translations");
  let errorMessage: string | null = null;

  if (error && typeof error?.Message === "string")
    errorMessage = `web_error_${error?.Message.toLowerCase()}`;
  else if (translationKey) errorMessage = translationKey;

  return (
    <p
      className={cx(styles.error, className, {
        [styles.errorCentered]: centered,
      })}
    >
      {errorMessage
        ? t([errorMessage, "web_generic_error_text"])
        : t("web_generic_error_text", "")}
    </p>
  );
};

export default CommonTextError;
