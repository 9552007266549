import { FC } from "react";
import { useTranslation } from "next-i18next";
import { IPaymentOfferModel } from "@nf/common";
import cx from "classnames";
import dynamic from "next/dynamic";
import ChooseOfferButton from "./CardComponents/ChooseOfferButton";
import { isAnnualPeriod } from "./utils";

const OfferPrice = dynamic(() => import("./CardComponents/OfferPrice"));

export interface IOfferCardProps {
  offer: IPaymentOfferModel;
  active?: boolean;
  displaySavingLabel?: boolean;
  displayMode?: "choose" | "change";
  handleSelect?: (offerId: string, offerHandle: string) => void;
}

const SavingLabel: FC<{ period: string }> = ({ period }) => {
  const { t } = useTranslation("translations");

  if (!isAnnualPeriod(period)) {
    return null;
  }

  return (
    <div className={"annualSave"}>
      {t("web_choose_subscription_annual_save")}
    </div>
  );
};

export const SimpleOfferCard = ({
  offer,
  active,
  displaySavingLabel = false,
  displayMode = "choose",
  handleSelect,
}: IOfferCardProps) => {
  return (
    <div
      className={cx("OfferContainer", "clickable", active && "active")}
      onClick={() => handleSelect?.(offer.Id, offer.Handle)}
    >
      {displaySavingLabel && <SavingLabel period={offer.Period} />}

      <OfferPrice price={offer.Price} period={offer.Period} />

      {handleSelect && (
        <ChooseOfferButton offer={offer} displayMode={displayMode} />
      )}
    </div>
  );
};

export default SimpleOfferCard;
