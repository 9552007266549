import { ImageHelper, type IHeroComponentModel } from "@nf/common";
import { ROUTES } from "@nf/constants";
import React from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import { LinkWithLocale } from "../../../LinkWithLocale";

import styles from "./LandingPageSignUp.module.scss";

const SignUpForm = dynamic(() => import("./components/SignUpForm"));

const AppHeaderSignUpButtonsContainer = dynamic(
  () =>
    import("components/AppHeader/AppHeaderSignUpMobile/AppHeaderSignUpMobile")
);

interface LandingPageSignUpProps {
  component: IHeroComponentModel;
}

export const LandingPageSignUp = ({ component }: LandingPageSignUpProps) => {
  const backgroundImage = ImageHelper.getHighlightsImageUrl(
    component.MediaList?.[0].Images,
    null
  );

  return (
    <>
      <AppHeaderSignUpButtonsContainer isLandingPage fixedPosition={true} />
      <div className={styles.signUpContainer}>
        <LinkWithLocale href={ROUTES.HOME}>
          <a className={styles.background}>
            <Image
              alt=""
              src={backgroundImage || ""}
              priority={true}
              quality={100}
              layout="fill"
              objectFit="cover"
            />
          </a>
        </LinkWithLocale>
        <div className={styles.formContainer}>
          <SignUpForm title={component.MediaList?.[0].Title} />
        </div>
      </div>
    </>
  );
};
