/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import cx from "classnames";
import { Form } from "../Form";
import styles from "./FormButton.module.scss";
import dynamic from "next/dynamic";
import { TLoadingAnimation } from "../../MediaButton";

const MediaButton = dynamic(() => import("components/MediaButton"));

const { Field } = Form;

interface IFormButtonProps {
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
  nonEmptyFields?: { requiredFields: string[]; autoCompleted: boolean };
  loading?: boolean;
  children: React.ReactNode;
  className?: string;
  loadingAnimationType?: TLoadingAnimation;
  hideButtonTitle?: boolean;
}

export const FormButton = ({
  type = "submit",
  disabled,
  nonEmptyFields,
  loading,
  children,
  className,
  loadingAnimationType,
  hideButtonTitle,
}: IFormButtonProps) => {
  const checkIfEmpty = (fields: { [key: string]: string }) =>
    nonEmptyFields?.requiredFields.some(
      (field) =>
        fields[field] === "" ||
        (!fields[field] && !nonEmptyFields.autoCompleted)
    );

  return (
    <Field shouldUpdate={true}>
      {(control, meta, form) => {
        const hasErrors = !!form
          .getFieldsError()
          .filter(({ errors }) => errors.length).length;

        return (
          <MediaButton
            className={cx(styles.button, className)}
            disabled={
              hasErrors ||
              disabled ||
              (nonEmptyFields && checkIfEmpty(form.getFieldsValue()))
            }
            type={type}
            variant="primary"
            loading={loading}
            loadingAnimationType={loadingAnimationType}
          >
            {!hideButtonTitle && children}
          </MediaButton>
        );
      }}
    </Field>
  );
};

export default FormButton;
