/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import cx from "classnames";
import { LinkWithLocale } from "components/LinkWithLocale";
import { useRouter } from "next/router";
import React from "react";
import styles from "./SettingsMenuItem.module.scss";

export interface ISettingsMenuItemModel {
  key: number;
  title: string;
  componentPath: string;
  hide?: boolean;
}

export interface ISettingsMenuItemProps {
  item: ISettingsMenuItemModel;
}

export const SettingsMenuItem = ({ item }: ISettingsMenuItemProps) => {
  const router = useRouter();
  const isActive = item.componentPath === "settings/" + router.query.submenuKey;

  return (
    <li
      className={cx(styles.menuItem, {
        [styles.menuItemActive]: isActive,
      })}
    >
      <LinkWithLocale href={`/${item.componentPath}`}>
        <a className={styles.link}>{item.title}</a>
      </LinkWithLocale>
    </li>
  );
};
