import { usePortal } from "@nf/common";
import { createPortal } from "react-dom";

export interface IPortalProps {
  children?: JSX.Element;
  id?: string;
}

export const Portal = ({ children, id }: IPortalProps) => {
  const target = usePortal(id);
  return createPortal(children, target);
};

export default Portal;
