/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useTranslation } from "next-i18next";
import { IUserSubscriptionModel } from "@nf/common";
import styles from "./PaymentDetailType.module.scss";

type PaymentDetailLabelProps = {
  subscription?: IUserSubscriptionModel & {
    CustomerCurrency?: string;
  };
  className?: string;
};

export const PaymentDetailType = ({
  className = "mt-md",
  subscription,
}: PaymentDetailLabelProps) => {
  const { t } = useTranslation("translations");

  const price = subscription?.NextPaymentPrice;
  const currency =
    subscription?.NextPaymentCurrency?.toLowerCase() ??
    subscription?.CustomerCurrency?.toLowerCase();
  const period = subscription?.Period;

  return (
    <div className={`${styles.container} ${className}`}>
      {currency && price && (
        <div>
          <span className={styles.title}>
            {period && t(`web_offer_subscription_period_${period}`)}:
          </span>
          <span className={styles.price}>{price && ` ${price}`}</span>
          <span className={styles.description}>
            {currency && `${currency}.${t(`web_payment_period_${period}`)}`}
          </span>
        </div>
      )}
    </div>
  );
};
