import { useCallback, useEffect } from "react";
import {
  DeviceHelper,
  eventService,
  IAppState,
  userProfileHelper,
  useSelector,
} from "@nf/common";
import { ROUTES } from "@nf/constants";
import {
  useReplacePage,
  useSwitchProfileWithRedirection,
  useSetCurrentSubProfile,
  useRedirectToProfileScreen,
  useGeneratePath,
} from "../../../hooks";
import { useRouterRedirectionParams } from "./useRouterRedirectionParams";
import { useUserSubscriptions } from "../../Settings/SettingsSubscription/hooks";
import {
  MOBILE_PARAM_SUBSCRIPTION_VALUE,
  MOBILE_PARAM_SUBSCRIPTIONS,
} from "../../../screens/WelcomeScreen/WelcomeScreen";

export const useRedirectOnSignIn = () => {
  const { subprofiles } = useSelector((state: IAppState) => state.user);
  const redirectTo = useReplacePage();
  const userRedirect = useSwitchProfileWithRedirection();
  const redirectUrl = useRouterRedirectionParams();
  const generatePath = useGeneratePath();

  const subProfiles = subprofiles?.Data?.subprofiles;
  const hasPinCode = subprofiles?.Data?.auth?.PinCodeId;
  const hasCurrentSubProfile = subprofiles?.Data?.currentSubprofile;
  const action = useSelector((state: IAppState) => state.user.action);
  const { hasActiveSubscriptions } = useUserSubscriptions();

  const setCurrentProfile = useSetCurrentSubProfile();

  const redirectToProfileSelectionScreen = useRedirectToProfileScreen();

  const isMobile = useCallback(() => DeviceHelper.isMobile(), []);

  useEffect(() => {
    const subscription = eventService.onSignInSuccessEvent(() => {
      if (isMobile()) {
        redirectTo(
          hasActiveSubscriptions
            ? {
                pathname: generatePath(ROUTES.WELCOME),
                query: {
                  [MOBILE_PARAM_SUBSCRIPTIONS]: MOBILE_PARAM_SUBSCRIPTION_VALUE,
                },
              }
            : {
                pathname: generatePath(ROUTES.PAYMENT_SUBSCRIPTION),
              }
        );
        return;
      }

      if (redirectUrl) {
        const owner = userProfileHelper.findMainProfile(subProfiles);
        if (owner && !hasPinCode) {
          setCurrentProfile(owner).then(() => {
            redirectTo(redirectUrl);
          });
        } else {
          redirectToProfileSelectionScreen();
        }
        return;
      }

      if (subProfiles?.length && subProfiles.length > 1) {
        redirectToProfileSelectionScreen();
      }

      if (subProfiles?.length && subProfiles.length === 1) {
        const initialHomePage = userProfileHelper.getInitialHomePage(
          subProfiles[0]
        );

        setCurrentProfile(subProfiles[0]).then(() => {
          redirectTo(userProfileHelper.cleanUp(initialHomePage) as string);
        });
      }
    });
    return () => {
      eventService.unsubscribe(subscription);
    };
  }, [
    action?.type,
    hasPinCode,
    subProfiles,
    hasCurrentSubProfile,
    redirectTo,
    userRedirect,
    redirectUrl,
    hasActiveSubscriptions,
    isMobile,
  ]);
};
