import React, { useCallback, useState } from "react";
import ImageNext from "next/image";
import { isServerSideRender } from "@nf/helpers";
import { ImageProps } from "next/dist/client/image";
import { InView } from "react-intersection-observer";
import { useImageWorker } from "./useImageWorker";

const LOW_QUALITY_VALUE = 20;
const DEFAULT_QUALITY_SCORE = 90;
const WIDTH = "w";
const HEIGHT = "h";
const QUALITY = "q";

export interface IAppImage extends ImageProps {
  isLCP?: boolean;
  onlyWidth?: boolean;
  onlyHeight?: boolean;
  useOriginal?: boolean;
}

const roundValue = (value: string | number): string =>
  Math.round(parseInt(value.toString())).toString();

const AppImageRaw = ({
  src,
  isLCP = false,
  onlyWidth = false,
  onlyHeight = false,
  useOriginal = false,
  width,
  height,
  ...props
}: IAppImage) => {
  const [isInView, setIsInView] = useState(false);
  const workerImage = useImageWorker(!useOriginal ? isInView : false, src);

  const onChange = useCallback((isInView: boolean) => {
    isInView && setIsInView(true);
  }, []);

  const generateUrl = (
    src: string,
    width: ImageProps["height"],
    height: ImageProps["width"],
    onlyWidth: boolean,
    onlyHeight: boolean
  ): string => {
    try {
      const url = new URL(src);
      const params = url.searchParams;

      if (width) {
        params.set(WIDTH, roundValue(width));
      }

      if (height) {
        params.set(HEIGHT, roundValue(height));
      }

      if (onlyWidth) {
        params.delete(HEIGHT);
      }

      if (onlyHeight) {
        params.delete(WIDTH);
      }

      params.set(QUALITY, DEFAULT_QUALITY_SCORE.toString());

      return url.toString();
    } catch (e) {
      return src;
    }
  };

  const fitImageSrc =
    typeof src === "string"
      ? generateUrl(src, width, height, onlyWidth, onlyHeight)
      : undefined;

  if (isServerSideRender && typeof src === "string") {
    return (
      <ImageNext
        src={fitImageSrc ?? src}
        {...props}
        quality={LOW_QUALITY_VALUE}
      />
    );
  }

  const destinationSource = useOriginal ? src : workerImage ?? fitImageSrc;

  return typeof src === "string" && destinationSource ? (
    <InView onChange={onChange} className={props.className}>
      <ImageNext
        src={destinationSource}
        {...props}
        layout="fill"
        priority={isLCP}
        quality={DEFAULT_QUALITY_SCORE}
      />
    </InView>
  ) : null;
};

export const AppImage = React.memo(AppImageRaw);

export default AppImage;
