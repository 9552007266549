/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React, { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { Dialog } from "components";
import { SettingsSubscriptionButton } from "../../../SettingsSubscriptionButton/SettingsSubscriptionButton";
import { DialogContentWrapper } from "../DialogContentWrapper";
import { CancelSubscriptionForm, PauseSubscriptionForm } from "./components";

import styles from "./PauseOrCancelSubscriptionDialog.module.scss";

export interface IPauseOrCancelSubscriptionDialogProps {
  endDate: string;
  id: number;
  isFormSent: boolean;
  setIsFormSent: (value: boolean) => void;
  children?: React.ReactNode;
  isLoading?: boolean;
  setIsPauseFormSent: (value: boolean) => void;
  offerId: number;
}

const OFFER_ID_EXCLUDED_FROM_CANCELING = [121, 130, 131, 2, 3];

export const PauseOrCancelSubscriptionDialog = ({
  endDate,
  id,
  isLoading,
  isFormSent,
  setIsFormSent,
  setIsPauseFormSent,
  offerId,
}: IPauseOrCancelSubscriptionDialogProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation("translations");

  const handleClick = () => {
    setIsVisible((prevState) => !prevState);
  };

  useEffect(() => {
    if (isFormSent && !isLoading) setIsVisible(false);
  }, [isFormSent, isLoading]);

  /*
    TODO Pausing should be disabled until payment provider is changed.
    Reenable when it is done.
  */
  // const cantPauseSubscription =
  //   OFFER_ID_EXCLUDED_FROM_CANCELING.includes(offerId);
  const cantPauseSubscription = true;

  return (
    <div className={styles.ContentContainer}>
      <div>
        <SettingsSubscriptionButton
          variant="plain"
          type="submit"
          onClick={isVisible ? undefined : handleClick}
        >
          {t("web_subscription_button_cancel")}
        </SettingsSubscriptionButton>
      </div>
      <Dialog
        wrapClassName={styles.DialogWrap}
        className={styles.DialogContainer}
        destroyOnClose={true}
        footer={null}
        visible={isVisible}
        onCancel={handleClick}
      >
        <DialogContentWrapper
          title={
            cantPauseSubscription
              ? t("web_subscription_cancel_header")
              : t("web_subscription_pause_or_cancel_header")
          }
        >
          {!cantPauseSubscription && (
            <PauseSubscriptionForm
              endDate={endDate}
              setIsFormSent={setIsFormSent}
              setIsPauseFormSent={setIsPauseFormSent}
            />
          )}
          <CancelSubscriptionForm id={id} setIsFormSent={setIsFormSent} />
        </DialogContentWrapper>
      </Dialog>
    </div>
  );
};
