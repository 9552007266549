/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  IMediaModel,
  MediaStreamType,
  UrlHelper,
  useIsLoggedIn,
} from "@nf/common";
import { ROUTES } from "@nf/constants";
import React from "react";
import dynamic from "next/dynamic";

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

const MediaButton = dynamic(() => import("components/MediaButton"));

import PlayIcon from "../../resources/icons/play.svg";

export interface ITrailerButtonProps {
  media?: IMediaModel;
  loading?: boolean;
}

export const TrailerButton = ({ media }: ITrailerButtonProps) => {
  const isLoggedIn = useIsLoggedIn();

  if (!media || !media.TrailerMediaId || !isLoggedIn) {
    return null;
  }

  const urlToTrial = UrlHelper.parametrize(
    `${ROUTES.PLAYER_SCREEN}/${media.TrailerMediaId}`,
    { streamType: MediaStreamType.Trial }
  );

  return (
    <LinkWithLocale href={urlToTrial}>
      <a>
        <MediaButton
          circular
          icon={<PlayIcon />}
          tabIndex={-1}
          variant="trailer"
        />
      </a>
    </LinkWithLocale>
  );
};
