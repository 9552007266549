import { Dialog } from "../../../components/Dialog";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";

export const MediaDetailsError = () => {
  const { t } = useTranslation("translations");
  const router = useRouter();
  const [isDisplayed, setIsDisplayer] = useState(true);

  useEffect(() => {
    if (!isDisplayed) router.back();
  }, [isDisplayed]);

  return (
    <Dialog
      className="dialog"
      destroyOnClose={true}
      footer={null}
      visible={isDisplayed}
      onCancel={() => setIsDisplayer(false)}
    >
      <h1 className="dialogTitle">{t("web_generic_error_header")}</h1>
      <p className="dialogText">{t("web_generic_error_text")}</p>
    </Dialog>
  );
};

export default MediaDetailsError;
