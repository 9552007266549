import { DataProvider, IErrorModel, useDataLoader } from "@nf/common";

export const useGetInvoicePDF = (invoiceId?: string) => {
  return useDataLoader<string, IErrorModel | undefined>({
    loader: () => {
      if (!invoiceId) {
        return Promise.resolve({
          ok: false,
          error: undefined,
        });
      }

      return DataProvider.getInvoicePDF(invoiceId)
        .then((data) => {
          return {
            ok: true,
            data: data,
            error: undefined,
          };
        })
        .catch((response) => {
          return {
            ok: false,
            error: response.Message,
          };
        });
    },
    deps: [invoiceId],
  });
};
