/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useCallback, useEffect, useState } from "react";
import {
  ApiErrors,
  AuthStore,
  buildEmailRule,
  buildPasswordMaximumRule,
  buildPasswordMinimumRule,
  DeviceHelper,
  dispatch,
  IFormValues,
  offeringIdsService,
  StorageHelper,
  updateApiErrors,
  useAuthSelector,
  UserStore,
} from "@nf/common";
import { ROUTES } from "@nf/constants";
import { googleAnalyticsEvent } from "../../../lib/gtm";

import { useIsLoading } from "helpers/setIsLoadingContext";
import { Trans, useTranslation } from "next-i18next";

import { useRouter } from "next/router";
import cx from "classnames";
import authFormStyles from "../AuthForm.module.scss";
import styles from "./LoginForm.module.scss";

import dynamic from "next/dynamic";

import {
  CUSTOMER_ALREADY_EXISTS_PARAM,
  CUSTOMER_ALREADY_VALIDATED_PARAM,
} from "constants/param";

import { Input } from "components/Inputs/Input";

import { InputPassword } from "components/Inputs/InputPassword";
import { LabelField } from "components/Form/LabelField";
import { FormButton } from "components/Form/FormButton";
import { useForgotPasswordLink, useSignUpUrl } from "../../../hooks";
import { useRedirectOnSignIn, useRedirectForLoggedInUsers } from "../hooks";
import axios from "axios";
import { captureMessage } from "@sentry/nextjs";
import { catchError, from, map, switchMap, tap } from "rxjs";

const CommonTextError = dynamic(() => import("components/CommonTextError"));

const LinkText = dynamic(() => import("components/LinkText"));

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

const Form = dynamic<IFormValues>(() => import("components/Form/Form"));

interface ILoginFormValues {
  Username: string;
  Password: string;
}

const EMAIL_FIELD = "email";
const PASSWORD_FIELD = "password";

const FORM_FIELDS = [EMAIL_FIELD, PASSWORD_FIELD];

export const ForgotPasswordLink = () => {
  const { t } = useTranslation("translations");
  const forgotPasswordLink = useForgotPasswordLink();
  return (
    <LinkWithLocale href={forgotPasswordLink}>
      <a className={`${styles.resetPassword} text-bold text-small`}>
        <Trans i18nKey={t("web_login_password_forgot", "")} />
      </a>
    </LinkWithLocale>
  );
};

export const LoginForm = () => {
  const router = useRouter();
  const { setIsLoading } = useIsLoading();
  const { t } = useTranslation("translations");
  const [isFormSent, setIsFormSent] = useState(false);
  const [apiErrors, setApiErrors] = useState<ApiErrors>({});
  const { isLoading, error } = useAuthSelector();
  const [autoCompleted, setAutoCompleted] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState<string | null>(null);
  const { email, message } = router.query;
  const isUserAlreadyExist = message?.includes(CUSTOMER_ALREADY_EXISTS_PARAM);
  const isUserAlreadyValidated = message?.includes(
    CUSTOMER_ALREADY_VALIDATED_PARAM
  );
  const [userEmail, setUserEmail] = useState<string>("");

  useRedirectOnSignIn();
  useRedirectForLoggedInUsers();

  const initialValues = {
    email: userEmail,
  };

  const onFinish = (values: IFormValues) => {
    const payload: ILoginFormValues = {
      Username: values[EMAIL_FIELD],
      Password: values[PASSWORD_FIELD],
    };

    const $signInObserver = from(DeviceHelper.getDeviceInfo()).pipe(
      tap(() => {
        setIsLoading(true);
        setIsFormSent(true);
      }),
      map((deviceInfo) => ({ ...payload, Device: deviceInfo })),
      switchMap((payload) =>
        from(axios.post(ROUTES.API_SIGN_IN, payload)).pipe(
          map(({ data }) => data)
        )
      ),
      catchError((e) => {
        console.error(e);
        return from(Promise.reject(e.response.data));
      })
    );

    const $subscription = $signInObserver.subscribe({
      next: (data) => {
        StorageHelper.setSession(data.authResponse.AuthorizationToken);
        StorageHelper.setUser(data.authResponse.User);
        googleAnalyticsEvent.login();
        dispatch(
          AuthStore.Actions.signInWebSuccess(data.authResponse.User, {
            ...data.authResponse.AuthorizationToken,
            IsOverTheDeviceLimit: data.session.overTheDeviceLimit,
          })
        );
        dispatch(AuthStore.Actions.getSessionDetailsSuccess(data.session));
        dispatch(
          UserStore.Actions.getUserSubscriptionsSuccess(data.subscriptions)
        );
        dispatch(
          UserStore.Actions.getUserSubprofilesSuccess(
            data.subProfiles,
            data.currentSubProfile,
            data?.pinCode
          )
        );
        dispatch(
          UserStore.Actions.getUserAvailabilityKeysSuccess(
            data.availabilityKeys
          )
        );
        dispatch(UserStore.Actions.getUserFavorites());
        data.products && offeringIdsService.setOfferingIds(data.products);
        StorageHelper.setTvRefreshTokens(data.tvRefreshToken);
      },
      error: ({ e }: any) => {
        setIsLoading(false);
        setIsFormSent(false);
        if (e?.statusCode !== 401) {
          captureMessage("login error", {
            level: "error",
            tags: {
              error: JSON.stringify(e),
            },
          });
        }
        setGlobalErrorMessage("WRONG_EMAIL_OR_PASSWORD");
        $subscription.unsubscribe();
      },
      complete: () => {
        $subscription.unsubscribe();
      },
    });
  };

  const setGlobalErrorMessage = useCallback(
    (error: string | null) => {
      if (error === "WRONG_EMAIL_OR_PASSWORD") {
        setErrorDisplay("web_login_failed_error");
      } else {
        setErrorDisplay(error);
      }
      setApiErrors({
        ...apiErrors,
        Login: [error || ""],
      });
    },
    [apiErrors]
  );

  const onValuesChange = (changedValues: IFormValues) => {
    const [isUpdated, newApiErrors] = updateApiErrors(apiErrors, changedValues);

    if (isUpdated) setApiErrors(newApiErrors);

    setApiErrors({
      ...apiErrors,
      Login: [""],
    });
    isFormSent && setIsFormSent(false);
  };

  useEffect(() => {
    if (email && (isUserAlreadyExist || isUserAlreadyValidated)) {
      if (typeof email === "string") {
        setUserEmail(email.toString());
      } else {
        setUserEmail(email[0].toString());
      }
    }
  }, [email, message]);

  useEffect(() => {
    if (error && isFormSent) {
      setGlobalErrorMessage(error.Message as string);
      setIsLoading(false);
    }
  }, [error, isFormSent]);

  const checkIfAutoCompleted = () => setAutoCompleted(true);
  const signUpUrl = useSignUpUrl();

  return (
    <div className={cx(authFormStyles.form, styles.container)}>
      <Form
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <h1 className={styles.title}>{t("web_login_header", "")}</h1>

        {isUserAlreadyExist && (
          <p className={styles.userExist}>{t("web_user_exist", "")}</p>
        )}

        {isUserAlreadyValidated && (
          <p className={styles.userValidated}>
            {t("web_user_already_validated")}
          </p>
        )}

        <LabelField
          className={styles.labelFieldContainer}
          name="email"
          label={t("web_login_email_header", "")}
          rules={[buildEmailRule(t)]}
          apiErrors={apiErrors.email || []}
          validateTrigger={["onBlur", "onSubmit"]}
        >
          <Input
            autoFocus
            spellCheck="false"
            trimValue
            placeholder={t("web_login_email_field", "")}
            className={authFormStyles.inputContainer}
            checkIfAutoCompleted={checkIfAutoCompleted}
            inputClassName={authFormStyles.input}
          />
        </LabelField>

        <LabelField
          className={styles.labelFieldContainer}
          name="password"
          label={t("web_login_password_header", "")}
          rules={[buildPasswordMinimumRule(t), buildPasswordMaximumRule(t)]}
          tip={<ForgotPasswordLink />}
          validateTrigger={["onBlur", "onSubmit"]}
        >
          <InputPassword
            spellCheck="false"
            placeholder={t("web_login_password_field", "")}
            className={authFormStyles.inputContainer}
            checkIfAutoCompleted={checkIfAutoCompleted}
            inputClassName={authFormStyles.input}
            visibilityToggle
          />
        </LabelField>

        <div className={styles.buttonWrapper}>
          <FormButton
            nonEmptyFields={{ requiredFields: FORM_FIELDS, autoCompleted }}
            loading={isLoading}
            disabled={isLoading || isFormSent}
            loadingAnimationType="dots"
            hideButtonTitle={isLoading}
          >
            {t("web_login_button", "")}
          </FormButton>
        </div>
        {errorDisplay && !isLoading && (
          <CommonTextError centered translationKey={errorDisplay || ""} />
        )}
        <p className="text-center text-small">
          {t("web_login_create_account", "")}
          <LinkText bold href={signUpUrl}>
            {t("web_create_account_link", "")}
          </LinkText>
        </p>
      </Form>
    </div>
  );
};

export default LoginForm;
