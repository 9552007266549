import React from "react";
import styles from "./LandingPageDevicesList.module.scss";
import dynamic from "next/dynamic";
import TvIcon from "../../.././../resources/landingPage/tv.svg";
import Computer from "../../.././../resources/landingPage/computer.svg";
import MobileTablet from "../../.././../resources/landingPage/mobile-tablet.svg";
import ConnectedTV from "../../.././../resources/landingPage/connectedTV.svg";
import { ITextComponentModel } from "../../../../../../common/src/models/Configuration/ITextComponentModel";

const SectionContainer = dynamic(() => import("../SectionContainer"));

const Icon = ({ title }: { title: string }) => {
  switch (title.toLowerCase()) {
    case "tv":
      return (
        <div style={{ height: "90px", width: "195px" }}>
          {" "}
          <TvIcon />
        </div>
      );
    case "medieafspiller":
      return (
        <div style={{ height: "100px", width: "195px" }}>
          <ConnectedTV />
        </div>
      );
    case "computer":
      return (
        <div style={{ height: "90px", width: "107px" }}>
          <Computer />
        </div>
      );
    case "mobil & tablet":
      return (
        <div style={{ height: "90px", width: "104px" }}>
          <MobileTablet height={100} />
        </div>
      );

    default:
      return null;
  }
};

type TLandingPageDevicesList = {
  component: ITextComponentModel;
};

type TDeviceList = {
  title: string;
  list: string[];
};

export const LandingPageDevicesList: React.FC<TLandingPageDevicesList> = ({
  component,
}) => {
  const devicesList = component.TextParsed
    ? (component.TextParsed as unknown as TDeviceList[])
    : [];

  return (
    <SectionContainer title={component.Title} includePadding>
      <div className={styles.list}>
        <div className={styles.row}>
          {devicesList.map((element, index) => (
            <div
              key={`${index}-${element.title}`}
              className={styles.elementContainer}
            >
              <div className={styles.icon}>
                <Icon title={element.title} />
              </div>
              <div className={styles.title}>{element.title}</div>
              <div>
                {element?.list?.map((item, index) => (
                  <div key={`${index}--`} className={styles.deviceName}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};
