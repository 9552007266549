import { IUserSubscriptionModel } from "@nf/common";
import { isSubscriptionHold } from "../../../../helpers";
import { useMemo } from "react";

type TPreviousSubscriptions = {
  previousSubscriptions: undefined | IUserSubscriptionModel[];
  hasPreviousSubscriptions: boolean;
};

export const usePreviousSubscriptions = (
  subscriptions?: IUserSubscriptionModel[]
): TPreviousSubscriptions => {
  const previousSubscriptions = useMemo(
    () =>
      subscriptions && subscriptions.length > 0
        ? subscriptions.filter(
            ({ IsActive, SubscriptionStatus }) =>
              !IsActive && !isSubscriptionHold(SubscriptionStatus)
          )
        : undefined,
    [subscriptions]
  );

  const hasPreviousSubscriptions =
    (previousSubscriptions && previousSubscriptions.length > 0) || false;

  return {
    previousSubscriptions,
    hasPreviousSubscriptions,
  };
};
