import { SettingsSubscriptionContainer } from "../SettingsSubscriptionContainer";
import { useTranslation } from "next-i18next";
import { IUserSubscriptionModel } from "@nf/common";
import { useFormContext } from "../PaymentFormProvider/PaymentFormProvider";

type TPreviousSubscriptionContainerProps = {
  hasActiveSub: boolean;
  previousSubscriptions: IUserSubscriptionModel[];
  setIsPauseFormSent: (value: boolean) => void;
};

export const PreviousSubscriptionContainer = ({
  hasActiveSub,
  previousSubscriptions,
  setIsPauseFormSent,
}: TPreviousSubscriptionContainerProps) => {
  const { isFormSent, setIsFormSent, isDataLoading } = useFormContext();
  const { t } = useTranslation("translations");

  return (
    <>
      <h2>{t("web_subscription_previous")}</h2>
      {previousSubscriptions.map((sub) => {
        return (
          <SettingsSubscriptionContainer
            key={sub.Id}
            period={sub.Period}
            subscription={sub}
            isActive={sub.IsActive}
            isLoading={isDataLoading}
            isFormSent={isFormSent}
            setIsFormSent={setIsFormSent}
            setIsPauseFormSent={setIsPauseFormSent}
            hasActiveSub={hasActiveSub}
            subscriptionStatus={sub.SubscriptionStatus}
          />
        );
      })}
    </>
  );
};
