/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { CellType } from "@nf/common";
import { useMemo } from "react";

interface ITileEntry {
  isDesktopSize: boolean;
  isHover: boolean;
  gridColumns: number | undefined;
  frameRef: React.RefObject<HTMLDivElement>;
  cellType: CellType | undefined;
  resize: { width: number; height: number };
}
export const calcTilePosition = (
  ref: HTMLDivElement,
  gridColumns: number | undefined,
  width: number,
  height: number
) => {
  let positionTop =
    ref &&
    ref.getBoundingClientRect().top +
      window.scrollY +
      (ref.clientHeight - height) / 2;
  positionTop = !gridColumns ? positionTop - 4 : positionTop;

  const positionLeft =
    ref && ref.getBoundingClientRect().left + (ref.clientWidth - width) / 2;

  const positionRight = ref && ref.getBoundingClientRect().right;

  return { top: positionTop, left: positionLeft, right: positionRight };
};

export const useTileEntry = ({
  isDesktopSize,
  isHover,
  gridColumns,
  frameRef,
  cellType,
  resize,
}: ITileEntry) => {
  const ref = frameRef.current;
  const dimension = useMemo(() => {
    if (!isDesktopSize) return;
    if (!isHover) return;
    if (ref) {
      const refSize = {
        width: ref.clientWidth * resize.width,
        height: ref.clientHeight * resize.height,
      };
      return refSize;
    }
  }, [isDesktopSize, isHover, frameRef.current, cellType, resize]);

  if (!isDesktopSize) return;
  if (!isHover) return;
  const position =
    dimension &&
    ref &&
    calcTilePosition(ref, gridColumns, dimension.width, dimension.height);

  return { dimension, position };
};
