/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { AuthStore, IAppState, IChangePasswordModel } from "@nf/common";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  IPasswordFormDispatchProps,
  IPasswordFormStateProps,
  PasswordForm as PasswordFormDefinition,
} from "./PasswordForm";

const mapStateToProps = (state: IAppState) => {
  return {
    action: state.auth.action,
    stateAuth: state.auth ?? {},
    isLoading: state.auth.isLoading,
    changePasswordSuccess: state.auth.changePasswordSuccess,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changePassword: (data: IChangePasswordModel) => {
    return dispatch(AuthStore.Actions.changePassword(data));
  },
  clearChangePasswordSuccessAction: () => {
    return dispatch(AuthStore.Actions.clearChangePasswordSuccess());
  },
});

export const PasswordForm = connect<
  IPasswordFormStateProps,
  IPasswordFormDispatchProps,
  Record<string, never>,
  IAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation("translations")(PasswordFormDefinition));
