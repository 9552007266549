import { IUserSubscriptionModel } from "@nf/common";
import { useCurrentSubscription, usePreviousSubscriptions } from "../../hooks";
import { CurrentSubscriptionContainer } from "../CurrentSubscriptionContainer/CurrentSubscriptionContainer";
import { PreviousSubscriptionContainer } from "../PreviousSubscriptionContainer/PreviousSubscriptionContainer";

type TActiveSubscriptionsContainerProps = {
  subscriptions: IUserSubscriptionModel[];
  setIsPauseFormSent: (value: boolean) => void;
};

export const ActiveSubscriptionsContainer = ({
  subscriptions,
  setIsPauseFormSent,
}: TActiveSubscriptionsContainerProps): JSX.Element => {
  const { currentSubscriptions, hasCurrentSubscriptions } =
    useCurrentSubscription(subscriptions);
  const { previousSubscriptions, hasPreviousSubscriptions } =
    usePreviousSubscriptions(subscriptions);

  return (
    <>
      {hasCurrentSubscriptions && (
        <CurrentSubscriptionContainer
          setIsPauseFormSent={setIsPauseFormSent}
          currentSubscriptions={
            currentSubscriptions as IUserSubscriptionModel[]
          }
          hasActiveSub={true}
        />
      )}
      {hasPreviousSubscriptions && (
        <PreviousSubscriptionContainer
          setIsPauseFormSent={setIsPauseFormSent}
          hasActiveSub={hasCurrentSubscriptions}
          previousSubscriptions={
            previousSubscriptions as IUserSubscriptionModel[]
          }
        />
      )}
    </>
  );
};
