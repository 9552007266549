/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  ApiErrors,
  buildEmailRule,
  buildNameMaxLengthRule,
  IFormValues,
  isShallowEqual,
  IUserModel,
  updateApiErrors,
  useConsentSelector,
  useDispatch,
  UserConsentsNames,
  UserStore,
  useUserSelector,
} from "@nf/common";
import { EXTERNAL_LINKS } from "@nf/constants";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "next-i18next";
import cx from "classnames";

import dynamic from "next/dynamic";

const ActionsHandler = dynamic(() => import("components/ActionsHandler"));

const Checkbox = dynamic(() => import("components/Checkbox"));

const CommonTextError = dynamic(() => import("components/CommonTextError"));

const LinkText = dynamic(() => import("components/LinkText"));

const PersonalInformationInfo = dynamic(
  () => import("components/Form/PersonalInformationInfo")
);

const PhoneDropdown = dynamic(() => import("components/PhoneDropdown"));

import { Form, FormButton, LabelField } from "../../../Form";
import { Input } from "../../../Inputs";

import styles from "./ContactsForm.module.scss";

interface IContactFormPrefix {
  className?: string;
  onChange?: (prefix: string | number) => void;
  value?: string;
}

const FORM_FIELDS = ["firstName", "lastName", "email"];

const PrefixField = ({
  className,
  onChange,
  value,
  ...props
}: IContactFormPrefix) => {
  return (
    <div className={styles.phoneFormContainer}>
      <PhoneDropdown
        value={value ? value.slice(0, 3) : undefined}
        onChange={(prefix: string | number) =>
          onChange?.(`${prefix}${value?.slice(3, value.length)}`)
        }
      />
      <Input
        className={className}
        value={value ? value.slice(3, value.length) : undefined}
        onChange={(e) => {
          !Number.isNaN(+e.target.value) &&
            onChange?.(`${value?.slice(0, 3)}${e.target.value}`);
        }}
        {...props}
      />
    </div>
  );
};

export const ContactsForm = () => {
  const { t } = useTranslation("translations");
  const {
    profile: { Data: user, Error: userError, IsProcessing: isProfileLoading },
  } = useUserSelector();
  const {
    consent,
    isLoading: isConsentLoading,
    error: consentError,
  } = useConsentSelector(UserConsentsNames.Marketing);
  const dispatch = useDispatch();
  const [isFormSent, setIsFormSent] = useState(false);
  const [apiErrors, setApiErrors] = useState<ApiErrors>({});
  const [valuesChanged, setValuesChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [form] = Form.useForm();
  const userPhone = user?.PhoneNumber;

  const error = userError ? userError : consentError;

  const initialValues: IFormValues = {
    email: user?.Email,
    firstName: user?.FirstName,
    lastName: user?.LastName,
    phone: userPhone,
  };

  const onValuesChange = (changedValues: IFormValues, values: IFormValues) => {
    const [isUpdated, newApiErrors] = updateApiErrors(apiErrors, changedValues);

    if (values.hasOwnProperty("consent")) {
      initialValues.consent = consent?.Accepted;
    }

    if (isUpdated) {
      setApiErrors(newApiErrors);
    }

    setApiErrors({
      ...apiErrors,
      UpdateProfile: [""],
    });

    checkIfChanged(!isShallowEqual(initialValues, values));

    if (Object.keys(changedValues)[0] === "email") {
      setEmailChanged(changedValues["email"] !== user?.Email);
    }
    isFormSent && setIsFormSent(false);
  };

  const checkIfChanged = (changed: boolean) =>
    changed ? setValuesChanged(true) : setValuesChanged(false);

  const onFinish = (values: IFormValues) => {
    const userPayload: Partial<IUserModel> = {
      FirstName: values["firstName"],
      LastName: values["lastName"],
      PhoneNumber: `${values["phone"]}`,
      Email: values["email"] !== user?.Email ? values["email"] : undefined,
    };

    if (consent) {
      const consentPayload = [
        {
          ...consent,
          Accepted: !!(values["consent"] ?? consent.Accepted),
          NeedUpdate: false,
        },
      ];
      dispatch(UserStore.Actions.updateUserConsents(consentPayload));
    }

    dispatch(UserStore.Actions.updateProfile(userPayload));
    setIsFormSent(true);
  };

  useEffect(() => {
    if (isFormSent && error) {
      setApiErrors({
        ...apiErrors,
        UpdateProfile: [error.Message as string],
      });
    }
  }, [error, isFormSent]);

  useEffect(() => {
    if (!user) dispatch(UserStore.Actions.getProfile());
  }, []);

  useEffect(() => {
    if (consent) {
      form.setFieldsValue({ consent: consent?.Accepted });
    }
  }, [consent]);

  const isLoading = isProfileLoading || isConsentLoading;
  const fetchedData = !isLoading || user;
  const isApiError =
    apiErrors.UpdateProfile && !!apiErrors.UpdateProfile[0]?.length;

  return (
    <ActionsHandler
      isLoading={isLoading && !fetchedData}
      error={!fetchedData || !user ? error : undefined}
      errorTranslationKey="web_my_account_error"
    >
      <>
        {user && (
          <Form
            form={form}
            className={styles.contactsForm}
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <div className={styles.nameInputs}>
              <LabelField
                className={styles.conatctsLabel}
                label={t("web_contacts_form_name_label", "")}
                name="firstName"
                rules={[buildNameMaxLengthRule()]}
                validateTrigger={["onBlur", "onSubmit"]}
              >
                <Input className="FormInput" />
              </LabelField>
              <LabelField
                name="lastName"
                label={"\u00A0"}
                rules={[buildNameMaxLengthRule()]}
                validateTrigger={["onBlur", "onSubmit"]}
              >
                <Input className="FormInput" />
              </LabelField>
            </div>
            <LabelField
              label={t("web_contacts_form_email_label", "")}
              name="email"
              tip={
                emailChanged ? t("web_contacts_form_email_tip", "") : undefined
              }
              rules={[buildEmailRule(t)]}
              validateTrigger={["onBlur", "onSubmit"]}
            >
              <Input className={cx({ [styles.emailChanged]: emailChanged })} />
            </LabelField>

            <LabelField
              className={styles.phoneForm}
              name="phone"
              tip={t("web_contacts_form_phone_tip", "")}
              label={t("web_contacts_form_phone_label", "")}
              validateTrigger={["onBlur", "onSubmit"]}
            >
              <PrefixField />
            </LabelField>
            {/* <LabelField FIX ME- WHEN BIRTHDAY WILL BE AVAIlABLE
        name="birthday"
      >
        <Input className="FormInput" />
      </LabelField> */}
            {consent && (
              <div className={styles.consent}>
                <Form.Field name="consent">
                  {({ onChange }) => (
                    <Checkbox
                      onChange={(e: any) => {
                        onChange((e.target as HTMLInputElement).checked);
                      }}
                      defaultChecked={consent.Accepted}
                    />
                  )}
                </Form.Field>
                <div className={styles.consentTextContainer}>
                  <Trans
                    i18nKey={t("web_consent_marketing_label", "")}
                    components={{
                      1: <p className={styles.consentText} />,
                      2: <LinkText href={EXTERNAL_LINKS.MARKETING_POLICY} />,
                      bold: <strong />,
                    }}
                  />
                </div>
              </div>
            )}
            <FormButton
              nonEmptyFields={{
                requiredFields: FORM_FIELDS,
                autoCompleted: false,
              }}
              disabled={!valuesChanged || isFormSent}
              loading={isLoading}
            >
              {t("web_contacts_form_submit", "")}
            </FormButton>
            {!isLoading && isApiError && (
              <CommonTextError error={error} centered />
            )}

            {emailChanged && isFormSent && !isApiError && !isLoading && (
              <div className={styles.textContainer}>
                <Trans
                  i18nKey={t("web_contacts_form_email_change", "")}
                  tOptions={{ defaultValue: "" }}
                  components={{ 1: <p /> }}
                />
              </div>
            )}
            <div className={styles.consent}>
              <div className={styles.textContainer}>
                <PersonalInformationInfo />
              </div>
            </div>
          </Form>
        )}
      </>
    </ActionsHandler>
  );
};
