/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { useTranslation } from "next-i18next";

import { DotsLoader } from "components/DotsLoader";

import styles from "./PaymentStatus.module.scss";

export const PaymentStatusLoading = () => {
  const { t } = useTranslation("translations");

  return (
    <div className={styles.status}>
      <p className={styles.info}>
        {t("web_payment_assigning_subscription_text")}
      </p>
      <DotsLoader width={120} />
    </div>
  );
};
