import {
  DataProvider,
  IErrorModel,
  IPaymentDetailsModel,
  useMutation,
} from "@nf/common";

export const useGetPaymentDetails = () => {
  return useMutation<IPaymentDetailsModel | undefined, IErrorModel>(() =>
    DataProvider.getPaymentDetails()
  );
};
