/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { IConfigurationModel, RouteHelper } from "@nf/common";
import React from "react";
import { useRouter } from "next/router";
import { NextSeo } from "next-seo";
import { useTranslation } from "next-i18next";
import {
  fallbackPageMetadata,
  PageMetadataKeys,
  pageMetaDataTranslationKeys,
} from "./pageMetadata";
import { ROUTES } from "@nf/constants";
import Head from "next/head";
import StructuredData from "./StructuredData";
import getOrganizationSchema from "schema/getOrganizationSchema";

interface ISeoProps {
  screenKey: PageMetadataKeys;
  configuration?: IConfigurationModel;
}

export const SEO = ({ screenKey, configuration }: ISeoProps) => {
  const { t } = useTranslation("translations");
  const router = useRouter();
  const href = process.env.NEXT_PUBLIC_MAIN_DOMAIN + router.asPath;

  const isHomePage = ROUTES.HOME.includes(screenKey);
  const canonicalUrl = isHomePage
    ? RouteHelper.getMainCanonicalUrl()
    : RouteHelper.getCanonicalUrl(router.pathname, configuration);

  const title = t(
    pageMetaDataTranslationKeys?.[screenKey]?.title,
    fallbackPageMetadata.default.title
  );
  const description = t(
    pageMetaDataTranslationKeys?.[screenKey]?.description,
    fallbackPageMetadata.default.description
  );

  return (
    <>
      {isHomePage && (
        <Head>
          <StructuredData schemas={[getOrganizationSchema()]} />
        </Head>
      )}
      <NextSeo
        title={title}
        description={description}
        canonical={canonicalUrl}
        openGraph={{
          title: title,
          description,
          url: href,
          site_name: process.env.NEXT_PUBLIC_NAME,
        }}
      />
    </>
  );
};

export default SEO;
