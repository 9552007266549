/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { IMediaModel, RouteHelper, useCanWatchAuthContent } from "@nf/common";
import dynamic from "next/dynamic";

const OfferButton = dynamic(() => import("components/OfferButton"));

const MediaButton = dynamic(() => import("components/MediaButton"));

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

import PlayIcon from "resources/icons/play.svg";
import InfoIcon from "resources/icons/info.svg";
import styles from "./PlayButton.module.scss";

export interface IPlayButtonProps {
  media?: IMediaModel;
  playBtnStyles?: string;
  isStopPropagation?: boolean;
}

export const PlayButton = (props: IPlayButtonProps) => {
  const { media, playBtnStyles, isStopPropagation } = props;
  const { canWatch } = useCanWatchAuthContent(media);

  if (canWatch) {
    return (
      <>
        {media && (
          <>
            {media.IsPlayable && (
              <LinkWithLocale href={RouteHelper.goToPlayer(media)}>
                <a className={playBtnStyles}>
                  <MediaButton circular variant="primary" icon={<PlayIcon />} />
                </a>
              </LinkWithLocale>
            )}
            <LinkWithLocale href={RouteHelper.goToDetails(media)}>
              <a>
                <MediaButton
                  circular
                  icon={<InfoIcon />}
                  tabIndex={-1}
                  variant="plain"
                />
              </a>
            </LinkWithLocale>
          </>
        )}
      </>
    );
  }

  return (
    <OfferButton
      isStopPropagation={isStopPropagation}
      className={styles.buttonContainer}
    />
  );
};

export default PlayButton;
