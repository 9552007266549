/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React from "react";
import { NextSeo } from "next-seo";

export type GenreSEOProp = {
  activeCategoryName: string;
};

export const GenreSEO = ({ activeCategoryName }: GenreSEOProp) => {
  const seoTitle = `${process.env.NEXT_PUBLIC_NAME} | ${activeCategoryName}`;

  return (
    <NextSeo
      title={seoTitle}
      openGraph={{
        title: seoTitle,
      }}
    />
  );
};

export default GenreSEO;
