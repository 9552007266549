import { TimeHelper } from "@nf/common";
import { OpenGraphVideo } from "next-seo/lib/types";

interface OpenGraphVideoData {
  actors?: string[];
  directors?: string[];
  categories?: string[];
  duration?: number;
  availableFrom?: Date;
}

const getOpenGraphVideo = ({
  actors,
  directors,
  categories,
  duration,
  availableFrom,
}: OpenGraphVideoData): OpenGraphVideo => {
  const video: OpenGraphVideo = {};

  if (actors?.length) {
    video.actors = actors?.map((actor) => ({
      profile: actor,
    }));
  }

  if (directors?.length) {
    video.directors = directors;
  }

  if (categories?.length) {
    video.tags = categories;
  }

  if (duration) {
    video.duration = duration;
  }

  if (availableFrom) {
    video.releaseDate = TimeHelper.toISOString(availableFrom);
  }

  return video;
};

export default getOpenGraphVideo;
