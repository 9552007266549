/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useCallback, useMemo, useState } from "react";
import {
  IMediaCategoryListModel,
  IMediaCategoryModel,
  MediaCategoryType,
  UrlHelper,
} from "@nf/common";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Select, Portal, Option, Backdrop } from "../..";
import styles from "../Drawers.module.scss";

export type MediaCategoryDrawerProps = {
  categories?: IMediaCategoryListModel;
  activeCategory: IMediaCategoryModel;
  type: "genre" | "categories";
};

export const MediaCategoryDrawer: React.FC<MediaCategoryDrawerProps> = ({
  categories: mediaCategories,
  activeCategory,
  type,
}) => {
  const router = useRouter();
  const params = router.query;

  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation("translations");

  const isMobileSize = window && window.innerWidth < 500;

  const options = useMemo(() => {
    return {
      dropdownStyle: { zIndex: 101, minHeight: "min-content" },
      style: { zIndex: 101 },
      dropdownMatchSelectWidth: isMobileSize ? 150 : 350,
      listHeight: isMobileSize ? 250 : 400,
      dropdownAlign: { offset: [0, 1] },
      className: styles.optionName,
    };
  }, [isMobileSize]);

  const onCategoryChange = useCallback(
    (categoryId?: string) => {
      router.push({
        search: UrlHelper.joinQueries(params, {
          id: categoryId,
          sort: params.sort,
        }),
      });
    },
    [params, router]
  );

  const renderOption = useMemo(() => {
    return mediaCategories?.Entities?.filter(
      (category) => category.CategoryName !== undefined
    )
      .filter(
        (category) =>
          (category.CategoryTypeCode === MediaCategoryType.Other &&
            type === "categories") ||
          (category.CategoryTypeCode === MediaCategoryType.Main &&
            type === "genre")
      )
      .map((category) => (
        <Option
          className={styles.optionName}
          key={category.CategoryId}
          value={category.CategoryId}
          id={category.CategoryId}
          title=""
        >
          {category.CategoryName}
        </Option>
      ));
  }, [params, mediaCategories?.Entities]);

  const selectedElement = useMemo(
    () => (
      <span className={styles.labelBox}>
        <span className={styles.labelSortName}>
          {t("web_select_categories")}
        </span>{" "}
        <h1 className={styles.labelCategoryOptionName}>
          {activeCategory.CategoryName}
        </h1>
      </span>
    ),
    [params, activeCategory]
  );

  return (
    <>
      <Portal>{isActive ? <Backdrop /> : <></>}</Portal>
      <div className={styles.drawers}>
        <Select
          {...options}
          onDropdownVisibleChange={(value: boolean) => setIsActive(value)}
          placeholder={t("web_select_categories")}
          value={selectedElement}
          onChange={(e: string | undefined) => onCategoryChange(e)}
        >
          {renderOption}
        </Select>
      </div>
    </>
  );
};

export default MediaCategoryDrawer;
