/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import * as React from "react";
import isEqual from "react-fast-compare";
import cx from "classnames";

import { ImageRatio } from "enums/ImageRatio";

import styles from "./ImageWithRatio.module.scss";
import { AppImage } from "../AppImage/AppImage";

export interface Props {
  imageSrc?: string; // source for the image which will be rendered once it is loaded
  placeholderSrc?: string;
  ratio?: ImageRatio;
  className?: string;
  contentClassName?: string;
  width?: number | string | null;
  height?: number | string | null;
  onClick?: () => void;
  index?: number;
  title?: string;
}

export interface State {
  width: number | string;
  height: number | string;
}

/*
 * Component which will display given image based od imageSrc. Until image is not loaded (ready to be rendered)
 * a placeholder image will be rendered
 */
export class ImageWithRatio extends React.Component<Props, State> {
  private width: number | string = 0;
  private height: number | string = 0;

  public state = {
    width: "100%",
    height: "100%",
    containerWidth: 0,
    containerHeight: 0,
  };

  private getContainerStyle = () => {
    let width: string | number = -1;

    if (this.props.width === null) {
      width = -1;
    } else if (this.props.width) {
      width = this.props.width;
    } else {
      width = this.state.width;
    }

    let height: string | number = -1;

    if (this.props.height === null) {
      height = -1;
    } else if (this.props.height) {
      height = this.props.height;
    } else {
      height = this.state.height;
    }

    const style: React.CSSProperties = {};
    if (width !== -1) {
      style.width = width;
    }

    if (height !== -1) {
      style.height = height;
    }
    return style;
  };

  private getRatioClassName = () => {
    const { ratio = ImageRatio?.ONE_ONE } = this.props;

    let className = styles.image;

    switch (ratio) {
      case ImageRatio?.ONE_ONE:
        className += " " + styles.ratio11;
        break;
      case ImageRatio?.SIXTEEN_NINE:
        className += " " + styles.ratio169;
        break;
      case ImageRatio?.SIXTEEN_SEVEN:
        className += " " + styles.ratio167;
        break;
      case ImageRatio?.SIXTEEN_SIX:
        className += " " + styles.ratio166;
        break;
      case ImageRatio?.SIXTEEN_FIVE:
        className += " " + styles.ratio165;
        break;
      case ImageRatio?.FOUR_THREE:
        className += " " + styles.ratio43;
        break;
      case ImageRatio?.THREE_TWO:
        className += " " + styles.ratio32;
        break;
      case ImageRatio?.EIGHT_FIVE:
        className += " " + styles.ratio85;
        break;
      case ImageRatio?.TWENTY_TWENTYTHREE:
        className += " " + styles.ratio2023;
        break;
      default:
        break;
    }

    return className;
  };

  public shouldComponentUpdate(nextProps: Props, nextState: State) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  private onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  onSize = (ref: HTMLDivElement | null) => {
    if (ref?.clientHeight) {
      this.setState((state) => ({
        ...state,
        containerHeight: ref?.clientHeight,
      }));
    }
    if (ref?.clientWidth) {
      this.setState((state) => ({
        ...state,
        containerWidth: ref?.clientWidth,
      }));
    }
  };

  public render() {
    const { children, className, contentClassName, title, index, imageSrc } =
      this.props;
    const { containerWidth, containerHeight } = this.state;
    return (
      <div
        onClick={this.onClick}
        style={this.getContainerStyle()}
        className={cx(className, styles.image)}
        ref={this.onSize}
      >
        <div className={this.getRatioClassName()}>
          {imageSrc && containerWidth && containerWidth && (
            <AppImage
              className={styles.ImageNext}
              src={imageSrc}
              width={containerWidth}
              height={containerHeight}
              alt={title || ""}
              sizes={"80vw, 100vw"}
              layout="fill"
              isLCP={index === 0}
              onlyHeight
              useOriginal
            />
          )}
          <div className={cx(contentClassName, styles.content)}>{children}</div>
        </div>
      </div>
    );
  }
}

export default ImageWithRatio;
