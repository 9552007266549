/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import cx from "classnames";

import { UrlHelper, LocaleHelper, ScreenType } from "@nf/common";

import { SortTypes } from "enums/SortTypes";
import { Select, Option, Portal, Backdrop } from "../..";

import styles from "../Drawers.module.scss";

export interface IMediaSortDrawer {
  screenType?: ScreenType;
}

export const MediaSortDrawer = ({ screenType }: IMediaSortDrawer) => {
  const { t, i18n } = useTranslation("translations");

  const alphabetical = t(
    "web_sort_option_alphabetical",
    SortTypes?.ALPHABETICAL
  );
  const newestYear = t("web_sort_option_year_newest", SortTypes?.YEAR_NEWEST);
  const oldestYear = t("web_sort_option_year_oldest", SortTypes?.YEAR_OLDEST);
  const popular = t("web_sort_option_popular", SortTypes?.POPULAR);
  const recentlyAdded = t(
    "web_sort_option_recently_added",
    SortTypes?.RECENTLY_ADDED
  );

  const mediaSortOptions = [
    { name: popular },
    { name: recentlyAdded },
    { name: alphabetical },
    { name: newestYear },
    { name: oldestYear },
  ];

  const router = useRouter();
  const [isActive, setIsActive] = useState(false);
  const params = router.query;
  const langValue = LocaleHelper.getLanguageFromLocale(i18n.language);

  const isMobileSize = window && window.innerWidth < 500;

  const options = useMemo(() => {
    return {
      dropdownStyle: {
        zIndex: 101,
        minHeight: `${mediaSortOptions.length * 4.25}rem`,
      },
      dropdownClassName: "sortDrawerVirtualList",
      style: { zIndex: 101 },
      dropdownMatchSelectWidth: isMobileSize ? 125 : 250,
      dropdownAlign: { offset: [0, 1] },
    };
  }, [isMobileSize]);

  let sortParamsCheck =
    (Array.isArray(params?.sort) ? params?.sort[0] : params?.sort) || popular;

  const onSortChange = useCallback(
    (sortId?: string) => {
      let linkId = undefined;
      switch (sortId) {
        case newestYear:
          linkId = "-productionYear";
          break;
        case oldestYear:
          linkId = "productionYear";
          break;
        case popular:
          linkId = "-popularity.month";
          break;
        case recentlyAdded:
          linkId = "-created";
          break;
        case undefined:
        case alphabetical:
          linkId = `localized.${langValue}.sortingTitle`;
          break;
      }
      if (linkId) {
        router.replace(
          UrlHelper.parametrize(
            router.asPath.split("?")[0],
            { sort: linkId },
            { skipEmptyString: true }
          ),
          undefined,
          { shallow: true }
        );
      }
    },
    [langValue, params, router]
  );

  useEffect(() => {
    const prevLocale = sortParamsCheck?.split(".", 2)?.[1];
    if (prevLocale && prevLocale.length && prevLocale !== langValue) {
      onSortChange();
    }
  }, [langValue, onSortChange, sortParamsCheck]);

  switch (sortParamsCheck) {
    case `localized.${langValue}.sortingTitle`:
      sortParamsCheck = alphabetical;
      break;
    case "-productionYear":
      sortParamsCheck = newestYear;
      break;
    case "productionYear":
      sortParamsCheck = oldestYear;
      break;
    case "-popularity.month":
      sortParamsCheck = popular;
      break;
    case "-created":
      sortParamsCheck = recentlyAdded;
      break;
  }

  const playlistScreen = screenType === ScreenType.Playlist;

  const labelElement = (
    <span
      className={cx(styles.labelBox, {
        [styles.labelBoxPlaylist]: playlistScreen,
        [styles.emptyOptionNameInPlaylist]:
          playlistScreen && sortParamsCheck.length === 0,
      })}
    >
      <span className={styles.labelSortName}>{t("web_select_sort")}</span>{" "}
      <span
        className={cx({
          [styles.labelOptionName]: !playlistScreen,
        })}
      >
        {sortParamsCheck}
      </span>
    </span>
  );

  return (
    <>
      <Portal>{isActive ? <Backdrop /> : <></>}</Portal>
      <div className={styles.drawers}>
        <Select
          {...options}
          onDropdownVisibleChange={(value: boolean) => setIsActive(value)}
          value={labelElement}
          onChange={(e: string | undefined) => onSortChange(e)}
        >
          {mediaSortOptions.map((sortOption) => (
            <Option
              key={`${sortOption.name}`}
              value={`${sortOption.name}`}
              title=""
            >
              {sortOption.name}
            </Option>
          ))}
        </Select>
      </div>
    </>
  );
};

export default MediaSortDrawer;
