import { IUserSubscriptionModel } from "@nf/common";
import { isSubscriptionHold } from "../../../../helpers";
import { useMemo } from "react";

type TCurrentSubscriptions = {
  currentSubscriptions: undefined | IUserSubscriptionModel[];
  hasCurrentSubscriptions: boolean;
};

export const useCurrentSubscription = (
  subscriptions?: IUserSubscriptionModel[]
): TCurrentSubscriptions => {
  const currentActiveSubscriptions =
    (subscriptions && subscriptions.length > 0) || false;

  const currentSubscriptions = useMemo(() => {
    return currentActiveSubscriptions && subscriptions
      ? subscriptions
          .filter(
            ({ IsActive, SubscriptionStatus }) =>
              IsActive || isSubscriptionHold(SubscriptionStatus)
          )
          .sort(
            (a: IUserSubscriptionModel, b: IUserSubscriptionModel) =>
              new Date(b.ExpirationDate).getTime() -
              new Date(a.ExpirationDate).getTime()
          )
          .sort(
            (a: IUserSubscriptionModel, b: IUserSubscriptionModel) =>
              // @ts-ignore
              b.IsPartnerSubscription - a.IsPartnerSubscription
          )
      : undefined;
  }, [subscriptions, currentActiveSubscriptions]);

  const hasCurrentSubscriptions =
    (currentSubscriptions && currentSubscriptions.length > 0) || false;
  return {
    currentSubscriptions,
    hasCurrentSubscriptions,
  };
};
