import { useState } from "react";
import { useTranslation } from "next-i18next";
import { Dialog } from "../../components/Dialog";

export const OverDeviceLimitModal = () => {
  const { t } = useTranslation("translations");
  const [isDisplayed, setIsDisplayer] = useState(true);

  return (
    <Dialog
      className="dialog"
      destroyOnClose={true}
      footer={null}
      visible={isDisplayed}
      onCancel={() => setIsDisplayer(false)}
    >
      <h1 className="dialogTitle">{t("web_devices_error_title")}</h1>
      <p className="dialogText">{t("web_devices_error_description")}</p>
    </Dialog>
  );
};

export default OverDeviceLimitModal;
