/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  CellType,
  IAppState,
  IListComponentModel,
  IMediaListModel,
  MediaStore,
  SourceType,
  ThemeContext,
} from "@nf/common";
import { ROUTES } from "@nf/constants";
import cx from "classnames";
import { useTranslation } from "next-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import RightArrowIcon from "../../../../../resources/icons/right-arrow.svg";
import { IListComponentProps } from "../../types";
import { ListComponentArrow } from "../ListComponentArrow";
import dynamic from "next/dynamic";

import React, { useCallback, useContext, useMemo, useState } from "react";
import { InView } from "react-intersection-observer";
import { useListItemCount } from "../../../..";
import Slider from "react-slick";

const ListComponentItem = dynamic(
  () => import("../ListComponentItem/components/ListComponentItem")
);
const DotsLoader = dynamic(() => import("components/DotsLoader"), {
  ssr: false,
});
const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useSourceSelector = (
  component: IListComponentModel
): IMediaListModel | undefined => {
  const mediaList =
    useSelector((state: IAppState) => state.media.mediaList) || {};

  if (component.SourceId) {
    return mediaList[component.SourceId];
  }

  if (component.MediaList) {
    return {
      SourceType: SourceType.MediaList,
      Entities: component.MediaList,
      TotalCount: component.MediaList.length,
    };
  }
};

const useGetMediaList = () => {
  const dispatch = useDispatch();
  return useCallback(
    (mediaListId: string | number) =>
      dispatch(
        MediaStore.Actions.getMediaList({
          MediaListId: mediaListId,
          IncludeCategories: true,
          IncludeImages: true,
          IncludeMedia: false,
          PageNumber: 1,
          PageSize: 12,
        })
      ),
    [dispatch]
  );
};

export interface IListComponentVerticalProps extends IListComponentProps {
  className?: string;
  placeholder?: React.ReactElement;
}

export const ListComponentVertical: React.FC<IListComponentVerticalProps> = (
  props
) => {
  const {
    className,
    component,
    loading: isLoading,
    placeholder,
    ready = true,
    readOnly,
  } = props;
  const { themeProvider } = useContext(ThemeContext);
  const { t } = useTranslation("translations");
  const source = useSourceSelector(component);
  const itemCount = useListItemCount(component);
  const [isListFocused, setIsListFocused] = useState(false);
  const [sliderTouched, setSliderTouched] = useState(false);
  const getMediaList = useGetMediaList();
  const listClassName = cx(`ListComponentVertical`, className);
  const isListNotEmpty = source?.Entities;
  const isListLoading = source?.IsLoading || isLoading;

  const inViewGetMediaList = useCallback(
    (inView: boolean) => {
      if (inView && component.SourceId && getMediaList) {
        getMediaList(component.SourceId);
      }
    },
    [getMediaList, component.SourceId]
  );

  const renderItems = useMemo(() => {
    if (source && ready && isListNotEmpty && !isListLoading) {
      return source.Entities.map((media) => (
        <ListComponentItem
          key={`${media.Id}`}
          cellType={component.CellType}
          media={media}
          isPlaceholder={false}
          readOnly={readOnly}
          withProgress={component.Title === "Recently watched"} // FIXME temporary for demo only
          style={{
            fontSize: `${
              themeProvider.getFontSize() *
              themeProvider.getListItemCountFactor(itemCount)
            }px`,
          }}
        />
      ));
    }

    return (
      placeholder ||
      Array(itemCount)
        .fill(undefined)
        .map((_, idx) => (
          <ListComponentItem
            key={`placeholder_${idx}`}
            cellType={component.CellType}
            isPlaceholder
          />
        ))
    );
  }, [source, ready, isListNotEmpty, isListLoading]);

  const handleSliderTouched = () => {
    setSliderTouched(true);
  };

  const getArrowContainerStyle = useCallback(
    (direction) => {
      const isLeftArrow = direction === "left";
      const showLeftArrowAfterFirstTouch = sliderTouched && isLeftArrow;
      const arrows = {
        justifyContent: isLeftArrow ? "flex-start" : "flex-end",
        display: showLeftArrowAfterFirstTouch || !isLeftArrow ? "flex" : "none",
      };
      const background = {
        background: `linear-gradient(to ${direction} , rgb(0, 0, 0, 0.0),  rgb(0, 0, 0, 0.5))`,
      };
      return { arrows, background };
    },
    [sliderTouched]
  );

  const arrowContainer = useCallback(
    (arrowDirection: "right" | "left") => {
      return isListFocused ? (
        <ListComponentArrow
          direction={arrowDirection}
          containerStyle={getArrowContainerStyle(arrowDirection)}
        />
      ) : undefined;
    },
    [isListFocused, getArrowContainerStyle]
  );

  const renderHeader = useMemo(() => {
    const skeletonColor = themeProvider.getColor("AppCellsBackgroundColor");
    const title = t(component.TitleTranslationKey || "", component.Title);
    const hasRedirectLink = !readOnly && component.SourceId;
    const isListReadyToBeRedirected =
      ready && isListNotEmpty && !isListLoading && hasRedirectLink;

    if (title || component.SourceId) {
      return (
        <SkeletonTheme baseColor={skeletonColor} highlightColor={skeletonColor}>
          <header className="ListComponentVertical-title-container">
            <p className="ListComponentVertical-title">
              {ready && isListNotEmpty && !isListLoading ? (
                title
              ) : (
                <Skeleton width="25rem" />
              )}
            </p>

            {isListReadyToBeRedirected && (
              <LinkWithLocale
                href={`${ROUTES.PLAYLIST_SCREEN}/${component.SourceId}`}
              >
                <a className="ListComponentVertical-see-all">
                  {t("web_browse_content_see_all")}
                  <RightArrowIcon />
                </a>
              </LinkWithLocale>
            )}
          </header>
        </SkeletonTheme>
      );
    }
  }, [
    component.TitleTranslationKey,
    component.SourceId,
    readOnly,
    isListNotEmpty,
    ready,
  ]);
  let headerVisible = true;

  switch (component.CellType) {
    case CellType.Highlights:
      headerVisible = false;
      break;
    case CellType.Promo:
      headerVisible = false;
      break;
  }

  if (source && !isListLoading && !source.TotalCount) {
    return null;
  }
  return (
    <InView
      as="div"
      id={`list-${component.Id}`}
      className={listClassName}
      rootMargin="25% 0px"
      triggerOnce
      onChange={inViewGetMediaList}
    >
      {headerVisible && renderHeader}
      <div
        className="ListComponentVertical-container"
        onMouseOver={() => setIsListFocused(true)}
        onMouseLeave={() => setIsListFocused(false)}
      >
        <Slider
          slidesToShow={itemCount}
          slidesToScroll={itemCount}
          infinite={false}
          dots={component.CellType === CellType.Highlights}
          speed={500}
          vertical={true}
          verticalSwiping={true}
          afterChange={handleSliderTouched}
          prevArrow={arrowContainer("left")}
          nextArrow={arrowContainer("right")}
        >
          {renderItems}
        </Slider>

        {isListLoading && (
          <div className="ListComponentVertical-loader">
            <DotsLoader width={50} />
          </div>
        )}
      </div>
    </InView>
  );
};

export default ListComponentVertical;
