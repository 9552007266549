/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { IComponentModel } from "@nf/common";
import React from "react";
import styles from "./ApplicationHeaderItem.module.scss";
import Image from "next/image";

export interface ApplicationHeaderItemProps {
  component: IComponentModel;
}

export const ApplicationHeaderItem = ({
  component,
}: ApplicationHeaderItemProps): JSX.Element => {
  if ("IconUrl" in component && "Action" in component)
    return (
      <button className={styles.applicationHeaderItem}>
        <Image src={component.IconUrl || ""} alt="" />
      </button>
    );

  return <></>;
};

export default ApplicationHeaderItem;
