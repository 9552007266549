/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { ThemeContext } from "@nf/common";
import React, { useContext, useEffect, useState } from "react";
import styles from "./ProgressBar.module.scss";

export interface IProgressBarProps {
  progress: number;
  className?: string;
}

export const ProgressBar = ({
  progress,
  className,
}: IProgressBarProps): JSX.Element => {
  const [animationProgress, setAnimationProgress] = useState(0);
  const themeProvider = useContext(ThemeContext).themeProvider;

  const progressBarStyle: React.CSSProperties = {
    backgroundColor: themeProvider.getColor("AppPrimaryColor"),
    width: `${animationProgress}%`,
    borderRadius: animationProgress < 100 ? "0 4.5px 4.5px 0" : "0",
  };

  useEffect(() => setAnimationProgress(progress), [progress]);

  return (
    <div className={`${styles.progressBar} ${className ? className : ""}`}>
      <div className={styles.bar} style={progressBarStyle} />
    </div>
  );
};

export default ProgressBar;
