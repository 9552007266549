/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useState, useCallback } from "react";
import { Form, FieldChildrenProps } from "../Form";
import { FieldProps } from "rc-field-form/es/Field";
import styles from "./LabelField.module.scss";
import cx from "classnames";

const { Field } = Form;

interface ErrorProps {
  children: React.ReactNode[];
}

const Error = ({ children }: ErrorProps) => (
  <ul className={styles.error}>
    {children &&
      children.map((error, index: number) => <li key={index}>{error}</li>)}
  </ul>
);

interface ILabelFieldProps extends FieldProps {
  errorClass?: string;
  apiErrors?: string[];
  otherErrors?: string[];
  label?: React.ReactNode;
  tip?: React.ReactNode;
  requiredIcon?: boolean;
  className?: string;
}

const LabelFieldControl = ({
  children,
  control,
  meta,
  form,
  apiErrors,
  otherErrors,
  errorClass,
  label,
  tip,
  requiredIcon,
  className,
}: ILabelFieldProps & FieldChildrenProps) => {
  const [visited, setVisited] = useState(control.touched);
  const hasErrors = meta.errors.length > 0;
  const hasApiErrors = apiErrors && apiErrors.length > 0;
  const hasOtherErrors = otherErrors && otherErrors.length > 0;
  const displayError =
    !control.touched && (hasErrors || hasApiErrors || hasOtherErrors);

  const onBlur = useCallback(
    (...args) => {
      if (control.onBlur) {
        if (!visited) {
          setVisited(true);
        }
        control.onBlur(...args);
      }
    },
    [control, visited]
  );

  if (displayError) {
    control.className = errorClass;
  }
  if ((hasApiErrors || hasOtherErrors) && !hasErrors) {
    meta.errors = [
      ...meta.errors,
      ...(apiErrors || []),
      ...(otherErrors || []),
    ];
  }

  const childNode =
    typeof children === "function"
      ? children(control, meta, form)
      : React.cloneElement(children as React.ReactElement, {
          ...control,
          onBlur,
        });

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.labelContainer}>
        <label className={styles.label}>
          {label} {requiredIcon && <span className={styles.required}>*</span>}
        </label>
      </div>
      {childNode}
      <div className={styles.fallbackContainer}>
        {displayError && <Error>{meta.errors}</Error>}
        {tip && <div className={styles.tip}>{tip}</div>}
      </div>
    </div>
  );
};

const DEFAULT_VALIDATE_TRIGGER = ["onChange", "onBlur", "onSubmit"];

const LabelField: React.FunctionComponent<ILabelFieldProps> = ({
  name,
  label,
  tip,
  apiErrors,
  errorClass,
  otherErrors,
  children,
  requiredIcon,
  className,
  ...restProps
}) => {
  return (
    <Field
      name={name}
      validateTrigger={DEFAULT_VALIDATE_TRIGGER}
      {...restProps}
    >
      {(control, meta, form) => (
        <LabelFieldControl
          control={control}
          meta={meta}
          form={form}
          label={label}
          tip={tip}
          apiErrors={apiErrors}
          otherErrors={otherErrors}
          errorClass={errorClass}
          requiredIcon={requiredIcon}
          className={className}
        >
          {children}
        </LabelFieldControl>
      )}
    </Field>
  );
};

LabelField.defaultProps = {
  errorClass: styles.inputError,
};

export { LabelField };

export default LabelField;
