/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import { IUserSubprofileModel } from "@nf/common";
import { ROUTES } from "@nf/constants";
import dynamic from "next/dynamic";

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));
const SubprofilesContainer = dynamic(
  () => import("components/Subprofiles/SubprofilesContainer")
);
const ProfileCircle = dynamic<IProfileCircleProps>(() =>
  import("components/Subprofiles/ProfileCircle").then(
    (mod) => mod.ProfileCircle
  )
);
import { useTranslation } from "next-i18next";
import styles from "./EnterPinCodeScreen.module.scss";
import EnterPinCodeForm from "./EnterPinCodeForm";
import { IProfileCircleProps } from "../../../components";

export interface IEnterPinCodeScreenProps {
  subprofile?: IUserSubprofileModel;
  pinCodeId?: string;
  close: (forceClose?: boolean) => void;
  handleSelectProfile: (subprofile?: IUserSubprofileModel) => void;
}

export const EnterPinCodeScreen = ({
  pinCodeId,
  subprofile,
  close,
  handleSelectProfile,
}: IEnterPinCodeScreenProps) => {
  const { t } = useTranslation("translations");
  const closeModal = () => {
    close();
  };

  return (
    <SubprofilesContainer close={closeModal}>
      <>
        <div className={styles.EnterPinCodeContainer}>
          <h2>{t("web_subprofiles_enter_pin")}</h2>
          <ProfileCircle
            className={styles.ProfileCircle}
            subprofile={subprofile}
            editButton={false}
          />
          <div className={styles.CodeContainer}>
            <EnterPinCodeForm
              handleSelectProfile={handleSelectProfile}
              pinCodeId={pinCodeId}
              subprofile={subprofile}
              inputType="password"
            />
          </div>
          <LinkWithLocale href={ROUTES.SUBPROFILES_FORGOT_PIN}>
            <a>
              <p className={styles.ForgotPin}>
                {t("web_subprofiles_forgot_pin")}
              </p>
            </a>
          </LinkWithLocale>
        </div>
      </>
    </SubprofilesContainer>
  );
};

export default EnterPinCodeScreen;
