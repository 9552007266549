/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useMemo } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import {
  DeviceHelper,
  ImageHelper,
  MediaType,
  RouteHelper,
  useCanWatchAuthContent,
} from "@nf/common";
import dynamic from "next/dynamic";

import { ImageWithRatio } from "components/ImageWithRatio";
import { ImageRatio } from "enums/ImageRatio";
import { IListComponentItemProps } from "../../models";

const MediaAdditionalInfo = dynamic(
  () => import("components/Media/MediaAdditionalInfo")
);

const PlayButton = dynamic(() => import("components/PlayButton"));

const MyListButton = dynamic(() => import("components/MyListButton"));

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));

import { isServerSideRender } from "@nf/helpers";

import styles from "./ListComponentItemHighlight.module.scss";
import { useSyncCompleted } from "../../../../../../../hooks";

type IListComponentItemHighlightProps = IListComponentItemProps;

export const ListComponentItemHighlight = ({
  media,
  isPlaceholder,
  index,
}: IListComponentItemHighlightProps) => {
  const isPhone = DeviceHelper.isPhone();
  const isPhoneOrTablet =
    DeviceHelper.isMobile() ||
    (!isServerSideRender && window?.innerWidth < 850);
  const { canWatch } = useCanWatchAuthContent(media);
  const isAuthProcessCompleted = useSyncCompleted();

  const renderSkeleton = useMemo(() => {
    if (!media) {
      return (
        <div className={`${styles.item} ${styles.itemSkeleton}`}>
          <SkeletonTheme>
            <div className={styles.skeleton}>
              <div style={{ marginBottom: "14px" }}>
                <Skeleton width={"20%"} height="18px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"40%"} height="49px" />
              </div>
              <div style={{ marginBottom: "30px" }}>
                <Skeleton width={"30%"} height="24px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"50%"} height="18px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"48%"} height="18px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"49%"} height="18px" />
              </div>
              <div>
                <Skeleton width={"35%"} height="18px" />
              </div>
            </div>
          </SkeletonTheme>
        </div>
      );
    }
  }, [media]);

  if (!media || isPlaceholder) {
    return <>{renderSkeleton}</>;
  }

  const canAddToWatchlist =
    canWatch && media.MediaTypeCode !== MediaType.Collection;

  return (
    <LinkWithLocale href={RouteHelper.goToDetails(media)} passHref>
      <a className={styles.item} id="highlightListItem">
        <ImageWithRatio
          key={media?.Id}
          index={index}
          width={null}
          height={null}
          ratio={
            isPhone ? ImageRatio?.TWENTY_TWENTYTHREE : ImageRatio?.SIXTEEN_SEVEN
          }
          contentClassName={styles.background}
          imageSrc={ImageHelper.getHeroFrontpageUrl(media?.Images)}
          title={media.Title}
        >
          <div className={styles.container}>
            <div className={styles.content}>
              <h1 className={styles.title}>{media?.Description}</h1>
              <div className={styles.infoContainer}>
                <p className={styles.description}>{media.Title}</p>
                <MediaAdditionalInfo
                  className={styles.additionalDescription}
                  media={media}
                  showComma={false}
                />
              </div>
              <div className={styles.buttonContainer}>
                {!isPhoneOrTablet && isAuthProcessCompleted && (
                  <>
                    <PlayButton isStopPropagation={true} media={media} />
                    {canAddToWatchlist && (
                      <MyListButton showLabel={false} media={media} />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </ImageWithRatio>
      </a>
    </LinkWithLocale>
  );
};
