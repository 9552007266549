/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
// @ts-nocheck

import { CellType, IListComponentModel } from "@nf/common";
import { useMemo } from "react";
import { useBreakpoints } from "../../..";

const FRAME_ITEM_COUNT = 4;
const COVER_ITEM_COUNT = 6;
const HIGHLIGHTS_ITEM_COUNT = 1;
const ITEM_COUNT = 1;
const SQUARE_ITEM_COUNT = 6;

export type ItemCountModel = {
  [key in CellType]: {
    [breakpoint: string]: number;
  };
};

const listCountByType: ItemCountModel = {
  [CellType.Frame]: {
    XS: 2,
    SM: 3,
    MD: 4,
    LG: 4,
    XL: 4,
  },
  [CellType.FrameLarge]: {
    XS: 1,
    SM: 2,
    MD: 2,
    LG: 2,
    XL: 2,
  },
  [CellType.Event]: {
    XS: 1,
    SM: 2,
    MD: 3,
  },
  [CellType.Cover]: {
    XS: 3,
    SM: 6,
    MD: 7,
    LG: 7,
    XL: 7,
  },
  [CellType.CoverLarge]: {
    XS: 3,
    SM: 4,
    MD: 5,
    LG: 5,
    XL: 5,
  },
  [CellType.Poster]: {
    XS: 3,
    SM: 6,
    MD: 7,
    LG: 7,
    XL: 7,
  },
  [CellType.Highlights]: {
    XS: 1,
    SM: 1,
    MD: 1,
  },
  [CellType.Promo]: {
    XS: 1,
    SM: 1,
    MD: 1,
  },
  [CellType.Default]: {
    XS: 1,
    SM: 2,
    MD: 3,
  },
  [CellType.Button]: {
    XS: 1,
    SM: 2,
    MD: 3,
  },
  [CellType.Circle]: {
    XS: 3,
    SM: 4,
    MD: 6,
    LG: 6,
    XL: 6,
  },
  [CellType.Square]: {
    XS: 2,
    SM: 3,
    MD: 4,
  },
  [CellType.Custom]: {
    XS: 1,
    SM: 2,
    MD: 3,
  },
  [CellType.Genres]: {
    XS: 1,
    SM: 2,
    MD: 3,
  },
};

export const getListItemCount = (
  component: IListComponentModel,
  breakpoint?: string
) => {
  const countByType = listCountByType[component.CellType || CellType.Frame];
  const countByResolution =
    countByType && breakpoint && countByType[breakpoint];

  if (!countByResolution) {
    switch (component.CellType) {
      case CellType.Frame:
        return component.VisibleItemsCount || FRAME_ITEM_COUNT;
      case CellType.Cover:
        return component.VisibleItemsCount || COVER_ITEM_COUNT;
      case CellType.Highlights:
      case CellType.Promo:
        return component.VisibleItemsCount || HIGHLIGHTS_ITEM_COUNT;
      case CellType.Circle:
        return component.VisibleItemsCount || SQUARE_ITEM_COUNT;
      default:
        return component.VisibleItemsCount || ITEM_COUNT;
    }
  }

  return countByResolution;
};

export const useListItemCount = (component: IListComponentModel) => {
  const { breakpoint } = useBreakpoints();
  const itemCount = useMemo(
    () => getListItemCount(component, breakpoint),
    [component, breakpoint]
  );

  return itemCount;
};
