import process from "process";
import React, { useMemo } from "react";
import { MobileSignUpComponent } from "./Components/MobileSignUpComponent/MobileSignUpComponent";

export type TextComponentProps = {
  component: any;
};
export const TextComponent = ({
  component,
}: TextComponentProps): React.ReactElement | null =>
  useMemo(() => {
    switch (component.Id) {
      case process.env.NEXT_PUBLIC_SIGN_UP_MOBILE_COMPONENT_ID:
        return <MobileSignUpComponent />;
      default:
        return null;
    }
  }, [component.Id]);
