/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { MediaType, useUserSelector } from "@nf/common";
import { Actions } from "@nf/common/src/store/user";
import { TimeHelper } from "@nf/common/src/helpers";
import { ITableColumnProps, type ITableProps } from "components/Table";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import IconX from "../../../resources/icons/close-dark.svg";
import styles from "./SettingsWatchHistory.module.scss";
import dynamic from "next/dynamic";

const ActionsHandler = dynamic(() => import("../../ActionsHandler"));

const Table = dynamic<ITableProps<IRowData | IRowNoData>>(
  () => import("../../Table")
);

interface IRowData {
  id: string | number;
  date: string;
  title: string;
}

interface IRowNoData {
  title: string;
}

export const SettingsWatchHistory = () => {
  const dispath = useDispatch();
  const {
    watchHistory: { Data, Error: error, IsProcessing: isLoading },
  } = useUserSelector();
  const { t } = useTranslation("translations");

  const onDelete = (id: string) => dispath(Actions.deleteWatchHistory(id));
  let watchHistory: IRowData[] | IRowNoData[] | undefined = Data?.map(
    (item) => {
      const date = item.LastViewedTime
        ? TimeHelper.getDateKey(item.LastViewedTime, "DD-MM-YY")
        : "";

      if (item.MediaTypeCode === MediaType.Episode) {
        return {
          id: item.Id,
          date,
          title: `${item.Title} - ${t("web_watch_history_season", {
            number: item.ParentOrderInParent,
          })}, ${t("web_watch_history_episode", {
            number: item.OrderInParent,
          })}`,
        };
      } else {
        return {
          id: item.Id,
          date,
          title: item.Title ?? "",
        };
      }
    }
  );

  useEffect(() => {
    dispath(Actions.getWatchHistory());
  }, []);

  let columns: ITableColumnProps<IRowData | IRowNoData> = [
    {
      dataIndex: "date",
      key: "date",
      align: "left",
    },
    {
      dataIndex: "title",
      key: "title",
      align: "left",
    },
    {
      align: "right",
      key: "action",
      render: ({ id }) => (
        <div className={styles.deleteButton} onClick={() => onDelete(id)}>
          <IconX />
        </div>
      ),
    },
  ];

  if (!watchHistory?.length) {
    columns = [
      {
        dataIndex: "title",
        key: "title",
        align: "left",
      },
    ];
    watchHistory = [
      {
        title: t("web_my_account_menu_watch_history_no_data"),
      },
    ];
  }
  return (
    <div className={styles.watchHistory}>
      <p className={styles.description}>{t("web_watch_history_description")}</p>
      <ActionsHandler isLoading={isLoading} error={error}>
        <Table
          rowClassName={styles.row}
          className={styles.table}
          columns={columns}
          data={watchHistory}
          rowKey={"id"}
        />
      </ActionsHandler>
    </div>
  );
};

export default SettingsWatchHistory;
