/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useEffect } from "react";
import { useTranslation } from "next-i18next";
import cx from "classnames";

import { IUserDeviceModel, useDispatch, useSelector } from "@nf/common";
import { Actions } from "@nf/common/src/store/user";

import IconX from "../../../resources/icons/close-dark.svg";

import type { ITableColumnProps, ITableProps } from "components";
import Tooltip from "components/Tooltip";
import dynamic from "next/dynamic";

const ActionsHandler = dynamic(() => import("components/ActionsHandler"));

const Table = dynamic<ITableProps<IUserDeviceModel>>(
  () => import("components/Table")
);

import styles from "./SettingsDevices.module.scss";

export const SettingsDevices = () => {
  const dispatch = useDispatch();
  const {
    Data,
    Error: error,
    IsProcessing: isLoading,
  } = useSelector((state) => state.user.devices);
  const { t } = useTranslation("translations");

  const onDelete = (id: string) => dispatch(Actions.deleteUserDevice(id));

  useEffect(() => {
    dispatch(Actions.getUserDevices());
  }, []);

  const playbackDevices = Data?.filter((device) => device.PlayDevice);

  const columns: ITableColumnProps<IUserDeviceModel> = [
    {
      title: t("web_devices_device"),
      dataIndex: "Name",
      key: "device",
      width: 230,
      align: "left",
    },
    {
      title: null,
      dataIndex: "",
      key: "delete",
      render: ({ Id, CurrentDevice }) =>
        !CurrentDevice && (
          <Tooltip content={t("web_devices_icon_delete")}>
            <div
              className={cx(styles.Action, styles.Delete)}
              onClick={() => onDelete(Id)}
            >
              <IconX />
            </div>
          </Tooltip>
        ),
    },
  ];

  return (
    <div className={styles.Container}>
      <p>{t("web_devices_info")}</p>
      <p>{t("web_devices_list_description")}</p>
      <ActionsHandler isLoading={isLoading} error={error}>
        <Table
          className={styles.Table}
          columns={columns}
          data={playbackDevices}
          rowKey={"Id"}
        />
      </ActionsHandler>
    </div>
  );
};

export default SettingsDevices;
