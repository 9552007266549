/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaPersonModel, RouteHelper } from "@nf/common";
import { LinkWithLocale } from "../../LinkWithLocale";

import styles from "./MediaPeopleLinks.module.scss";
import { Fragment } from "react";

interface IMediaPeopleLinksProps {
  people: IMediaPersonModel[];
}

export const MediaPeopleLinks = ({ people }: IMediaPeopleLinksProps) => {
  return (
    <>
      {people.map((person, index) => (
        <Fragment key={person.PersonId}>
          {!!index && ", "}
          <LinkWithLocale
            href={RouteHelper.goToPerson(
              person.PersonFullName,
              person.PersonId
            )}
          >
            <a className={styles.link}>{person.PersonFullName}</a>
          </LinkWithLocale>
        </Fragment>
      ))}
    </>
  );
};
