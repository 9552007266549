/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import dynamic from "next/dynamic";

import Close from "resources/icons/close.svg";

import styles from "./SubprofilesContainer.module.scss";

const HeaderLogo = dynamic(
  () => import("components/AppHeader/components/HeaderLogo")
);

const MediaButton = dynamic(() => import("components/MediaButton"));

export interface ISubprofilesContainerProps {
  children: JSX.Element;
  close?: () => void;
}

export const SubprofilesContainer = ({
  children,
  close,
}: ISubprofilesContainerProps) => {
  return (
    <div className={styles.subprofilesContainer}>
      <div className={styles.logoContainer}>
        <HeaderLogo />
      </div>
      {close && (
        <div className={styles.closeBtnWrapper}>
          <MediaButton onClick={close} variant="transparent">
            <Close />
          </MediaButton>
        </div>
      )}
      {children}
    </div>
  );
};

export default SubprofilesContainer;
