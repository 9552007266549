/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import * as React from "react";
import { DeviceHelper } from "@nf/common";
import cx from "classnames";
import XIcon from "../../../resources/icons/x.svg";
import styles from "./Input.module.scss";

export interface IInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  allowClear?: boolean;
  suffix?: React.ReactNode;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  checkIfAutoCompleted?: () => void;
  trimValue?: boolean;
  css?: React.CSSProperties;
}

interface IResolveOnChangeOptions {
  trim?: boolean;
}

const checkAutofillSupport = () => {
  const input = document.createElement("input");
  try {
    input.matches(":autofill");
    return true;
  } catch (error) {
    // Selector is not supported
    return false;
  }
};

export function fixControlledValue<T>(value: T) {
  if (typeof value === "undefined" || value === null) {
    return "";
  }

  return value;
}

export function resolveOnChange(
  target: HTMLInputElement,
  e:
    | React.ChangeEvent<HTMLInputElement>
    | React.MouseEvent<HTMLElement, MouseEvent>,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  options?: IResolveOnChangeOptions
) {
  if (onChange) {
    let event = e;
    if (e.type === "click") {
      // click clear icon
      event = Object.create(e);
      event.target = target;
      event.currentTarget = target;
      const originalInputValue = target.value;
      // change target ref value cause e.target.value should be '' when clear input
      target.value = "";
      onChange(event as React.ChangeEvent<HTMLInputElement>);
      // reset target ref value
      target.value = originalInputValue;
      return;
    }

    if (isOfType<HTMLInputElement>(e.target, "value") && options?.trim) {
      e.target.value = e.target.value.trim();
    }

    onChange(event as React.ChangeEvent<HTMLInputElement>);
  }
}

export function isOfType<T>(
  varToBeChecked: any,
  propertyToCheckFor: keyof T
): varToBeChecked is T {
  return (varToBeChecked as T)[propertyToCheckFor] !== undefined;
}

export interface IInputState {
  value: string | number | readonly string[] | undefined;
  focused: boolean;
  /** `value` from prev props */
  prevValue: string | number | readonly string[] | undefined;
}

export class Input extends React.Component<IInputProps, IInputState> {
  static defaultProps = {
    type: "text",
  };

  input!: HTMLInputElement;
  timeout: ReturnType<typeof setTimeout> | null;

  constructor(props: IInputProps) {
    super(props);
    const value =
      typeof props.value === "undefined" ? props.defaultValue : props.value;
    this.state = {
      value,
      focused: false,
      prevValue: props.value,
    };
    this.timeout = null;
  }

  static getDerivedStateFromProps(
    nextProps: IInputProps,
    { prevValue }: IInputState
  ) {
    const newState: Partial<IInputState> = { prevValue: nextProps.value };
    if (nextProps.value !== undefined || prevValue !== nextProps.value) {
      newState.value = nextProps.value;
    }
    return newState;
  }

  focus = () => {
    this.input.focus();
  };

  blur() {
    this.input.blur();
  }

  select() {
    this.input.select();
  }

  saveInput = (input: HTMLInputElement) => {
    this.input = input;
  };

  onFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const { onFocus } = this.props;
    this.setState({ focused: true });
    if (onFocus) {
      onFocus(e);
    }
  };

  onBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const { onBlur } = this.props;
    this.setState({ focused: false });
    if (onBlur) {
      onBlur(e);
    }
  };

  onInputMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    // Prevent focused state lost
    e.preventDefault();
  };

  onInputMouseUp: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    this.focus();
  };

  setValue(value: string, callback?: () => void) {
    if (this.props.value === undefined) {
      this.setState({ value }, callback);
    }
  }

  handleReset = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setValue("", () => {
      this.focus();
    });
    resolveOnChange(this.input, e, this.props.onChange);
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { trimValue } = this.props;

    this.setValue(e.target.value);
    resolveOnChange(this.input, e, this.props.onChange, { trim: trimValue });
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { onPressEnter, onKeyDown } = this.props;
    if (e.keyCode === 13 && onPressEnter) {
      onPressEnter(e);
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  renderSuffixIcon() {
    const { allowClear, disabled, readOnly } = this.props;
    const { value } = this.state;
    const needClear = !disabled && !readOnly && value;

    if (allowClear && needClear) {
      return (
        <i
          className={cx(styles.clear, { [styles.clearHidden]: !needClear })}
          role="button"
          onClick={this.handleReset}
        >
          <XIcon />
        </i>
      );
    }

    return this.props.suffix;
  }

  renderSuffix() {
    const { suffix, allowClear } = this.props;

    if (suffix || allowClear) {
      return (
        <div
          className={styles.suffix}
          onMouseDown={this.onInputMouseDown}
          onMouseUp={this.onInputMouseUp}
        >
          {this.renderSuffixIcon()}
        </div>
      );
    }
    return null;
  }

  componentDidMount() {
    const checkIfAutoCompleted = this.props.checkIfAutoCompleted;
    if (checkIfAutoCompleted && !DeviceHelper.isFirefox()) {
      this.timeout = setTimeout(() => {
        const supportsAutofill = checkAutofillSupport();

        if (supportsAutofill && this.input?.matches(":autofill")) {
          checkIfAutoCompleted();
        } else if (!supportsAutofill && this.input?.value !== "") {
          checkIfAutoCompleted();
        }
      }, 400);
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      className,
      inputClassName,
      checkIfAutoCompleted,
      css,
      trimValue,
      ...otherProps
    } = this.props;
    const { focused, value } = this.state;

    return (
      <span
        style={css}
        className={cx(styles.container, className, {
          [styles.containerFocused]: focused,
        })}
      >
        <input
          {...otherProps}
          ref={this.saveInput}
          className={cx(styles.input, inputClassName)}
          value={fixControlledValue(value)}
          onChange={this.handleChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.handleKeyDown}
        />
        {this.renderSuffix()}
      </span>
    );
  }
}

export default Input;
