/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { ContactsForm } from "../Forms";

export const SettingsPersonalDetails = () => {
  return <ContactsForm />;
};

export default SettingsPersonalDetails;
