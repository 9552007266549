import { useEffect } from "react";
import { useIsLoggedIn } from "@nf/common";
import {
  useRedirectToProfileScreen,
  useRedirectToUrlParam,
} from "../../../hooks";
import { getCookie } from "cookies-next";

export const useRedirectForLoggedInUsers = () => {
  const isLoggedIn = useIsLoggedIn();
  const redirectToProfileSelectionScreen = useRedirectToProfileScreen();
  const isChild = getCookie("isChild");

  const { hasRedirectUrlParam, redirectToUrlParam } = useRedirectToUrlParam();

  useEffect(() => {
    if (isLoggedIn && isChild) {
      redirectToProfileSelectionScreen();
    }

    if (isLoggedIn && !isChild && hasRedirectUrlParam) {
      redirectToUrlParam();
    }
  }, [
    isLoggedIn,
    redirectToProfileSelectionScreen,
    isChild,
    hasRedirectUrlParam,
    redirectToUrlParam,
  ]);
};
