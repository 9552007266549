/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React, { useCallback } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import cx from "classnames";
import dynamic from "next/dynamic";

import { ROUTES } from "@nf/constants";

import { ISettingsMenuItemModel, SettingsMenuItem } from "../SettingsMenuItem";

import LeftArrowIcon from "../../../resources/icons/left-arrow.svg";

import styles from "./SettingsMenu.module.scss";
import { LinkWithLocale } from "../../LinkWithLocale";

const LogoutButton = dynamic(() => import("components/LogoutButton"));

export interface ISettingsScreenProps {
  showMenu: boolean;
  isViaPartner?: boolean;
}
export enum MenuKeys {
  PERSONAL_DETAILS,
  CHANGE_PASSWORD,
  SUBSCRIPTION,
  PROFILES,
  WATCH_HISTORY,
  PAYMENT_DETAILS,
  PAYMENT_HISTORY,
  DEVICES,
  USER_DATA,
}

export const SettingsMenu: React.FC<ISettingsScreenProps> = ({
  showMenu,
  isViaPartner,
}) => {
  const { t } = useTranslation("translations");
  const { asPath } = useRouter();
  const menuItems: ISettingsMenuItemModel[] = [
    {
      key: MenuKeys.PERSONAL_DETAILS,
      title: t("web_my_account_menu_personal_details"),
      componentPath: "settings/personal-details",
    },
    {
      key: MenuKeys.CHANGE_PASSWORD,
      title: t("web_my_account_menu_change_password"),
      componentPath: "settings/change-password",
    },
    {
      key: MenuKeys.SUBSCRIPTION,
      title: t("web_my_account_menu_subscription"),
      componentPath: "settings/subscription",
    },
    {
      key: MenuKeys.PROFILES,
      title: t("web_my_account_menu_profiles"),
      componentPath: "settings/profiles",
    },
    {
      key: MenuKeys.WATCH_HISTORY,
      title: t("web_my_account_menu_watch_history"),
      componentPath: "settings/watch-history",
    },
    {
      key: MenuKeys.PAYMENT_DETAILS,
      title: t("web_my_account_menu_payment_details"),
      componentPath: "settings/payment-details",
      hide: isViaPartner,
    },
    {
      key: MenuKeys.PAYMENT_HISTORY,
      title: t("web_my_account_menu_payment_history"),
      componentPath: "settings/payment-history",
      hide: isViaPartner,
    },
    {
      key: MenuKeys.DEVICES,
      title: t("web_my_account_menu_devices"),
      componentPath: "settings/devices",
    },
    //This button has been temporarily disabled for client's request
    // {
    //   key: MenuKeys.USER_DATA,
    //   title: t("web_my_account_menu_user_data"),
    //   componentPath: "settings/user-data",
    // },
  ];

  const renderTitle = useCallback(() => {
    const sectionTitle = menuItems.find((item) =>
      asPath.includes(item.componentPath)
    )?.title;

    return sectionTitle ? (
      <div className={styles.sectionTitleContainer}>
        <LinkWithLocale href={ROUTES.SETTINGS_SCREEN}>
          <a className={styles.back}>
            <LeftArrowIcon />
          </a>
        </LinkWithLocale>
        <div className={styles.sectionTitle}>{sectionTitle}</div>
      </div>
    ) : (
      <div className={styles.titleContainer}>
        <div className={styles.title}>{t("web_my_account_header")}</div>
      </div>
    );
  }, [asPath, t, menuItems]);

  const renderMenuItems = useCallback(() => {
    return menuItems.reduce(
      (arr: JSX.Element[], menuItem: ISettingsMenuItemModel) => {
        if (!menuItem.hide)
          arr.push(<SettingsMenuItem item={menuItem} key={menuItem.key} />);
        return arr;
      },
      []
    );
  }, [isViaPartner, t, asPath, menuItems]);

  return (
    <div className={styles.container}>
      {renderTitle()}
      <div
        className={cx({
          [styles.menuContainer]: !showMenu,
        })}
      >
        <ul className={styles.menu}>{renderMenuItems()}</ul>
        <LogoutButton variant="plain" />
      </div>
    </div>
  );
};

export default SettingsMenu;
