/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useContext } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import cx from "classnames";

import {
  ImageHelper,
  RouteHelper,
  ThemeContext,
  useCanWatchAuthContent,
} from "@nf/common";

import placeholder from "resources/icons/placeholder.png";
import { ImageRatio } from "enums/ImageRatio";
import type { IListComponentItemProps } from "../../models";

import styles from "./ListComponentItemPromo.module.scss";

const ImageWithRatio = dynamic(() => import("components/ImageWithRatio"));

const MediaAdditionalInfo = dynamic(
  () => import("components/Media/MediaAdditionalInfo")
);

const PlayButton = dynamic(() => import("components/PlayButton"));

const MyListButton = dynamic(() => import("components/MyListButton"));

export const ListComponentItemPromo = ({
  media,
  isPlaceholder,
}: IListComponentItemProps) => {
  const { themeProvider } = useContext(ThemeContext);
  const { canWatch } = useCanWatchAuthContent(media);
  const router = useRouter();

  const goToMedia = () => {
    const detailsUrlObject = RouteHelper.goToDetails(media);
    router.push({
      pathname: `/${router.query.country}/${router.query.language}${detailsUrlObject.pathname}`,
    });
  };

  if (!media || isPlaceholder) {
    const skeletonColor = themeProvider.getSkeletonColor();

    return (
      <div className={cx(styles.item, styles.itemSkeleton)}>
        <div className={cx(styles.image, styles.imageSkeleton)} />
        <div className={cx(styles.container, styles.containerSkeleton)}>
          <SkeletonTheme
            baseColor={skeletonColor}
            highlightColor={skeletonColor}
          >
            <div className={styles.skeleton}>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"50%"} height="45px" />
              </div>
              <div style={{ marginBottom: "24px" }}>
                <Skeleton width={"50%"} height="16px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"70%"} height="21px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"70%"} height="21px" />
              </div>
              <div style={{ marginBottom: "5px" }}>
                <Skeleton width={"60%"} height="21px" />
              </div>
            </div>
          </SkeletonTheme>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.item}>
      <div className={styles.background}>
        <ImageWithRatio
          placeholderSrc={placeholder.src}
          key={media?.Id}
          width={null}
          height={null}
          ratio={ImageRatio?.THREE_TWO}
          className={styles.image}
          imageSrc={ImageHelper.getHighlightAssetFrontpagelUrl(media.Images)}
          onClick={goToMedia}
        />
        <div className={styles.container}>
          <div className={styles.content}>
            <h1 className={styles.headerDescr}>{media.Description}</h1>
            <div className={styles.infoConatiner}>
              <h2 className={styles.title}>{media.Title}</h2>
              <MediaAdditionalInfo
                className={styles.additionalInfo}
                showDuration={false}
                media={media}
              />
            </div>
            <div className={styles.descrContainer}>
              <p className={styles.description}>
                {media?.ShortDescription || media?.LongDescription || ""}
              </p>
            </div>
            <div className={styles.buttonsContainer}>
              <PlayButton playBtnStyles={styles.playButton} media={media} />
              {canWatch && <MyListButton showLabel={false} media={media} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
