import React, { useState } from "react";
import ActionsHandler from "../../../../ActionsHandler/ActionsHandler";
import { useUserSubscriptions } from "../../hooks";
import { useDebounce } from "../../../../../hooks";

type TFormProviderProps = {
  children: JSX.Element;
};

type TFormContext = {
  isFormSent: boolean;
  setIsFormSent: (value: boolean) => void;
  isDataLoading: boolean;
};

const initialState: TFormContext = {
  isFormSent: false,
  setIsFormSent: () => null,
  isDataLoading: true,
};

const FormContext = React.createContext(initialState);

export const useFormContext = () => React.useContext(FormContext);

export const PaymentFormProvider = ({ children }: TFormProviderProps) => {
  const [isFormSent, setIsFormSent] = useState(initialState.isFormSent);

  const { error, isLoading } = useUserSubscriptions();

  const isDataLoading = useDebounce<boolean>(!!isLoading, 100);

  return (
    <FormContext.Provider value={{ isFormSent, setIsFormSent, isDataLoading }}>
      <ActionsHandler isLoading={false} error={isFormSent ? error : undefined}>
        {children}
      </ActionsHandler>
    </FormContext.Provider>
  );
};
