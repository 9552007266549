/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { useUserSubscriptions } from "./hooks";
import { dispatch, IUserSubscriptionModel, PaymentStore } from "@nf/common";
import {
  ActiveSubscriptionsContainer,
  PauseSubscriptionSuccesInfoDialog,
  PaymentFormProvider,
} from "./components";
import dynamic from "next/dynamic";

const DotsLoader = dynamic(() => import("components/DotsLoader"));
const NoSubscriptionsContainer = dynamic(
  () => import("components/Settings/NoSubscriptionContainer")
);

import { CommonTextError } from "../../CommonTextError";
import { useEffect, useState } from "react";

export const SettingsSubscription = (): JSX.Element => {
  const [isPauseFormSent, setIsPauseFormSent] = useState(false);
  const { hasActiveSubscriptions, subscriptions, error, Completed, isLoading } =
    useUserSubscriptions();

  useEffect(() => {
    dispatch(PaymentStore.Actions.getOffers());
  }, []);

  const displayComponent = Completed && !isLoading;

  return displayComponent && !isLoading ? (
    <PaymentFormProvider>
      <>
        {hasActiveSubscriptions && !error && (
          <ActiveSubscriptionsContainer
            setIsPauseFormSent={setIsPauseFormSent}
            subscriptions={subscriptions as IUserSubscriptionModel[]}
          />
        )}

        {!hasActiveSubscriptions && !error && <NoSubscriptionsContainer />}

        {error && <CommonTextError translationKey="web_my_account_error" />}
        {isPauseFormSent && (
          <PauseSubscriptionSuccesInfoDialog
            setIsPauseFormSent={setIsPauseFormSent}
          />
        )}
      </>
    </PaymentFormProvider>
  ) : (
    <DotsLoader />
  );
};

export default SettingsSubscription;
