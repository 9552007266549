import {
  DataProvider,
  ICreatePaymentDetailsModel,
  IErrorModel,
  IPaymentOrderModel,
  useMutation,
} from "@nf/common";

export const useCreatePaymentDetails = () => {
  return useMutation<
    IPaymentOrderModel,
    IErrorModel,
    ICreatePaymentDetailsModel
  >((data) => DataProvider.createPaymentDetails(data));
};
