/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  dispatch,
  IUserSubprofilesStateModel,
  UserStore,
  IUserSubprofileModel,
  useSelector,
  IAppState,
  StorageHelper,
} from "@nf/common";
import dynamic from "next/dynamic";

const ActionsHandler = dynamic(() => import("components/ActionsHandler"));
const ProfileCircle = dynamic<IProfileCircleProps>(
  () =>
    import("components/Subprofiles/ProfileCircle").then(
      (mod) => mod.ProfileCircle
    ),
  { ssr: false }
);
const SubprofilesContainer = dynamic(
  () => import("components/Subprofiles/SubprofilesContainer"),
  { ssr: false }
);
const Checkbox = dynamic(() => import("components/Checkbox"), { ssr: false });
const AddProfile = dynamic<IAddProfileProps>(
  () =>
    import("components/Subprofiles/ProfileCircle").then(
      (mod) => mod.AddProfile
    ),
  { ssr: false }
);

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { EnterPinCodeScreen } from "screens/SubprofileScreen/EnterPinCodeScreen";
import styles from "./SubprofileSelect.module.scss";
import { useIsLoading } from "helpers/setIsLoadingContext";
import {
  useSwitchProfileWithRedirection,
  useSetCurrentSubProfile,
  useRedirectToUrlParam,
} from "../../../hooks";
import {
  IAddProfileProps,
  IProfileCircleProps,
} from "../ProfileCircle/ProfileCircle";

interface ISubprofileSelectProps {
  subprofilesState?: IUserSubprofilesStateModel;
  setModalVisibility?: () => void;
  isLoading?: boolean;
}

export const SubprofileSelect = ({
  subprofilesState,
  setModalVisibility,
  isLoading,
}: ISubprofileSelectProps) => {
  const { t } = useTranslation("translations");
  const action = useSelector((state: IAppState) => state.user.action);
  const { setIsLoading } = useIsLoading();
  const [checked, setIsChecked] = useState(true);
  const [showPincodeScreen, setShowPincodeScreen] = useState(false);
  const [selectedAuthSubprofile, setSelectedAuthSubprofile] = useState<
    IUserSubprofileModel | undefined
  >(undefined);

  const currentSubprofile = subprofilesState?.currentSubprofile;
  const pinCodeId = subprofilesState?.auth?.PinCodeId;
  const isAnyKidSubprofile = subprofilesState?.subprofiles?.some(
    (subprofile) => subprofile.Child
  );

  const selectCurrentProfile = useSetCurrentSubProfile();
  const userRedirect = useSwitchProfileWithRedirection();

  const { hasRedirectUrlParam, redirectToUrlParam } = useRedirectToUrlParam();

  const selectProfile = (subprofile?: IUserSubprofileModel) => {
    if (
      pinCodeId &&
      isAnyKidSubprofile &&
      ((currentSubprofile?.Child && !subprofile?.Child) ||
        (!currentSubprofile && !subprofile?.Child))
    ) {
      setShowPincodeScreen(true);
      setSelectedAuthSubprofile(subprofile);
    } else {
      handleSelectProfile(subprofile);
    }
  };

  const handleSelectProfile = useCallback(
    (subprofile?: IUserSubprofileModel) => {
      setModalVisibility && setModalVisibility();

      if (!subprofile) return;
      if (subprofile?.UserId === currentSubprofile?.UserId) {
        userRedirect(subprofile);
      } else {
        setIsLoading(true);
        selectCurrentProfile(subprofile).then(() => {
          userRedirect(subprofile);
        });
      }
    },
    []
  );

  const checkCheckboxDefaultValue = async () => {
    const remember = await StorageHelper.getCurrentSubprofileId();
    setIsChecked(!!remember);
  };

  const closeEnterPinCodeScreen = (forceClose?: boolean) => {
    (setModalVisibility || forceClose) && setSelectedAuthSubprofile(undefined);
    setShowPincodeScreen(false);
  };

  useEffect(() => {
    checkCheckboxDefaultValue();
  }, []);

  useEffect(() => {
    if (action?.type === UserStore.Consts.SELECT_USER_SUBPROFILE_SUCCESS) {
      dispatch(UserStore.Actions.selectUserSubprofileCompleted());
      hasRedirectUrlParam
        ? redirectToUrlParam()
        : userRedirect(action.payload.profile);
    }
  }, [action, hasRedirectUrlParam, redirectToUrlParam, userRedirect]);

  return (
    <>
      {!selectedAuthSubprofile || !showPincodeScreen ? (
        <SubprofilesContainer>
          <div className={styles.subprofileSelect}>
            <h1>{t("web_subprofiles_select_profile_header")}</h1>
            <ActionsHandler isLoading={isLoading}>
              <>
                <div className={styles.profileList}>
                  {subprofilesState?.subprofiles?.map((subprofile, index) => (
                    <ProfileCircle
                      selectProfile={selectProfile}
                      editButton={false}
                      subprofile={subprofile}
                      key={index}
                      ellipseText
                      trimUsername
                    />
                  ))}
                  <AddProfile subprofilesState={subprofilesState} />
                </div>
                <label className={styles.checkboxContainer}>
                  <Checkbox
                    checked={checked}
                    onChange={(e: any) =>
                      setIsChecked((e.target as HTMLInputElement).checked)
                    }
                  />
                  <span>
                    {t("web_subprofiles_select_profile_remember_device")}
                  </span>
                </label>
              </>
            </ActionsHandler>
          </div>
        </SubprofilesContainer>
      ) : (
        selectedAuthSubprofile &&
        !!pinCodeId &&
        showPincodeScreen && (
          <EnterPinCodeScreen
            pinCodeId={pinCodeId}
            handleSelectProfile={handleSelectProfile}
            subprofile={selectedAuthSubprofile}
            close={closeEnterPinCodeScreen}
          />
        )
      )}
    </>
  );
};

export default SubprofileSelect;
