/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import cx from "classnames";
import React from "react";
import styles from "./MediaButton.module.scss";
import dynamic from "next/dynamic";

const LoaderSpinner = dynamic(() => import("../LoaderSpinner"));

const DotsLoader = dynamic(() => import("../DotsLoader"));

export type IMediaButtonVariant =
  | "plain"
  | "primary"
  | "secondary"
  | "transparent"
  | "link"
  | "login"
  | "trailer"
  | "no-border";

export type TLoadingAnimation = "dots" | "spinner";
export interface IMediaButtonProps {
  className?: string;
  textClassName?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconElevated?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  variant: IMediaButtonVariant;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode | string;
  loading?: boolean;
  circular?: boolean;
  tabIndex?: number;
  loadingAnimationType?: TLoadingAnimation;
}

const ButtonAnimation: React.FC<{
  loadingAnimation?: TLoadingAnimation;
}> = ({ loadingAnimation = "spinner" }) =>
  loadingAnimation === "dots" ? (
    <DotsLoader width={40} />
  ) : (
    <LoaderSpinner className={styles.loader} width={25} height={25} />
  );

export const MediaButton: React.FC<IMediaButtonProps> = ({
  className,
  textClassName,
  children,
  disabled,
  icon,
  iconElevated,
  loading,
  type,
  variant,
  circular,
  tabIndex,
  onClick,
  loadingAnimationType,
  ...props
}) => (
  <button
    className={cx(styles.button, styles[variant], className, {
      [styles.buttonLoading]: loading,
      [styles.disabled]: disabled,
      [styles.circular]: circular,
    })}
    disabled={disabled || loading}
    type={type}
    tabIndex={tabIndex}
    onClick={onClick}
    {...props}
  >
    {icon && (
      <i
        className={cx(styles.icon, {
          [styles.iconElevated]: iconElevated,
        })}
      >
        {icon}
      </i>
    )}
    {children && (
      <span className={cx(styles.text, textClassName)}>{children}</span>
    )}
    {loading && <ButtonAnimation loadingAnimation={loadingAnimationType} />}
  </button>
);

export default MediaButton;
