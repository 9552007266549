import { useAuthSelector } from "@nf/common";
import dynamic from "next/dynamic";

const MediaButton = dynamic(() => import("../MediaButton"));

const Dialog = dynamic(() => import("../Dialog"));

const CommonTextError = dynamic(() => import("../CommonTextError"));

import { Trans, useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import styles from "./ReciptModal.module.scss";
import { useBrandingLogo } from "../../hooks";
import { DotsLoader } from "../DotsLoader";
import { PAYMENT_PARAM, SUCCESS_VALUE } from "constants/param";

interface ReciptModalProps {
  status: string;
}

export const ReciptModal = ({ status }: ReciptModalProps) => {
  const { t } = useTranslation("translations");
  const { user, isCompleted } = useAuthSelector();
  const router = useRouter();
  const logo = useBrandingLogo();
  const wasPaymentSuccessful = status === SUCCESS_VALUE;

  const closeModal = () => {
    const { pathname, query } = router;
    delete query[PAYMENT_PARAM];
    router.replace({ pathname, query }, undefined, { shallow: true });
  };

  return (
    <Dialog
      className={styles.Dialog}
      visible={true}
      destroyOnClose={true}
      footer={null}
      onCancel={closeModal}
      mask
      maskStyle={{
        backgroundColor: "var(--bg-color)",
        opacity: 0.8,
      }}
    >
      <img alt="Logo" className={styles.logo} src={logo} />
      <div className={styles.textContainer}>
        {wasPaymentSuccessful ? (
          <>
            <h1 className={styles.title}>{t("web_payment_success_header")}</h1>
            {isCompleted ? (
              <>
                <Trans
                  i18nKey={
                    t
                      ? t("web_payment_success_text")
                      : "web_payment_success_text"
                  }
                  components={{ p: <p />, bold: <strong /> }}
                  values={{ email: user?.Email }}
                />
                <div className={styles.buttonContainer}>
                  <MediaButton
                    variant="primary"
                    className={styles.button}
                    onClick={closeModal}
                  >
                    {t("web_receipt_CTA", "")}
                  </MediaButton>
                </div>
              </>
            ) : (
              <div className={styles.dotsContainer}>
                <DotsLoader width={100} />
              </div>
            )}
          </>
        ) : (
          <CommonTextError centered />
        )}
        <h3>{t("web_payment_success_help_header")}</h3>
        <Trans
          i18nKey={
            t
              ? t("web_payment_success_help_text")
              : "web_payment_success_help_text"
          }
          components={{
            p: <p />,
            br: <br />,
            bold: <strong />,
            a: <a href={t("help")} className={styles.link} />,
          }}
        />
      </div>
    </Dialog>
  );
};

export default ReciptModal;
