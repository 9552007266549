/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  filterPeopleByRole,
  IMediaModel,
  MediaCategoryType,
  mediaDetailsTagHelper,
  MediaType,
  PersonInAssetType,
} from "@nf/common";
import { useTranslation } from "next-i18next";
import { MediaPeopleLinks } from "../MediaPeopleLinks";

import styles from "./MediaDetailsDescriptionList.module.scss";

interface IMediaDetailsDescriptionListProps {
  media: IMediaModel;
}

export const MediaDetailsDescriptionList = ({
  media,
}: IMediaDetailsDescriptionListProps): JSX.Element => {
  const { t } = useTranslation("translations");

  const actors = filterPeopleByRole(media.People, PersonInAssetType.Actor);
  const directors = filterPeopleByRole(
    media.People,
    PersonInAssetType.Director
  );

  const formats = mediaDetailsTagHelper(
    media.Categories,
    MediaCategoryType.Format
  );

  return (
    <ul className={styles.list}>
      {actors && !!actors.length && (
        <li className={styles.listItem}>
          <h3>{t("web_asset_page_cast", "")}</h3>
          <p>
            <MediaPeopleLinks people={actors} />
          </p>
        </li>
      )}

      {directors && !!directors.length && (
        <li className={styles.listItem}>
          <h3>{t("web_asset_page_director", "")}</h3>
          <p>
            <MediaPeopleLinks people={directors} />
          </p>
        </li>
      )}

      {media?.Countries && !!media.Countries.length && (
        <li className={styles.listItem}>
          <h3>{t("web_asset_page_production_country", "")}</h3>
          <p>{media.Countries.map((country) => country.Name).join(", ")}</p>
        </li>
      )}

      <li className={styles.listItem}>
        <h3>{t("web_asset_page_age_limit", "")}</h3>
        <p>
          {media?.MediaAgeRestrictionValueMin &&
          parseInt(media.MediaAgeRestrictionValueMin) > 0
            ? `${media.MediaAgeRestrictionValueMin}${t(
                "web_asset_page_agelimit_year",
                ""
              )}`
            : t("web_asset_page_agelimit_allowed_all", "")}
        </p>
      </li>

      {media.Subtitles && !!media.Subtitles.length && (
        <li className={styles.listItem}>
          <h3>{t("web_asset_page_subtitles", "")}</h3>
          <p>
            {media.Subtitles.map((language) => (
              <span key={language}>{language}</span>
            ))}
          </p>
        </li>
      )}

      {formats && !!formats.length && (
        <li className={styles.listItem}>
          <h3>{t("web_asset_page_format", "")}</h3>
          <p>{formats.map((format) => format.CategoryName).join(", ")}</p>
        </li>
      )}

      {media.MediaTypeCode !== MediaType.Series && (
        <li className={styles.listItem}>
          <h3>{t("web_asset_page_orginal_title", "")}</h3>
          <p>{media.Title}</p>
        </li>
      )}

      {media.ContentAdvisory && !!media.ContentAdvisory.length && (
        <li className={styles.listItem}>
          <h3>{t("web_asset_page_content_advisory", "")}</h3>
          <p>{media.ContentAdvisory}</p>
        </li>
      )}
    </ul>
  );
};

export default MediaDetailsDescriptionList;
