/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import { TILE_OUT_OF_VIEW_WIDTH } from "@nf/constants";
import React, { useCallback, useMemo, useRef, useState } from "react";

import { useTileEntry } from "../../../../hooks";
import { isServerSideRender } from "@nf/helpers";
import { IListComponentItemProps } from "../../models";
import { useRouter } from "next/router";
import cx from "classnames";
import styles from "./ListComponentGenreItemFrame.module.scss";
import ListComponentGenreItemModal from "../ListComponentGenreItemModal/ListComponentGenreItemModal";

export interface ListComponentGenreItemFrame extends IListComponentItemProps {
  width: number;
}

export const ListComponentGenreItemFrame = ({
  media,
  isPlaceholder = false,
  gridColumns,
  cellType,
}: ListComponentGenreItemFrame) => {
  const frameRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [isHover, setIsHover] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isOutOfView, setIsOutOfView] = useState(false);
  let mouseEnterTimeout: NodeJS.Timeout;
  const isDesktopSize = !isServerSideRender && window.innerWidth > 850;

  const resize = useMemo(() => {
    if (gridColumns && gridColumns > 5) {
      return { width: 1.1, height: 1.333 };
    }

    if (gridColumns) {
      return { width: 0.62, height: 0.775 };
    }

    return { width: 0.785, height: 0.975 };
  }, []);

  const tileEntryParams = {
    isDesktopSize,
    isHover,
    gridColumns,
    frameRef,
    cellType,
    resize,
  };
  const tileEntry = useTileEntry(tileEntryParams);
  const mouseEnter = () => {
    if (!isDesktopSize) return;
    mouseEnterTimeout = setTimeout(() => {
      setIsFadingOut(false);
      setIsHover(true);
      const boundingRect = frameRef?.current?.getBoundingClientRect();
      const tileRight =
        boundingRect &&
        boundingRect.right >
          (window.innerWidth + TILE_OUT_OF_VIEW_WIDTH ||
            document.documentElement.clientWidth + TILE_OUT_OF_VIEW_WIDTH);
      const tileLeft = boundingRect && boundingRect.left < 0;
      if (tileRight || tileLeft) {
        setIsOutOfView(true);
      } else {
        setIsOutOfView(false);
      }
    }, 200);
  };

  const mouseLeave = () => {
    if (!isDesktopSize) return;
    setIsFadingOut(true);
    setTimeout(() => {
      setIsHover(false);
      setIsFadingOut(false);
      setIsOutOfView(false);
    }, 350);
    clearTimeout(mouseEnterTimeout);
  };

  const goToMedia = useCallback(() => {
    router.push({
      pathname: `/${router.query.country}/${router.query.language}/genre/${media?.Id}`,
    });
  }, [media?.Id, router.query?.country, router.query?.language]);

  const renderSkeleton = () => {
    return (
      <div className={cx(styles.containerSkeleton)}>
        <div className={styles.skeleton} />
      </div>
    );
  };

  if (!media || isPlaceholder) {
    return renderSkeleton();
  }

  const isModalAvailable = isHover && !isOutOfView && isDesktopSize;

  return (
    <div
      className={cx(styles.item, isOutOfView && styles.isOutOfView)}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={goToMedia}
    >
      <div className={styles.container} ref={frameRef}>
        <div className={styles.title}>{media.Title}</div>
      </div>
      {isServerSideRender ||
        (tileEntry &&
          tileEntry.dimension &&
          tileEntry.dimension.width &&
          tileEntry.dimension.height &&
          tileEntry.position &&
          isModalAvailable && (
            <ListComponentGenreItemModal
              media={media}
              gridColumns={gridColumns}
              isFadingOut={isFadingOut}
              width={tileEntry.dimension.width}
              height={tileEntry.dimension.height}
              rectTop={tileEntry.position.top}
              rectLeft={tileEntry.position.left}
              rectRight={tileEntry.position.right}
            />
          ))}
    </div>
  );
};
